@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Vehicle */
/* ---------------------------------------------------------------- */
.vehicle {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  padding: 19px 0;
  @media (max-width: 767px) {
    border-bottom: 2px solid $titleBorder;
    padding: 20px 0;
  }
  /* ---------------------------------------------------------------- */
  /* Vehicle - Icon */
  /* ---------------------------------------------------------------- */
  > svg {
    flex-shrink: 0;
    height: 50px;
    width: 54px;
    @media (max-width: 385px) {
      height: 44px;
      width: 48px;
    }
    @media (max-width: 380px) {
      height: 40px;
      width: 44px;
    }
    @media (max-width: 374px) {
      display: none;
    }
    * {
      @include fast-transition($mob: true, $props: "fill");
      /* ---------------------------------------------------------------- */
      /* Vehicle - Icon - Stroke */
      /* ---------------------------------------------------------------- */
      &:nth-child(1) {
        fill: $iconBikeStroke;
      }
      /* ---------------------------------------------------------------- */
      /* Vehicle - Icon - Seat */
      /* ---------------------------------------------------------------- */
      &:nth-child(2) {
        fill: $iconBikeSeat;
      }
      /* ---------------------------------------------------------------- */
      /* Vehicle - Icon - Tyres */
      /* ---------------------------------------------------------------- */
      &:nth-child(3),
      &:nth-child(4) {
        fill: $iconBikeTyres;
      }
      /* ---------------------------------------------------------------- */
      /* Vehicle - Icon - Body */
      /* ---------------------------------------------------------------- */
      &:nth-child(5) {
        fill: $iconBikeBody;
      }
      /* ---------------------------------------------------------------- */
      /* Vehicle - Icon - Wheels */
      /* ---------------------------------------------------------------- */
      &:nth-child(6),
      &:nth-child(7) {
        fill: $iconBikeWheels;
      }
    }
  }
  > div {
    align-items: center;
    display: flex;
    width: 100%;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
    > div {
      &:nth-child(1) {
        margin: 0 0 0 12px;
        padding-right: 12px;
        @media (max-width: 374px) {
          margin-left: 0;
        }
        /* ---------------------------------------------------------------- */
        /* Vehicle - Name */
        /* ---------------------------------------------------------------- */
        > h2 {
          color: $title;
          font-family: $bold;
          font-size: 16px;
          line-height: 19px;
          margin: 0 auto 0 0;
          width: 100%;
          @media (max-width: 380px) {
            font-size: 15px;
            line-height: 19px;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Error */
        /* ---------------------------------------------------------------- */
        > div {
          align-items: center;
          display: flex;
          height: 29px;
          margin: -1px 0 0;
          > svg {
            flex-shrink: 0;
            height: 20px;
            width: 20px;
            * {
              fill: $questionInvalidText;
            }
          }
          > p {
            color: $questionInvalidText;
            font-size: 16px;
            line-height: 20px;
            margin: 0 0 -1px;
            padding: 0 0 0 10px;
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Vehicle - Buttons Row */
      /* ---------------------------------------------------------------- */
      &:nth-child(2) {
        display: flex;
        flex-shrink: 0;
        margin-left: auto;
        @media (max-width: 767px) {
          display: flex;
          flex-wrap: wrap;
        }
        > div {
          width: 100%;
          /* ---------------------------------------------------------------- */
          /* All Buttons */
          /* ---------------------------------------------------------------- */
          > div[role="button"],
          > button {
            margin-left: 16px;
            @media (max-width: 1240px) {
              margin-left: 12px;
            }
          }
          /* ---------------------------------------------------------------- */
          /* Edit, Delete Button - Mobile */
          /* ---------------------------------------------------------------- */
          &:nth-child(1) {
            display: none;
            @media (max-width: 1024px) {
              display: flex;
              justify-content: flex-end;
            }
            @media (max-width: 767px) {
              justify-content: flex-start;
            }
            > div[role="button"],
            > button {
              &:nth-child(1) {
                margin-left: auto;
              }
              &:nth-child(2) {
                @media (max-width: 767px) {
                  display: inline-flex;
                }
              }
              @media (max-width: 767px) {
                height: auto;
              }
              svg {
                @media (max-width: 1024px) {
                  height: 12px;
                  margin: -1px 4px 0 -1px;
                  width: 12px;
                }
                &:only-child {
                  @media (max-width: 1024px) {
                    margin: 0;
                  }
                }
              }
              span {
                @media (max-width: 400px) {
                  font-size: 14px;
                }
              }
            }
          }
          /* ---------------------------------------------------------------- */
          /* Edit, Delete Button - Desktop */
          /* ---------------------------------------------------------------- */
          &:nth-child(2) {
            @media (max-width: 1024px) {
              display: none;
            }
            > div[role="button"],
            > button {
              @media (max-width: 1240px) {
                padding: 0 16px;
              }
              &:nth-child(1) {
                margin-left: auto;
              }
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Main Bike */
  /* ---------------------------------------------------------------- */
  &.main {
    > svg * {
      &:nth-child(1) {
        fill: $iconBikeStrokeMainBike;
      }
      &:nth-child(2) {
        fill: $iconBikeSeatMainBike;
      }
      &:nth-child(3),
      &:nth-child(4) {
        fill: $iconBikeTyresMainBike;
      }
      &:nth-child(5) {
        fill: $iconBikeBodyMainBike;
      }
      &:nth-child(6),
      &:nth-child(7) {
        fill: $iconBikeWheelsMainBike;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    > div > div:nth-child(1) > h2 {
      color: $vehiclesInvalidText;
    }
    > svg * {
      &:nth-child(1) {
        fill: $iconBikeStrokeInvalid;
      }
      &:nth-child(2) {
        fill: $iconBikeSeatInvalid;
      }
      &:nth-child(3),
      &:nth-child(4) {
        fill: $iconBikeTyresInvalid;
      }
      &:nth-child(5) {
        fill: $iconBikeBodyInvalid;
      }
      &:nth-child(6),
      &:nth-child(7) {
        fill: $iconBikeWheelsInvalid;
      }
    }
  }
}
