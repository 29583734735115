@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Payment - Summary */
/* ---------------------------------------------------------------- */
.payment-summary {
  background-color: $paymentSummaryBg;
  border: 2px solid $paymentSummaryBorder;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  flex-basis: 412px;
  flex-direction: column;
  margin: 0 0 18px 314px;
  max-width: 412px;
  padding: 20px;
  @media (max-width: 1024px) {
    flex-basis: 100%;
    margin-left: 0;
    max-width: 100%;
  }
  @media (max-width: 767px) {
    border-radius: 0;
    border-width: 2px 0;
    flex-basis: calc(100% + 32px);
    margin: 0 -16px 12px;
    max-width: calc(100% + 32px);
    padding: 20px 16px;
  }
  /* ---------------------------------------------------------------- */
  /* Breakdown */
  /* ---------------------------------------------------------------- */
  > ul {
    margin: 0 0 21px;
    > li {
      display: flex;
      font-family: $regular;
      font-size: 18px;
      line-height: 22px;
      padding: 15px 0;
      @include user-select;
      @media (max-width: 390px) {
        font-size: 17px;
        line-height: 21px;
      }
      @media (max-width: 365px) {
        font-size: 16px;
        line-height: 20px;
      }
      &:first-child {
        padding-top: 0;
      }
      /* ---------------------------------------------------------------- */
      /* Breakdown - Cost */
      /* ---------------------------------------------------------------- */
      > span {
        font-family: $bold;
        margin-left: auto;
      }
      /* ---------------------------------------------------------------- */
      /* Total */
      /* ---------------------------------------------------------------- */
      &:last-child {
        border-bottom: 2px solid $paymentSummaryBorder;
        border-top: 2px solid $paymentSummaryBorder;
        color: $paymentSummaryTotal;
        font-family: $bold;
        font-size: 22px;
        line-height: 27px;
        padding: 11px 0;
        @media (max-width: 390px) {
          font-size: 21px;
          line-height: 26px;
        }
        @media (max-width: 365px) {
          font-size: 17px;
          line-height: 21px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  > p {
    font-family: $regular;
    font-size: 14px;
    line-height: 17px;
    @include user-select;
  }
}
