html {
  color: $text;
  display: flex;
  font-family: $medium;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  line-height: 1.25;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-rendering: optimizeLegibility;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-text-size-adjust: 100%;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body {
  background-color: $pageBg;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 320px;
  width: 100%;
  @include no-user-select;
  .platform-admin & {
    overflow: hidden;
    position: fixed;
    top: 0;
  }
}

html.scroll-disable body {
  cursor: default;
  pointer-events: none;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

html.platform-chat #root::after {
  background-color: $black;
  bottom: auto;
  content: "";
  display: block;
  height: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.5s;
  width: 100%;
  z-index: 800;
}

html.platform-chat-maximized.platform-chat #root::after {
  bottom: 0;
  height: auto;
  opacity: 0.3;
}

#CookieReportsPanel {
  z-index: 1200;
  > #CookieReportsOverlay {
    backdrop-filter: blur(1px) !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    opacity: 1 !important;
  }
}

div#root[aria-hidden="true"] ~ #CookieReportsPanel > #CookieReportsOverlay {
  display: none;
  visibility: hidden;
}
