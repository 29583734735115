@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Select */
/* ---------------------------------------------------------------- */
.select {
  position: relative;
  width: 412px;
  @media (max-width: 1024px) {
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Select - Select */
  /* ---------------------------------------------------------------- */
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-color: $inputBg;
    border: 2px solid $inputBorder;
    border-radius: 6px;
    box-sizing: border-box;
    color: $inputText;
    display: inline-block;
    filter: none;
    font-family: $medium;
    font-size: 16px;
    font-weight: normal;
    height: 50px;
    letter-spacing: normal;
    line-height: normal;
    outline: none;
    padding: 0 44px 0 16px;
    resize: none;
    vertical-align: middle;
    width: 100%;
    @include no-user-select;
    option {
      color: $inputText;
    }
    &::-ms-expand {
      display: none;
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      background-color: $inputBgFocus;
      border-color: $inputBorderFocus;
      @include user-select;
      &::-ms-value {
        background-color: $inputBgFocus;
        color: $inputText;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        border-color: $inputBorderHover;
        &:focus {
          border-color: $inputBorderFocus;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Select - Icon */
  /* ---------------------------------------------------------------- */
  svg {
    flex-shrink: 0;
    height: 18px;
    pointer-events: none;
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    * {
      fill: $inputIcon;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Placeholder */
  /* ---------------------------------------------------------------- */
  &.placeholder {
    select {
      color: $inputTextPlaceholder;
      @include no-user-select;
      &:focus {
        color: darken($inputTextPlaceholder, 15%);
        &::-ms-value {
          color: darken($inputTextPlaceholder, 15%);
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          color: darken($inputTextPlaceholder, 15%);
        }
      }
    }
    &.invalid {
      select {
        color: $inputTextPlaceholderInvalid;
        &:focus {
          color: darken($inputTextPlaceholderInvalid, 10%);
          &::-ms-value {
            color: darken($inputTextPlaceholderInvalid, 10%);
          }
        }
        :global(.platform-desktop) & {
          &:hover {
            color: darken($inputTextPlaceholderInvalid, 10%);
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    display: block;
    select {
      border-color: $inputBorderInvalid;
      color: $inputTextInvalid;
      &:focus {
        border-color: $inputBorderFocusInvalid;
        color: darken($inputTextPlaceholderInvalid, 10%);
        &::-ms-value {
          color: darken($inputTextPlaceholderInvalid, 10%);
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: $inputBorderFocusInvalid;
          color: $inputTextInvalid;
          &:focus {
            color: darken($inputTextPlaceholderInvalid, 10%);
            &::-ms-value {
              color: darken($inputTextPlaceholderInvalid, 10%);
            }
          }
        }
      }
    }
    svg * {
      fill: $inputIconInvalid;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Loading */
  /* ---------------------------------------------------------------- */
  &.loading {
    display: block;
    pointer-events: none;
    input {
      padding-right: 52px;
    }
    > svg:last-child {
      height: 20px;
      width: 20px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  &.read-only {
    select {
      cursor: default;
      pointer-events: none;
      &::selection {
        pointer-events: none;
      }
      + svg {
        pointer-events: none;
      }
      &:focus {
        background-color: $inputBg;
        border-color: $inputBorder;
        &::-ms-value {
          background-color: $inputBg;
          color: darken($inputTextPlaceholder, 15%);
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: $inputBorder;
          &:focus {
            border-color: $inputBorder;
          }
        }
      }
    }
    &.placeholder {
      select {
        &:focus {
          color: $inputTextPlaceholder;
          &::-ms-value {
            color: $inputTextPlaceholder;
          }
        }
        :global(.platform-desktop) & {
          &:hover {
            color: $inputTextPlaceholder;
          }
        }
      }
      &.invalid {
        select {
          &:focus {
            color: $inputTextPlaceholderInvalid;
            &::-ms-value {
              color: $inputTextPlaceholderInvalid;
            }
          }
          :global(.platform-desktop) & {
            &:hover {
              color: $inputTextPlaceholderInvalid;
            }
          }
        }
      }
    }
    &.invalid {
      select {
        &:focus {
          border-color: $inputBorderInvalid;
          color: $inputTextInvalid;
          &::-ms-value {
            border-color: $inputBorderInvalid;
          }
        }
        :global(.platform-desktop) & {
          &:hover {
            border-color: $inputBorderInvalid;
            color: $inputTextInvalid;
            &:focus {
              color: $inputTextInvalid;
              &::-ms-value {
                color: $inputTextInvalid;
              }
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    select {
      border-color: $inputBorderDisabled;
      color: $inputTextDisabled;
      opacity: 1;
      pointer-events: none;
    }
    svg {
      pointer-events: none;
      * {
        fill: $inputIconDisabled;
      }
    }
  }
}
