@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Confirm */
/* ---------------------------------------------------------------- */
.policy-confirm {
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  display: flex;
  flex-wrap: wrap;
  padding: 12px 20px;
  position: relative;
  @media (max-width: 767px) {
    padding: 12px 16px;
  }
  > div {
    /* ---------------------------------------------------------------- */
    /* Checkbox */
    /* ---------------------------------------------------------------- */
    &:nth-child(1) {
      height: 30px;
      width: 30px;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        height: 40px;
        width: 40px;
      }
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        height: 34px;
        width: 34px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        height: 30px;
        width: 30px;
      }
      > div[role="button"],
      > button {
        height: 30px;
        width: 30px;
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          height: 40px;
          width: 40px;
        }
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          height: 34px;
          width: 34px;
        }
        @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
          height: 30px;
          width: 30px;
        }
        > svg {
          height: 20px;
          width: 20px;
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            height: 30px;
            width: 30px;
          }
          @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
            height: 24px;
            width: 24px;
          }
          @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text Container */
    /* ---------------------------------------------------------------- */
    &:nth-child(2) {
      box-sizing: border-box;
      display: flex;
      flex-basis: calc(100% - 30px);
      flex-wrap: wrap;
      max-width: calc(100% - 30px);
      padding: 6px 0 0 16px;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        flex-basis: calc(100% - 40px);
        max-width: calc(100% - 40px);
        padding: 0 0 0 12px;
      }
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        flex-basis: calc(100% - 34px);
        max-width: calc(100% - 34px);
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        flex-basis: calc(100% - 30px);
        max-width: calc(100% - 30px);
      }
      > p,
      > div {
        line-height: 20px;
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          font-size: 15px;
          line-height: 19px;
        }
        @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
          font-size: 14px;
          line-height: 18px;
        }
        &:nth-child(1) {
          font-family: $semibold;
          margin: 0 0 8px;
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            margin: 0 0 4px;
          }
        }
        > a {
          color: $hyperlink;
          font-family: $medium;
          text-decoration: underline;
          text-decoration-color: $hyperlink;
          :global(.platform-desktop) & {
            &:hover {
              color: $hyperlinkHover;
            }
          }
          :global(.platform-desktop) &,
          :global(.platform-mobile) & {
            &:active {
              color: $hyperlinkActive;
            }
          }
        }
        > span > button {
          color: $hyperlink;
          cursor: pointer;
          display: inline;
          font-family: $medium;
          font-size: 16px;
          line-height: 20px;
          text-decoration-color: $hyperlink;
          :global(.platform-desktop) & {
            &:hover {
              color: $hyperlinkHover;
            }
          }
          &:active {
            color: $hyperlinkActive;
          }
          @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
            font-size: 15px;
            line-height: 19px;
          }
          @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    span {
      display: inline-block;
      flex-shrink: 0;
      font-family: $bold;
      font-size: 16px;
      line-height: 20px;
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 15px;
        line-height: 19px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 14px;
        line-height: 18px;
      }
      > span {
        font-family: $semibold;
        margin: 0 5px 0 0;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    background-color: $questionInvalidBg;
    border-color: $questionInvalidBorder;
    /* ---------------------------------------------------------------- */
    /* Error Message */
    /* ---------------------------------------------------------------- */
    > div:nth-child(3) {
      margin-top: 15px;
      padding: 0 0 0 46px;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        padding: 0 0 0 52px;
      }
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        padding: 0 0 0 46px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        padding: 0 0 0 42px;
      }
      @media (max-width: 1024px) {
        margin-top: 11px;
      }
      > p {
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          font-size: 15px;
          line-height: 19px;
          padding: 0 0 0 12px;
        }
        @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
          font-size: 14px;
          line-height: 18px;
          padding: 0 0 0 8px;
        }
      }
    }
  }
}
