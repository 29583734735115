@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Page Wrapper */
/* ---------------------------------------------------------------- */
.page-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  /* ---------------------------------------------------------------- */
  /* Narrow: Confirmation, Debug, Error, Motorcycle,
     Payment, Recall, Riders */
  /* ---------------------------------------------------------------- */
  &.narrow {
    padding: 20px 96px;
    @media (max-width: 1192px) {
      padding: 20px 48px;
    }
    @media (max-width: 896px) {
      padding: 20px 24px;
    }
    @media (max-width: 767px) {
      padding: 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Fullwidth: Quote, Product, Policy */
  /* ---------------------------------------------------------------- */
  &.fullwidth {
    padding: 24px 0 40px;
    @media (max-width: 767px) {
      padding: 0;
    }
    @media (max-width: 1440px) {
      + div {
        display: none;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Vertically Align: Error */
  /* ---------------------------------------------------------------- */
  &.vertical-align {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 767px) {
      padding: 0;
    }
  }
}
