@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Product Excess */
/* ---------------------------------------------------------------- */
.product-excess {
  background-color: $productBg;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  margin: 0 0 24px;
  @media (max-width: 1240px) {
    margin: 0 0 16px;
  }
  @media (max-width: 1024px) {
    margin: 0 0 12px;
  }
  @media (max-width: 767px) {
    border-radius: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > header {
    margin: 0 0 26px;
    padding: 24px 20px 0;
    @media (max-width: 1240px) {
      margin: 0 0 20px;
    }
    @media (max-width: 1024px) {
      margin: 0 0 16px;
    }
    @media (max-width: 767px) {
      margin: 0 0 20px;
      padding: 24px 16px 0;
    }
    > h2 {
      color: $title;
      font-family: $bold;
      font-size: 28px;
      line-height: 32px;
      position: relative;
      text-transform: capitalize;
      @include no-user-select;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        font-size: 24px;
        line-height: 28px;
      }
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 23px;
        line-height: 27px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 22px;
        line-height: 26px;
      }
      &:first-child {
        margin-top: -5px;
      }
      &::after {
        background-color: $titleBorder;
        content: "";
        display: block;
        height: 2px;
        margin: 11px 0 0;
        position: relative;
        width: 100%;
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          margin: 6px 0 0;
        }
      }
    }
  }
  > section {
    padding: 0 20px;
    @media (max-width: 767px) {
      padding: 0 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Copy */
    /* ---------------------------------------------------------------- */
    > h3 {
      font-family: $bold;
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 8px;
      max-width: 420px;
      @include user-select;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        max-width: 100%;
      }
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 15px;
        line-height: 19px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    > p {
      margin: 0 0 15px;
      max-width: 500px;
      @include user-select;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        max-width: 100%;
      }
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 15px;
        line-height: 19px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    > div {
      margin: 0 0 30px;
      @media (max-width: 1240px) {
        margin: 0 0 24px;
      }
      /* ---------------------------------------------------------------- */
      /* Select */
      /* ---------------------------------------------------------------- */
      > div {
        display: block;
        width: 500px;
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          width: 100%;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Footer */
  /* ---------------------------------------------------------------- */
  > footer {
    background-color: $productPrimaryBg;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top: 2px solid $productPrimaryBorder;
    padding: 16px 20px 15px;
    @media (max-width: 767px) {
      border-radius: 0;
      padding: 16px;
    }
    > p {
      color: $productTitle;
      font-family: $bold;
      text-align: left;
      @include user-select;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        text-align: center;
      }
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 15px;
        line-height: 19px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
