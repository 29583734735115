@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Control */
/* ---------------------------------------------------------------- */
.control {
  display: flex;
  flex-direction: row-reverse;
  margin: 30px 0 10px;
  @media (max-width: 767px) {
    margin: 20px 16px;
  }
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  > div[role="button"],
  > button {
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    > svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    /* ---------------------------------------------------------------- */
    /* Back */
    /* ---------------------------------------------------------------- */
    &:last-child:not(:first-child) {
      padding-left: 42px;
      padding-right: 24px;
      text-align: right;
      width: 110px;
      @media (max-width: 767px) {
        margin-right: 8px;
        padding: 0 24px;
        width: calc(50% - 8px);
      }
      > svg {
        left: 12px;
        margin: 0;
        @media (max-width: 375px) {
          left: 6px;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Continue */
    /* ---------------------------------------------------------------- */
    &:first-child {
      margin-left: auto;
      width: 265px;
      @media (max-width: 767px) {
        margin-left: 8px;
        width: calc(50% - 8px);
      }
      > span {
        order: 1;
      }
      > svg {
        margin: 0;
        order: 2;
        right: 12px;
        @media (max-width: 375px) {
          right: 6px;
        }
      }
    }
    &:only-child {
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
  }
}
