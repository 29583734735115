@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Question Secondary */
/* ---------------------------------------------------------------- */
.question-secondary {
  border: 2px solid transparent;
  box-sizing: border-box;
  display: flex;
  margin-top: -24px;
  padding-bottom: 24px;
  padding-top: 6px;
  position: relative;
  @media (max-width: 1024px) {
    border-width: 2px 0;
    left: -24px;
    padding-left: 24px;
    padding-right: 24px;
    width: calc(100% + 48px);
  }
  @media (max-width: 767px) {
    left: -16px;
    padding-left: 16px;
    padding-right: 16px;
    width: calc(100% + 32px);
  }
  &:last-child {
    margin: -24px 0 -14px;
    padding-bottom: 12px;
    @media (max-width: 767px) {
      margin-bottom: -8px;
    }
  }
  > div {
    position: relative;
    &:nth-child(1) {
      flex-basis: 314px;
      max-width: 314px;
      text-align: right;
      @media (max-width: 1024px) {
        align-items: center;
        display: flex;
        flex-basis: calc(100% - 28px);
        margin-top: -4px;
        max-width: calc(100% - 28px);
        order: 1;
        text-align: left;
      }
    }
  }
}
