@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Header Trustpilot */
/* ---------------------------------------------------------------- */
.header-trustpilot {
  color: $headerText;
  display: inline-flex;
  margin: 16px 0 0;
  @media (max-width: 767px) {
    flex-direction: row;
    height: 12px;
    margin: 4px 0 0;
  }
  @media (max-width: 500px) {
    margin: 5px 0 0;
  }
  @media (max-width: 405px) {
    margin: 10px 0 0;
  }
  &:focus {
    color: darken($headerText, 10%);
    text-decoration: underline;
    text-decoration-color: darken($headerText, 10%);
    @media (max-width: 767px) {
      outline: 2px solid $headerLogoFocus;
    }
  }
  :global(.platform-desktop) & {
    &:hover {
      color: darken($headerText, 10%);
    }
  }
  :global(.platform-desktop) &,
  :global(.platform-mobile) & {
    &:active {
      color: darken($headerText, 20%);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header Trustpilot - Loading */
  /* ---------------------------------------------------------------- */
  &.loading {
    visibility: hidden;
  }
  /* ---------------------------------------------------------------- */
  /* Header Trustpilot - Columns */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    flex-shrink: 0;
    height: 20px;
    @media (max-width: 1024px) {
      height: auto;
    }
    &:nth-child(1) {
      width: 189px;
      @media (max-width: 1024px) {
        width: 158px;
      }
      @media (max-width: 767px) {
        width: 125px;
      }
      @media (max-width: 500px) {
        width: 69px;
      }
      > div {
        /* ---------------------------------------------------------------- */
        /* Header Trustpilot - Trust Score */
        /* ---------------------------------------------------------------- */
        &:nth-child(1) {
          font-family: $semibold;
          font-size: 16px;
          line-height: 20px;
          margin: 4px 5px 0 0;
          @media (max-width: 1024px) {
            font-size: 14px;
            line-height: 17px;
            margin: 3px 4px 0 0;
          }
          @media (max-width: 767px) {
            font-size: 12px;
            line-height: 15px;
            margin: -1px 3px 0 0;
          }
          @media (max-width: 500px) {
            display: none;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Header Trustpilot - Stars Container */
        /* ---------------------------------------------------------------- */
        &:nth-child(2) {
          @media (max-width: 767px) {
            margin: -3px 0 0 0;
          }
          @media (max-width: 500px) {
            margin: -4px 6px 0 0;
          }
          /* ---------------------------------------------------------------- */
          /* Header Trustpilot - Stars */
          /* ---------------------------------------------------------------- */
          > svg {
            height: 20px;
            width: 108px;
            @media (max-width: 1024px) {
              height: 16px;
              margin: 1px 0 0 0;
              width: 87px;
            }
            @media (max-width: 767px) {
              height: 12px;
              margin: 0 0 0;
              width: 65px;
            }
            :global {
              .tp-star {
                .tp-star__canvas {
                  fill: $headerEmptyStar;
                }
                .tp-star__canvas--half {
                  fill: $headerEmptyStar;
                  visibility: hidden;
                }
              }
            }
          }
          > svg.header-trustpilot-stars-1 {
            :global .tp-star:nth-child(1) .tp-star__canvas {
              fill: $headerFullStar;
            }
          }
          > svg.header-trustpilot-stars-1-5 {
            :global .tp-star:nth-child(-n + 1) .tp-star__canvas {
              fill: $headerFullStar;
            }
            :global .tp-star:nth-child(2) .tp-star__canvas--half {
              fill: $headerFullStar;
              visibility: visible;
            }
          }
          > svg.header-trustpilot-stars-2 {
            :global .tp-star:nth-child(-n + 2) .tp-star__canvas {
              fill: $headerFullStar;
            }
          }
          > svg.header-trustpilot-stars-2-5 {
            :global .tp-star:nth-child(-n + 2) .tp-star__canvas {
              fill: $headerFullStar;
            }
            :global .tp-star:nth-child(3) .tp-star__canvas--half {
              fill: $headerFullStar;
              visibility: visible;
            }
          }
          > svg.header-trustpilot-stars-3 {
            :global .tp-star:nth-child(-n + 3) .tp-star__canvas {
              fill: $headerFullStar;
            }
          }
          > svg.header-trustpilot-stars-3-5 {
            :global .tp-star:nth-child(-n + 3) .tp-star__canvas {
              fill: $headerFullStar;
            }
            :global .tp-star:nth-child(4) .tp-star__canvas--half {
              fill: $headerFullStar;
              visibility: visible;
            }
          }
          > svg.header-trustpilot-stars-4 {
            :global .tp-star:nth-child(-n + 4) .tp-star__canvas {
              fill: $headerFullStar;
            }
          }
          > svg.header-trustpilot-stars-4-5 {
            :global .tp-star:nth-child(-n + 4) .tp-star__canvas {
              fill: $headerFullStar;
            }
            :global .tp-star:nth-child(5) .tp-star__canvas--half {
              fill: $headerFullStar;
              visibility: visible;
            }
          }
          > svg.header-trustpilot-stars-5 {
            :global .tp-star:nth-child(-n + 5) .tp-star__canvas {
              fill: $headerFullStar;
            }
          }
        }
      }
    }
    &:nth-child(2) {
      /* ---------------------------------------------------------------- */
      /* Header Trustpilot - Number Of Reviews */
      /* ---------------------------------------------------------------- */
      > div {
        font-size: 14px;
        line-height: 20px;
        margin: 4px 4px 0 6px;
        @media (max-width: 1024px) {
          font-size: 13px;
          line-height: 16px;
          margin: 3px 3px 0 4px;
        }
        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 15px;
          margin: -1px 3px 0 4px;
        }
        @media (max-width: 500px) {
          display: none;
        }
        > span {
          font-family: $semibold;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Header Trustpilot - Trustpilot Logo */
      /* ---------------------------------------------------------------- */
      > svg {
        height: 20px;
        margin: 2px 0 0;
        width: 80px;
        @media (max-width: 1024px) {
          height: 19px;
          margin: 0;
          width: 77px;
        }
        @media (max-width: 767px) {
          height: 16px;
          margin: -3px 0 0;
          width: 65px;
        }
      }
    }
  }
}
