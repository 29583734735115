// Fonts
$bold: "Montserrat Bold", sans-serif;
$semibold: "Montserrat Semibold", sans-serif;
$medium: "Montserrat Medium", sans-serif;
$regular: "Montserrat Regular", sans-serif;
$vrm: "Charles Wright Bold", times new roman;

// Primary Colours
$primaryXDark: #014c83;
$primaryDark: #00538f;
$primary: #006bb6;
$primaryLight: #80b5db;
$primaryXLight: #3af;
$primaryXXLight: #8aa8bd;
$primaryXXXLight: #addfff;
$primaryPastelDark: #c2e7ff;
$primaryPastel: #daf0ff;
$primaryPastelLight: #f4fbff;
$primaryPastelXLight: #ebf7ff;

// Secondary Colours
$secondaryXXDark: #ab590d;
$secondaryXDark: #d16d10;
$secondaryDark: #df7f28;
$secondary: #f08e35;
$secondaryLight: #ffb877;
$secondaryPastel: #fff1e0;

// Shades
$black: #000;
$greyXDark: #212121;
$greyDark: #333;
$grey: #bdbdbd;
$greyMedium: #757575;
$greyLight: #c5c5c5;
$greyXLight: #f0f0f0;
$greyXXLight: #fbfbfb;
$white: #fff;

// Success Colours
$successDark: #1c7742;
$success: #1f8449;
$successLight: #94bba4;
$successXLight: #daffe8;
$successPastel: #edfff4;

// Alert Colours
$alert: #e01a1a;
$alertDark: darken($alert, 12%);
$alertLight: #ff3f3e;
$alertXLight: #ffc4c4;
$alertPastel: #fff5f5;

// Disabled Colours
$disabledDark: #848484;
$disabled: #a3a3a3;
$disabledLight: #c4c4c4;
$disabledXLight: #e1e1e1;

// Misc
$regYellow: #ffdd01;
$adobeRed: #ff3500;
$trustpilotGreen: #00b67a;
$trustpilotGrey: #dcdce6;
$sportsBikeShopBg: #36a9e1;
$sportsBikeShopText: $white;
/* --------------------------------- */
/* Typography */
/* --------------------------------- */
// Text
$text: $greyDark;

// Titles
$title: $primary;
$titleBorder: $primaryPastel;

// Hyperlink
$hyperlink: $secondaryXXDark;
$hyperlinkHover: darken($secondaryXXDark, 10%);
$hyperlinkActive: darken($secondaryXXDark, 20%);
/* --------------------------------- */
/* Scrollbars */
/* --------------------------------- */
$scrollBar: $primaryPastelXLight;
$scrollBarThumb: $primary;
/* --------------------------------- */
/* Page */
/* --------------------------------- */
$pageBg: $primary;
/* --------------------------------- */
/* Header */
/* --------------------------------- */
$headerBg: $primaryDark;
$headerText: $white;
$headerTextNumber: $secondaryLight;
$headerTextPartner: $grey;
$headerTextHighlight: $secondary;
$headerLogoFocus: $secondary;
$headerRetrieveQuoteBg: $primaryXDark;
$headerIcon: $secondary;
$headerTimesBg: $white;
$headerTimesText: $primary;
$headerTimesBoxShadow: $black;
$headerFullStar: $trustpilotGreen;
$headerEmptyStar: $trustpilotGrey;
/* --------------------------------- */
/* USP Bar */
/* --------------------------------- */
$uspBg: $primaryXDark;
$uspBorder: darken($primaryXDark, 5%);
$uspText: $white;
$uspIcon: $white;
/* --------------------------------- */
/* Footer */
/* --------------------------------- */
$footerBg: $primaryDark;
$footerCopyrightText: $greyXLight;
$footerLink: $white;
/* --------------------------------- */
/* Form */
/* --------------------------------- */

// Fieldset
$fieldsetBg: $white;

// Progress
$progressBg: $white;
$progressBoxShadow: $black;
$progressIcon: $disabled;
$progressText: $greyMedium;
$progressBorder: $disabled;
$progressTextActive: $primary;
$progressBorderActive: $secondary;
$progressTextComplete: $greyDark;
$progressBorderComplete: $greyDark;

// Question
$helpTooltipIcon: $secondaryLight;
$helpTooltipIconSelected: $secondary;
$questionSecondaryBg: $greyXXLight;
$questionSecondaryBorder: $greyXLight;
$questionInvalidBg: $alertPastel;
$questionInvalidBorder: $alertXLight;
$questionInvalidText: $alert;
$helpTooltipIconDisabled: rgba($disabled, 0.7);

// Call Us
$callUsIcon: $secondary;
$callUsText: $secondary;

// Input
$inputText: $text;
$inputBg: $white;
$inputBorder: $disabledXLight;
$inputTextPlaceholder: $greyMedium;
$inputBgFocus: $primaryPastelLight;
$inputBorderFocus: $primary;
$inputBorderHover: $primaryXLight;
$inputTextInvalid: $alert;
$inputTextPlaceholderInvalid: lighten($alert, 2%);
$inputBorderInvalid: $alertXLight;
$inputBorderFocusInvalid: $alertLight;
$inputIcon: $secondary;
$inputIconHover: $secondaryLight;
$inputIconInvalid: $alert;
$inputIconDisabled: rgba($disabled, 0.7);
$inputTextDisabled: $disabled;
$inputBorderDisabled: rgba($disabled, 0.7);
$inputVrmBg: $regYellow;
$inputVrmBorder: $greyDark;

// Checkbox
$checkboxBg: $white;
$checkboxBorder: $secondary;
$checkboxIcon: $secondary;
$checkboxBgFocus: $primaryPastelLight;
$checkboxBorderFocus: $secondaryXXDark;
$checkboxBorderHover: $secondaryXDark;
$checkboxIconHover: $secondaryXDark;
$checkboxIconChecked: $secondary;
$checkboxIconCheckedFocus: $secondaryXXDark;
$checkboxBorderInvalid: $alertXLight;
$checkboxBorderFocusInvalid: $alertLight;
$checkboxIconInvalid: $alert;
$checkboxBorderDisabled: $disabledLight;
$checkboxIconDisabled: $disabled;

// Label
$labelDisabled: $disabled;

// Radio & Checkboxes
$formButtonText: $primary;
$formButtonBg: $primaryPastelXLight;
$formButtonBorder: $primaryPastel;
$formButtonBgHover: $primaryPastelDark;
$formButtonBorderHover: $primaryXXXLight;
$formButtonTextSelected: $white;
$formButtonBgSelected: $primaryDark;
$formButtonBorderSelected: shade($primaryXDark, 15%);
$formButtonTextInvalid: $alert;
$formButtonBgInvalid: $alertXLight;
$formButtonBorderInvalid: $alertXLight;
$formButtonBgHoverInvalid: darken($alertXLight, 7.5%);
$formButtonBorderHoverInvalid: darken($alertXLight, 7.5%);
$formButtonBgSelectedInvalid: $alert;
$formButtonBorderSelectedInvalid: $alert;
$formButtonTextDisabled: rgba($disabled, 0.7);
$formButtonBgDisabled: $disabledXLight;
$formButtonBorderDisabled: rgba($disabled, 0.7);

// Autocomplete
$autocompleteText: $text;
$autocompleteBorder: $primary;
$autocompleteBg: $white;
$autocompleteScrollBar: $scrollBar;
$autocompleteScrollBarThumb: $scrollBarThumb;
$autocompleteTextSelected: $success;
$autocompleteTextHover: $primaryPastel;

// Claims and Convictions
$claimsConvictionsBg: $primaryPastelXLight;
$claimsConvictionsBorder: $primaryPastel;

// Riders
$ridersInvalidText: $alert;
$ridersLoadingIcon: $white;
$ridersLoadingIconBg: $secondary;

// Vehicles
$vehiclesInvalidText: $alert;
$vehiclesLoadingIcon: $white;
$vehiclesLoadingIconBg: $secondary;
$vehiclesLoadingIconBgDisabled: $disabledLight;

// Address Lookup
$addressLookupDescriptionText: $primary;
$addressLookupDescriptionIcon: $primary;
$addressLookupDescriptionBorder: $disabledXLight;
$addressLookupDescriptionBg: $white;
$addressLookupDescriptionTextInvalid: $alertLight;
$addressLookupDescriptionIconInvalid: $alertLight;
$addressLookupDescriptionBorderInvalid: $alertXLight;

// Vehicle Lookup
$vehicleLookupDescriptionText: $primary;
$vehicleLookupDescriptionIcon: $primary;
$vehicleLookupDescriptionBorder: $disabledXLight;
/* --------------------------------- */
/* Buttons */
/* --------------------------------- */

// Primary
$primaryButtonText: $greyXDark;
$primaryButtonBg: $secondary;
$primaryButtonBorder: $secondaryDark;
$primaryButtonBgHover: $secondaryXDark;
$primaryButtonBorderHover: $secondaryXDark;
$primaryButtonTextActive: $white;
$primaryButtonBgActive: $secondaryXXDark;
$primaryButtonBorderActive: $secondaryXXDark;
$primaryButtonTextDisabled: $disabledXLight;
$primaryButtonBgDisabled: $disabled;
$primaryButtonBorderDisabled: $disabledDark;

// Secondary
$secondaryButtonText: $secondaryXXDark;
$secondaryButtonBg: $white;
$secondaryButtonBorder: $secondaryXXDark;
$secondaryButtonTextHover: $secondaryXXDark;
$secondaryButtonBgHover: $secondaryPastel;
$secondaryButtonBorderHover: $secondaryXXDark;
$secondaryButtonTextActive: shade($secondaryXXDark, 10%);
$secondaryButtonBgActive: shade($secondaryPastel, 8%);
$secondaryButtonBorderActive: tint($secondaryXXDark, 50%);
$secondaryButtonTextDisabled: $disabled;
$secondaryButtonBgDisabled: $disabledXLight;
$secondaryButtonBorderDisabled: $disabled;

// Success
$successButtonText: $white;
$successButtonBg: $success;
$successButtonBorder: $successDark;
$successButtonBgHover: tint($success, 2.5%);
$successButtonBorderHover: tint($success, 40%);
$successButtonBgActive: darken($successDark, 10%);
$successButtonBorderActive: darken($successDark, 10%);
$successButtonTextDisabled: $disabledXLight;
$successButtonBgDisabled: $disabled;
$successButtonBorderDisabled: $disabledDark;

// Success Inverted
$successButtonInvertedBorder: $success;
$successButtonInvertedBgHover: rgba($success, 0.1);
$successButtonInvertedText: $success;
$successButtonInvertedIcon: $success;
$successButtonInvertedBorderHover: $successDark;
$successButtonInvertedTextHover: $successDark;
$successButtonInvertedIconHover: $successDark;
$successButtonInvertedBorderActive: darken($successDark, 5%);
$successButtonInvertedTextActive: darken($successDark, 5%);
$successButtonInvertedIconActive: darken($successDark, 5%);

// Invalid
$invalidButtonText: $alertDark;
$invalidButtonBg: $alertXLight;
$invalidButtonBorder: $alertXLight;
$invalidButtonBgHover: lighten($alertXLight, 2.5%);
$invalidButtonBorderHover: darken($alertXLight, 2.5%);
$invalidButtonTextActive: $white;
$invalidButtonBgActive: $alertDark;
$invalidButtonBorderActive: $alertDark;
$invalidButtonTextDisabled: rgba($disabled, 0.7);
$invalidButtonBgDisabled: $disabledXLight;
$invalidButtonBorderDisabled: rgba($disabled, 0.7);

// Vehicle Lookup
$vehicleLookupDescriptionText: $primary;
$vehicleLookupDescriptionIcon: $primary;
$vehicleLookupDescriptionBorder: $disabledXLight;
/* --------------------------------- */
/* Modal */
/* --------------------------------- */
$modalBackdrop: rgba($primary, 0.4);
$modalBg: $white;
$modalListBullet: $primary;
$modalHeaderBg: $primaryPastelXLight;
$modalHeaderBorder: $primaryPastel;
$modalScrollBar: $scrollBar;
$modalScrollBarThumb: $scrollBarThumb;
$modalCloseIcon: $primary;
$modalCloseIconHover: $primaryXDark;
$modalCloseIconActive: $primaryXDark;
/* --------------------------------- */
/* Recall */
/* --------------------------------- */
$recallIcon: $primary;
$recallBannerTitle: $text;
/* --------------------------------- */
/* Confirmation */
/* --------------------------------- */

// Message
$confirmationMessageBg: $fieldsetBg;
$confirmationMessageTitle: $title;
$confirmationMessageTitleBg: $primaryPastelXLight;
$confirmationMessageTitleBorder: $primaryPastel;

// Banner
$confirmationBannerOverlay: $secondary;

// Competition
$confirmationCompetitionText: $white;
$confirmationCompetitionBg: $secondaryDark;
$confirmationCompetitionBgHover: darken($secondaryDark, 5%);
$confirmationCompetitionBgActive: darken($secondaryDark, 10%);

// Pages and Blog
$confirmationPagesTitle: $white;
$confirmationPagesImageBg: $greyXLight;

// Social
$confirmationSocialTitle: $primary;
$confirmationSocailIcon: $secondary;
$confirmationSocailIconHover: $secondaryXDark;
$confirmationSocailIconActive: $secondaryXXDark;
/* --------------------------------- */
/* Payment */
/* --------------------------------- */

// Summary
$paymentSummaryTotal: $primary;
$paymentSummaryBg: $primaryPastelXLight;
$paymentSummaryBorder: $primaryPastel;

// Legal
$paymentLegalLink: $secondaryXXDark;
$paymentLegalCheckbox: $secondary;
/* --------------------------------- */
/* Quote */
/* --------------------------------- */
$quoteBg: $white;
$quoteLegal: $greyMedium;
$quoteTickBg: $success;
$quoteFeaturesBg: $greyXXLight;
$quoteFeaturesBorder: $greyXLight;
$quotePrice: $primary;
$quotePriceBg: $primaryPastelXLight;
$quotePriceBorder: $primaryPastel;
$quoteDiscount: $primary;
$quoteButtonBgSelected: $white;
$quoteButtonBorderSelected: $success;
$quoteButtonTextSelected: $success;
$quoteButtonIconSelected: $success;
$quoteButtonBgSelectedHover: rgba($success, 0.1);
$quoteButtonTextSelectedHover: $successDark;
$quoteButtonIconSelectedHover: $successDark;
/* --------------------------------- */
/* Product / Policy */
/* --------------------------------- */
$productBg: $white;
$productPrimaryBg: $primaryPastelXLight;
$productPrimaryBorder: $primaryPastel;
$productSecondaryBg: $secondaryPastel;
$productSecondaryBorder: darken($secondaryPastel, 5%);
$productText: $white;
$productTitle: $title;
$productWebReference: $success;
$productShoppingCartBorder: $secondary;
$productLegal: $greyMedium;
$productButtonBg: $primaryPastelXLight;
$productButtonBorder: $primaryPastel;
$productButtonBgHover: $primaryPastelDark;
$productButtonBorderHover: $primaryXXXLight;
$productButtonSelectedBg: $white;
$productButtonSelectedBorder: $success;
$productButtonSelectedText: $success;
$productChevron: $secondary;
$productIncludedBg: $success;
$productIncludedText: $white;

$productTextDisabled: $disabledDark;
$productBgDisabled: $disabledXLight;

// Product - Product Overview
$productOverviewInfo: $primary;
$productOverviewTick: $success;
$productOverviewButtonBg: $secondary;
$productOverviewButtonBorder: $disabledXLight;
$productOverviewButtonBorderHover: $secondary;
$productOverviewButtonBgHover: $secondaryPastel;
$productOverviewButtonSelected: $success;
$productOverviewButtonBgSelected: $successPastel;
$productOverviewButtonBgDisabled: $disabledXLight;
$productOverviewButtonBorderDisabled: $disabledLight;
$productOverviewButtonTextDisabled: $disabledDark;

// Product - Policy Info
$productPolicyInfoTotalBg: $primaryPastel;

// Product - Addons
$productTableBorder: $disabledXLight;
$productTableSelectedPrimaryBg: $successPastel;
$productTableSelectedSecondaryBg: $successXLight;
$productTableSelectedBorder: $success;
$productTableTick: $success;
$productTableCross: $alert;
$productTableMobileHeading: $primaryDark;

// Product - Your Details
$productDetailsGradientLight: $primaryXLight;
$productDetailsGradientDark: $primary;
$productTableIcons: $primary;

// Policy - Policy Documents
$productPolicyLinkBg: $primaryPastelXLight;
$productPolicyLinkBorder: $primaryPastel;
$productPolicyLinkText: $primary;
$productPolicyLinkBgHover: $primaryPastelDark;
$productPolicyLinkBorderHover: $primaryXXXLight;
$productPolicyLinkBgActive: $primaryPastel;
$productPolicyLinkBorderActive: $primaryXXXLight;
$productAdobeLinkLogoBg: radial-gradient(65.2% 67.02% at 39.87% 33.31%, rgba(29, 15, 11, 0.85) 0%, #1d0f0b 76.7%);
$productAdobeLinkLogoIcon: $white;
$productAdobeLinkBorder: $adobeRed;
$productAdobeLinkBg: $white;

// Policy - Accordions
$policyAccordionIcon: $primary;
/* --------------------------------- */
/* Error */
/* --------------------------------- */
$errorBg: $white;
$errorTitle: $title;
$errorSubtitle: $title;
$errorSubtitleBorder: $primaryPastelXLight;
$errorHighlight: $secondary;
$errorTextInvalid: $alert;
/* --------------------------------- */
/* Icons */
/* --------------------------------- */

// Helmet
$iconHelmetStroke: $greyDark;
$iconHelmetOuterShell: $primary;
$iconHelmetBase: $primaryXXLight;
$iconHelmetVisor: $primaryPastelXLight;
$iconHelmetPivot: $primaryXXXLight;

$iconHelmetStrokeMainRider: $greyDark;
$iconHelmetOuterShellMainRider: $success;
$iconHelmetBaseMainRider: $successLight;
$iconHelmetVisorMainRider: $primaryPastelXLight;
$iconHelmetPivotMainRider: $successLight;

$iconHelmetStrokeInvalid: $greyDark;
$iconHelmetOuterShellInvalid: $alert;
$iconHelmetBaseInvalid: $alertXLight;
$iconHelmetVisorInvalid: $alertPastel;
$iconHelmetPivotInvalid: $alertXLight;

$iconHelmetOuterShellDisabled: $disabledDark;
$iconHelmetBaseDisabled: $disabledDark;
$iconHelmetVisorDisabled: $disabledXLight;
$iconHelmetPivotDisabled: $disabledLight;

// Bike
$iconBikeStroke: $greyDark;
$iconBikeSeat: $primaryXXLight;
$iconBikeWheels: $primaryXXXLight;
$iconBikeBody: $primary;
$iconBikeTyres: $primaryXXLight;

$iconBikeStrokeMainBike: $greyDark;
$iconBikeSeatMainBike: $successLight;
$iconBikeWheelsMainBike: $successLight;
$iconBikeBodyMainBike: $success;
$iconBikeTyresMainBike: $successLight;

$iconBikeStrokeInvalid: $greyDark;
$iconBikeSeatInvalid: $alertXLight;
$iconBikeWheelsInvalid: $alertLight;
$iconBikeBodyInvalid: $alert;
$iconBikeTyresInvalid: $alertXLight;

// Main Rider Helmet
$iconHelmetOuterShellMainRider: $success;
$iconHelmetBaseMainRider: $successLight;
$iconHelmetVisorMainRider: $primaryPastelXLight;
$iconHelmetPivotMainRider: $successLight;
$iconHelmetStrokeMainRider: $greyDark;

// Claims
$iconClaimPage: $white;
$iconClaimText: $greyDark;
$iconClaimHighlighted: $primary;
$iconClaimStroke: $greyDark;

$iconClaimPageInvalid: $white;
$iconClaimTextInvalid: $alert;
$iconClaimHighlightedInvalid: $alert;
$iconClaimStrokeInvalid: $greyDark;

// Convictions
$iconConvictionHammerStroke: $greyDark;
$iconConvictionHammerGavel: $primaryXLight;
$iconConvictionHammerBase: $primaryPastelXLight;
$iconConvictionHammerHead: $primaryXXLight;
$iconConvictionHammerHandle: $primary;

$iconConvictionHammerStrokeInvalid: $greyDark;
$iconConvictionHammerGavelInvalid: $alert;
$iconConvictionHammerBaseInvalid: $alertXLight;
$iconConvictionHammerHeadInvalid: $alert;
$iconConvictionHammerHandleInvalid: $alertLight;

// Legal
$iconHammerStroke: $greyDark;
$iconHammerGavel: $primaryXLight;
$iconHammerBase: $primaryPastelXLight;
$iconHammerHead: $primaryXXLight;
$iconHammerHandle: $primary;

$iconHammerHeadDisabled: $disabledDark;
$iconHammerBaseDisabled: $disabledXLight;
$iconHammerGavelDisabled: $disabledLight;
$iconHammerHandleDisabled: $disabledDark;

// Accident
$accidentStroke: $greyDark;
$accidentBg: $primary;
$accidentCross: $primaryPastelXLight;

$accidentBgDisabled: $disabledDark;
$accidentCrossDisabled: $disabledXLight;
/* --------------------------------- */
/* Admin Nav */
/* --------------------------------- */
$adminLink: $white;
