@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Question Vrm Main Vehicle */
/* ---------------------------------------------------------------- */
.question-vrm-main-vehicle {
  display: flex;
  width: 412px;
  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
}
/* ---------------------------------------------------------------- */
/* Question Vrm Main Vehicle Buttons */
/* ---------------------------------------------------------------- */
.question-vrm-main-vehicle-buttons {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 8px;
  @media (max-width: 1024px) {
    flex-direction: row;
    margin-top: 12px;
    padding-left: 0;
  }
  @media (max-width: 570px) {
    flex-direction: column;
  }
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (max-width: 1024px) {
      flex-direction: row;
      width: 100%;
    }
    @media (max-width: 570px) {
      flex-direction: column;
    }
    > div[role="button"],
    > button {
      height: 48px;
      padding: 0 8px;
      width: 100%;
      @media (max-width: 1024px) {
        height: 50px;
      }
      &:last-child {
        margin-top: auto;
        @media (max-width: 1024px) {
          margin-left: 12px;
        }
        @media (max-width: 570px) {
          margin: 12px 0 0 0;
        }
      }
      span {
        font-size: 15px;
        @media (max-width: 1024px) {
          font-size: 16px;
        }
        @media (max-width: 670px) {
          font-size: 15px;
        }
        @media (max-width: 570px) {
          font-size: 16px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Loading */
  /* ---------------------------------------------------------------- */
  &.loading {
    display: flex;
    pointer-events: none;
    > span {
      align-items: center;
      background-color: $vehiclesLoadingIconBg;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      height: 32px;
      justify-content: center;
      position: absolute;
      right: -72px;
      top: 0;
      width: 32px;
      z-index: 1;
      @media (max-width: 1060px) {
        right: -44px;
      }
      @media (max-width: 1024px) {
        height: 28px;
        right: 24px;
        top: 5px;
        width: 28px;
      }
      @media (max-width: 767px) {
        right: 16px;
      }
      > svg {
        height: 28px;
        width: 28px;
        @media (max-width: 1024px) {
          height: 24px;
          width: 24px;
        }
        * {
          &:nth-child(1) {
            stroke: $vehiclesLoadingIcon;
          }
          &:nth-child(2) {
            fill: $vehiclesLoadingIcon;
          }
        }
      }
    }
  }
}
