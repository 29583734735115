@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Defaqto */
/* ---------------------------------------------------------------- */
.defaqto {
  background-image: url("/images/defaqto.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  display: block;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 79px;
  &::before {
    content: "";
    display: block;
    padding-top: calc(calc(380px / 600px) * 100%);
  }
  @media (max-width: 1350px) {
    width: 66px;
  }
  @media (max-width: 1230px) {
    width: 60px;
  }
  @media (max-width: 1134px) and (min-width: 500px) {
    width: 54px;
  }
  @media (max-width: 920px) and (min-width: 441px) {
    width: 47px;
  }
  @media (max-width: 767px) {
    width: 79px;
  }
  @media (max-width: 500px) {
    width: 54px;
  }
}
