@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Included */
/* ---------------------------------------------------------------- */
.included {
  align-items: center;
  display: flex;
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  > div[role="button"],
  > button {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    font-family: $regular;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 16px;
    @include fast-transition($props: "color");
    @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
      align-items: flex-start;
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 11px;
    }
    &:focus {
      color: $hyperlinkHover;
      text-decoration: underline;
      text-decoration-color: $hyperlinkHover;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $hyperlinkHover;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        color: $hyperlinkActive;
      }
    }
    &:nth-child(1) {
      max-width: 100%;
      @include user-select;
      > span {
        padding-right: 12px;
        text-align: left;
        @include fast-transition($mob: true, $props: "color");
        @media (max-width: 1240px) {
          padding-right: 0;
        }
        @media (max-width: 550px) {
          padding-right: 0;
        }
      }
      > svg {
        flex-shrink: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Info Icon */
    /* ---------------------------------------------------------------- */
    > svg {
      height: 20px;
      margin-right: 11px;
      width: 20px;
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        height: 16px;
        margin-right: 7px;
        position: relative;
        top: 1px;
        width: 16px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        height: 13px;
        margin-right: 8px;
        top: 3px;
        width: 13px;
      }
      * {
        fill: $productOverviewTick;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    > div[role="button"],
    > button {
      color: $productOverviewButtonTextDisabled;
      > svg * {
        fill: $productOverviewButtonTextDisabled;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Optional */
  /* ---------------------------------------------------------------- */
  &.optional {
    > div[role="button"],
    > button {
      font-family: $bold;
    }
  }
}
