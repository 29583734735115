@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modal Form */
/* ---------------------------------------------------------------- */
.modal-form {
  background-color: $modalBg;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 960px;
  min-height: 198px;
  overflow: hidden;
  position: relative;
  width: calc(100% - 64px);
  z-index: 1;
  @media (max-width: 1024px) {
    max-height: calc(100% - 48px);
    min-height: 172px;
    width: calc(100% - 48px);
  }
  @media (max-width: 767px) {
    max-height: calc(100% - 32px);
    min-height: 154px;
    width: calc(100% - 32px);
  }
  :global(.MuiDialog-container) & {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);
    margin: 32px;
    @media (max-width: 1024px) {
      margin: 24px;
    }
    @media (max-width: 767px) {
      margin: 16px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Submitting */
  /* ---------------------------------------------------------------- */
  &.submitting {
    cursor: default;
    pointer-events: none;
  }
  /* ---------------------------------------------------------------- */
  /* Fieldset */
  /* ---------------------------------------------------------------- */
  > fieldset {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    /* ---------------------------------------------------------------- */
    /* Header */
    /* ---------------------------------------------------------------- */
    > header {
      background-color: $fieldsetBg;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      box-sizing: border-box;
      padding: 40px 0 0;
      @media (max-width: 1024px) {
        padding: 32px 0 0;
      }
      @media (max-width: 767px) {
        border-radius: 0;
        padding: 20px 0 0;
      }
      /* ---------------------------------------------------------------- */
      /* Legend */
      /* ---------------------------------------------------------------- */
      > legend {
        box-sizing: border-box;
        float: left;
        margin: 0 0 36px;
        padding: 0 32px;
        width: 100%;
        @media (max-width: 1024px) {
          margin: 0 0 28px;
          padding: 0 24px;
        }
        @media (max-width: 767px) {
          margin: 0 0 24px;
          padding: 0 16px;
        }
        &:first-child {
          margin-top: -4px;
        }
        + * {
          clear: both;
        }
        /* ---------------------------------------------------------------- */
        /* Legend - Title */
        /* ---------------------------------------------------------------- */
        > h2 {
          color: $title;
          font-family: $bold;
          font-size: 30px;
          line-height: 32px;
          position: relative;
          text-transform: capitalize;
          @include no-user-select;
          @media (max-width: 1024px) {
            font-size: 27px;
            line-height: 30px;
          }
          @media (max-width: 767px) {
            font-size: 24px;
            line-height: 28px;
          }
          &::after {
            background-color: $titleBorder;
            content: "";
            display: block;
            height: 2px;
            margin: 14px 0 0;
            position: absolute;
            width: 100%;
            @media (max-width: 1024px) {
              margin: 6px 0 0;
            }
          }
          /* ---------------------------------------------------------------- */
          /* Legend - Icon */
          /* ---------------------------------------------------------------- */
          > svg {
            flex-shrink: 0;
            position: absolute;
            top: 50%;
            &[data-style="conviction"] {
              height: 40px;
              margin-top: -20px;
              width: 40px;
              @media (max-width: 1024px) {
                height: 30px;
                margin-top: -15px;
                width: 30px;
              }
              + span {
                padding-left: 56px;
                @media (max-width: 1024px) {
                  padding-left: 40px;
                }
              }
              /* ---------------------------------------------------------------- */
              /* Hammer - Icon - Head */
              /* ---------------------------------------------------------------- */
              *:nth-child(1),
              *:nth-child(2),
              *:nth-child(3) {
                fill: $iconConvictionHammerHead;
              }
              /* ---------------------------------------------------------------- */
              /* Hammer - Icon - Base */
              /* ---------------------------------------------------------------- */
              *:nth-child(4) {
                fill: $iconConvictionHammerBase;
              }
              /* ---------------------------------------------------------------- */
              /* Hammer - Icon - Gavel */
              /* ---------------------------------------------------------------- */
              *:nth-child(5),
              *:nth-child(6) {
                fill: $iconConvictionHammerGavel;
              }
              /* ---------------------------------------------------------------- */
              /* Hammer - Icon - Handle */
              /* ---------------------------------------------------------------- */
              *:nth-child(7) {
                fill: $iconConvictionHammerHandle;
              }
              /* ---------------------------------------------------------------- */
              /* Hammer - Icon - Stroke */
              /* ---------------------------------------------------------------- */
              *:nth-child(8),
              *:nth-child(9) {
                fill: $iconConvictionHammerStroke;
              }
            }
            &[data-style="claim"] {
              height: 40px;
              margin-top: -20px;
              width: 50px;
              @media (max-width: 1024px) {
                height: 30px;
                margin-top: -15px;
                width: 36px;
              }
              + span {
                padding-left: 66px;
                @media (max-width: 1024px) {
                  padding-left: 47px;
                }
              }
              /* ---------------------------------------------------------------- */
              /* Claim - Icon - Text */
              /* ---------------------------------------------------------------- */
              * {
                fill: $iconClaimText;
              }
              /* ---------------------------------------------------------------- */
              /* Claim - Icon - Highlighted */
              /* ---------------------------------------------------------------- */
              *:nth-child(3),
              *:nth-child(7),
              *:nth-child(22) {
                fill: $iconClaimHighlighted;
              }
              /* ---------------------------------------------------------------- */
              /* Claim - Icon - Page */
              /* ---------------------------------------------------------------- */
              *:nth-child(1),
              *:nth-child(5),
              *:nth-child(15) {
                fill: $iconClaimPage;
              }
              /* ---------------------------------------------------------------- */
              /* Claim - Icon - Stroke */
              /* ---------------------------------------------------------------- */
              *:nth-child(2),
              *:nth-child(6),
              *:nth-child(16) {
                fill: $iconClaimStroke;
              }
            }
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Scroll Container */
    /* ---------------------------------------------------------------- */
    > div {
      box-sizing: border-box;
      margin: 0 0 30px;
      overflow-y: auto;
      position: relative;
      scrollbar-color: $modalScrollBarThumb $modalScrollBar;
      scrollbar-width: thin;
      @media (max-width: 1024px) {
        width: 100%;
      }
      :global(.platform-windows) & {
        width: calc(100% - 1px);
      }
      :global(.browser-firefox.platform-windows) & {
        width: 100%;
      }
      /* ---------------------------------------------------------------- */
      /* Scroll Bar - IE */
      /* ---------------------------------------------------------------- */
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        scrollbar-3dlight-color: $modalScrollBarThumb;
        scrollbar-arrow-color: $modalScrollBarThumb;
        scrollbar-base-color: $modalScrollBar;
        scrollbar-face-color: $modalScrollBarThumb;
        scrollbar-highlight-color: $modalScrollBarThumb;
        scrollbar-shadow-color: $modalScrollBarThumb;
        scrollbar-track-color: $modalScrollBar;
      }
      /* ---------------------------------------------------------------- */
      /* Scroll Bar - Webkit / Android / Samsung */
      /* ---------------------------------------------------------------- */
      :global(.platform-windows) &,
      :global(.platform-mobile) & {
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: $modalScrollBar;
          border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $modalScrollBarThumb;
          border-radius: 4px;
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      }
      /* ---------------------------------------------------------------- */
      /* Form Content - Question */
      /* ---------------------------------------------------------------- */
      > div {
        margin-left: 32px;
        margin-right: 32px;
        @media (max-width: 1024px) {
          left: 0;
          margin-left: 0;
          margin-right: 0;
          width: 100%;
        }
        :global(.platform-windows) & {
          margin-left: 32px;
          margin-right: 25px;
          @media (max-width: 1024px) {
            margin-left: 0;
            margin-right: 0;
            padding-right: 17px;
          }
          @media (max-width: 767px) {
            padding-right: 9px;
          }
        }
        :global(.platform-windows.browser-firefox) & {
          margin-right: 24px;
          @media (max-width: 1024px) {
            margin-left: 0;
            margin-right: 0;
            padding-right: 16px;
          }
          @media (max-width: 767px) {
            padding-right: 8px;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Form Content - Tooltip */
        /* ---------------------------------------------------------------- */
        > div:nth-child(2) > div:nth-child(1) > svg {
          @media (max-width: 1060px) {
            right: -44px !important;
          }
          @media (max-width: 1024px) {
            right: 24px !important;
          }
          @media (max-width: 767px) {
            right: 16px !important;
          }
          :global(.platform-windows) & {
            @media (max-width: 1024px) {
              right: 17px !important;
            }
            @media (max-width: 767px) {
              right: 9px !important;
            }
          }
          :global(.platform-windows.browser-firefox) & {
            @media (max-width: 1024px) {
              right: 16px !important;
            }
            @media (max-width: 767px) {
              right: 8px !important;
            }
          }
        }
      }
      > div:last-child {
        &[class] {
          margin-bottom: 12px;
          margin-left: 32px;
          margin-right: 32px;
          @media (max-width: 1024px) {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Footer */
    /* ---------------------------------------------------------------- */
    > footer {
      box-sizing: border-box;
      display: flex;
      flex-shrink: 0;
      padding: 0 32px 24px;
      position: relative;
      width: 100%;
      @media (max-width: 1024px) {
        padding: 0 24px 20px;
      }
      @media (max-width: 767px) {
        padding: 0 16px 16px;
      }
      &::before {
        background-color: $titleBorder;
        content: "";
        display: block;
        height: 2px;
        left: 32px;
        position: absolute;
        top: -22px;
        width: calc(100% - 64px);
        @media (max-width: 1024px) {
          left: 24px;
          margin: 6px 0 0;
          top: -20px;
          width: calc(100% - 48px);
        }
        @media (max-width: 767px) {
          left: 16px;
          width: calc(100% - 32px);
        }
      }
      /* ---------------------------------------------------------------- */
      /* Footer - Buttons */
      /* ---------------------------------------------------------------- */
      > div[role="button"],
      > button {
        flex-shrink: 0;
        min-width: 150px;
        @media (max-width: 767px) {
          padding: 0 20px;
        }
        @media (max-width: 440px) {
          flex-basis: calc(50% - 4px);
          max-width: calc(50% - 4px);
          min-width: auto;
          padding: 0 12px;
        }
        span {
          @media (max-width: 1024px) {
            font-size: 15px;
          }
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
        &:first-child {
          margin: 0 20px 0 auto;
          @media (max-width: 1024px) {
            margin: 0 16px 0 auto;
          }
          @media (max-width: 767px) {
            margin: 0 12px 0 auto;
          }
          @media (max-width: 440px) {
            margin: 0 8px 0 auto;
          }
        }
      }
    }
  }
}
