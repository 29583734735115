@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Documents Item */
/* ---------------------------------------------------------------- */
.item {
  flex-basis: calc(50% - 12px);
  margin: 0 6px 8px;
  min-width: calc(50% - 12px);
  @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
    margin-bottom: 8px;
    min-width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Link */
  /* ---------------------------------------------------------------- */
  > a {
    align-items: center;
    background-color: $productPolicyLinkBg;
    border-color: $productPolicyLinkBorder;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-start;
    padding: 9px 12px;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    width: 100%;
    @include slow-transition($mob: true, $props: "border-color, background-color");
    @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
      padding-bottom: 8px;
      padding-left: 8px;
      padding-right: 8px;
    }
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
      flex-direction: row;
      justify-content: flex-start;
      padding: 8px 16px;
    }
    &::before {
      content: none;
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      background-color: $productPolicyLinkBgHover;
      border-color: $productPolicyLinkBorderHover;
      span {
        text-decoration: underline;
        text-decoration-color: $productPolicyLinkText;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        background-color: $productPolicyLinkBgHover;
        border-color: $productPolicyLinkBorderHover;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Active */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        background-color: $productPolicyLinkBgActive;
        border-color: $productPolicyLinkBorderActive;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    span:last-child {
      color: $productPolicyLinkText;
      font-family: $medium;
      font-size: 16px;
      line-height: 20px;
      pointer-events: none;
      text-align: left;
      white-space: normal;
      @include slow-transition($mob: true, $props: "color");
      @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
        font-size: 15px;
        line-height: 19px;
      }
      > small {
        font-size: 13px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      flex-shrink: 0;
      height: 30px;
      margin: 0 10px 0 0;
      width: 30px;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        height: 30px;
        margin: 0 16px 0 0;
        width: 30px;
      }
      * {
        fill: $productPolicyLinkText;
      }
    }
  }
}
