@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Confirmation - Banner */
/* ---------------------------------------------------------------- */
.confirmation-banner {
  align-items: center;
  background-color: $sportsBikeShopBg;
  background-image: url("/images/sbs-logo.svg");
  background-position: 190% 55%;
  background-repeat: no-repeat;
  background-size: 120%;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  box-sizing: border-box;
  display: flex;
  margin-top: 20px;
  overflow: hidden;
  padding: 43px 48px;
  position: relative;
  will-change: transform;
  @include fast-transition($props: "background-color");
  @media (max-width: 1024px) {
    padding: 33px 36px 32px;
  }
  @media (max-width: 767px) {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 16px;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
  /* ---------------------------------------------------------------- */
  /* Social */
  /* ---------------------------------------------------------------- */
  + div {
    @media (max-width: 767px) {
      margin-top: auto;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Image */
  /* ---------------------------------------------------------------- */
  > svg {
    flex-shrink: 0;
    height: 74px;
    margin-left: auto;
    width: 320px;
    @media (max-width: 1240px) {
      height: 68px;
      width: 294px;
    }
    @media (max-width: 980px) {
      height: 59px;
      width: 255px;
    }
    @media (max-width: 900px) {
      height: 56px;
      width: 242px;
    }
    @media (max-width: 820px) {
      height: 49px;
      width: 212px;
    }
    @media (max-width: 600px) {
      margin: 16px auto 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Focus */
  /* ---------------------------------------------------------------- */
  &:focus {
    background-color: darken($sportsBikeShopBg, 5%);
    p {
      text-decoration: underline;
      text-decoration-color: $sportsBikeShopText;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Hover */
  /* ---------------------------------------------------------------- */
  :global(.platform-desktop) & {
    &:hover {
      background-color: darken($sportsBikeShopBg, 5%);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Active */
  /* ---------------------------------------------------------------- */
  :global(.platform-desktop) &,
  :global(.platform-mobile) & {
    &:active {
      background-color: darken($sportsBikeShopBg, 10%);
    }
  }
  > p {
    color: $sportsBikeShopText;
    font-family: $bold;
    font-size: 23px;
    line-height: 28px;
    max-width: 500px;
    padding: 0 48px 0 0;
    position: relative;
    z-index: 1;
    @include user-select;
    @media (max-width: 1240px) {
      font-size: 21px;
      line-height: 26px;
    }
    @media (max-width: 980px) {
      font-size: 19px;
      line-height: 23px;
    }
    @media (max-width: 900px) {
      font-size: 18px;
      line-height: 22px;
    }
    @media (max-width: 820px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 767px) {
      max-width: 450px;
    }
    @media (max-width: 600px) {
      max-width: 100%;
      padding: 0;
      text-align: center;
    }
  }
  > button {
    flex-shrink: 0;
  }
}
