@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Label */
/* ---------------------------------------------------------------- */
.label {
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  padding: 0 40px 0 20px;
  @include no-user-select;
  @media (max-width: 1024px) {
    padding: 0 32px 0 0;
  }
  @media (max-width: 767px) {
    padding: 0 16px 0 0;
  }
  > span {
    font-size: 15px;
    line-height: 19px;
  }
  > small {
    font-size: 13px;
    line-height: 17px;
  }
  > p:not(:first-child) {
    margin-top: 10px;
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  > button {
    color: $hyperlink;
    cursor: pointer;
    display: inline;
    font-family: $bold;
    font-size: 16px;
    line-height: 20px;
    text-decoration: underline;
    text-decoration-color: $hyperlink;
    &:focus {
      color: $hyperlinkHover;
      text-decoration: underline;
      text-decoration-color: $hyperlinkHover;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $hyperlinkHover;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        color: $hyperlinkActive;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    color: $labelDisabled;
    pointer-events: none;
    /* ---------------------------------------------------------------- */
    /* Remove Selection Colour - IE */
    /* ---------------------------------------------------------------- */
    &::selection {
      color: $labelDisabled;
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    > button {
      color: $labelDisabled;
      pointer-events: none;
      text-decoration: none;
    }
  }
}
