@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Web Reference */
/* ---------------------------------------------------------------- */
.web-reference {
  border-color: $primary;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  color: $primary;
  font-family: $regular;
  font-size: 12px;
  line-height: 15px;
  margin: 0 0 14px;
  padding: 10px 10px 10px 10px;
  > strong {
    color: $success;
    font-family: $bold;
    white-space: nowrap;
  }
  @media (max-width: 1230px) {
    font-size: 11px;
    line-height: 14px;
  }
}
