@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Header */
/* ---------------------------------------------------------------- */
.header {
  background: $headerBg;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 128px;
  padding: 0;
  width: 100%;
  @media (max-width: 1024px) {
    height: 116px;
  }
  @media (max-width: 767px) {
    height: 82px;
  }
  /* ---------------------------------------------------------------- */
  /* Header + Debug Navigation */
  /* ---------------------------------------------------------------- */
  ~ nav,
  ~ main::before {
    top: 128px;
    @media (max-width: 1024px) {
      top: 116px;
    }
    @media (max-width: 767px) {
      top: 82px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header + Live Chat Button */
  /* ---------------------------------------------------------------- */
  ~ div > {
    div[role="button"],
    button {
      top: 408px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header - Container */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    margin: 0 auto;
    max-width: 1360px;
    padding: 0 32px;
    position: relative;
    @media (max-width: 1024px) {
      padding: 0 24px;
    }
    @media (max-width: 767px) {
      padding: 0 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Header - Container - Columns */
    /* ---------------------------------------------------------------- */
    > div {
      &:nth-child(1) {
        align-self: flex-start;
        margin: 5px 0 0;
        padding: 23px 32px 0 0;
        @media (max-width: 1024px) {
          padding: 21px 16px 0 0;
        }
        @media (max-width: 767px) {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          margin: 0;
          padding: 0 8px 0 0;
        }
      }
      &:nth-child(2) {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        margin: 3px 0 0 auto;
        @media (max-width: 350px) {
          position: absolute;
          right: 16px;
        }
      }
    }
  }
}
