@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Endorsement */
/* ---------------------------------------------------------------- */
.policy-endorsement {
  padding: 19px 0 0;
  position: relative;
  @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
    padding: 15px 0 0;
  }
  &:first-child {
    padding: 0;
  }
  &::after {
    background-color: $titleBorder;
    content: "";
    display: block;
    height: 2px;
    position: relative;
    width: 100%;
  }
  &:last-child {
    &::after {
      content: none;
    }
  }
  > header {
    padding-bottom: 19px;
    @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
      padding-bottom: 15px;
    }
    > div[role="button"],
    > button {
      align-items: center;
      cursor: pointer;
      display: flex;
      text-align: left;
      width: 100%;
      > span {
        font-family: $bold;
        /* ---------------------------------------------------------------- */
        /* Title */
        /* ---------------------------------------------------------------- */
        &:nth-child(1) {
          font-size: 20px;
          line-height: 24px;
          padding-right: 20px;
          @include user-select;
          @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
            font-size: 18px;
            line-height: 22px;
          }
          @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
            font-size: 17px;
            line-height: 21px;
          }
          @media (max-width: 767px) {
            padding-right: 16px;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Compulsory Badge */
        /* ---------------------------------------------------------------- */
        &:nth-child(2) {
          align-items: center;
          background-color: $productTitle;
          border-radius: 24px;
          color: $productText;
          display: flex;
          font-size: 16px;
          height: 24px;
          line-height: 20px;
          margin-left: auto;
          margin-right: 20px;
          padding: 0 24px;
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            box-sizing: border-box;
            justify-content: center;
            left: 0;
            position: absolute;
            top: 20px;
            width: 100%;
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Chevron Icon */
      /* ---------------------------------------------------------------- */
      svg {
        flex-shrink: 0;
        height: 14px;
        width: 14px;
        &:nth-child(2) {
          margin-left: auto;
        }
        * {
          fill: $productChevron;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  > div {
    padding-bottom: 25px;
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
      padding-bottom: 19px;
    }
    @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
      padding-bottom: 16px;
    }
    > * {
      @include user-select;
      &:first-child {
        margin-top: -4px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $bold;
      font-size: 20px;
      line-height: 24px;
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 19px;
        line-height: 23px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    strong {
      font-family: $bold;
    }
    p,
    li {
      font-family: $regular;
      font-size: 16px;
      line-height: 20px;
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 15px;
        line-height: 19px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    ul,
    ol,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0 0 15px;
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        margin: 0 0 12px;
      }
    }
    ul {
      list-style-type: none;
      > li {
        padding-left: 24px;
        position: relative;
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          padding-left: 20px;
        }
        @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
          padding-left: 16px;
        }
        &::before {
          background-color: $modalListBullet;
          border-radius: 50%;
          content: "";
          display: block;
          height: 8px;
          left: 0;
          position: absolute;
          top: 5px;
          width: 8px;
          @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
            height: 6px;
            width: 6px;
          }
        }
        > ol {
          padding-top: 7px;
          > li {
            margin: 0 0 4px;
          }
        }
      }
    }
    ol {
      counter-reset: li;
      list-style: none;
      > li {
        counter-increment: li;
        padding-left: 24px;
        position: relative;
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          padding-left: 20px;
        }
        &::before {
          color: $modalListBullet;
          content: counter(li) ". ";
          display: inline-block;
          font-family: $bold;
          left: 18px;
          margin-left: -18px;
          position: absolute;
          text-align: right;
          top: 0;
        }
      }
    }
    li {
      margin: 0 0 4px;
      &:last-child {
        margin: 0;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Compulsory */
  /* ---------------------------------------------------------------- */
  &.compulsory {
    > header {
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        padding-top: 40px;
      }
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        padding-top: 44px;
      }
      > div[role="button"],
      > button {
        > svg:nth-child(3) {
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            margin-left: auto;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Open */
  /* ---------------------------------------------------------------- */
  &.open {
    > header {
      > div[role="button"],
      > button {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
