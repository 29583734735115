@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Input */
/* ---------------------------------------------------------------- */
.input {
  position: relative;
  width: 412px;
  @media (max-width: 1024px) {
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Input - Input */
  /* ---------------------------------------------------------------- */
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-color: $inputBg;
    border: 2px solid $inputBorder;
    border-radius: 6px;
    box-sizing: border-box;
    color: $inputText;
    display: inline-block;
    filter: none;
    font-family: $medium;
    font-size: 16px;
    font-weight: normal;
    height: 50px;
    letter-spacing: normal;
    line-height: normal;
    outline: none;
    padding: 0 16px;
    resize: none;
    vertical-align: middle;
    width: 100%;
    @include no-user-select;
    &:invalid {
      box-shadow: none;
    }
    &:-webkit-autofill {
      background: $inputBg;
      -webkit-box-shadow: 0 0 0 1000px $inputBg inset !important;
      transition: background-color 10000s ease-in-out 0s;
    }
    &:-moz-autofill {
      background: $inputBg;
    }
    &:-moz-autofill-preview {
      background: $inputBg;
    }
    &::-ms-clear {
      display: none;
      height: 0;
      width: 0;
    }
    &::-webkit-contacts-auto-fill-button {
      display: none;
      visibility: hidden;
    }
    &::-webkit-credentials-auto-fill-button {
      display: none;
      visibility: hidden;
    }
    &::-webkit-caps-lock-indicator {
      display: none;
      visibility: hidden;
    }
    &::-ms-reveal {
      display: none;
      height: 0;
      width: 0;
    }
    &::-webkit-clear-button {
      display: none;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
    /* ---------------------------------------------------------------- */
    /* Type Number */
    /* ---------------------------------------------------------------- */
    &[type="number"] {
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        display: none;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Type Date, Month */
    /* ---------------------------------------------------------------- */
    &[type="date"],
    &[type="month"] {
      :global(.platform-android) & {
        line-height: 48px;
      }
      :global(.platform-ios) & {
        line-height: 47px;
      }
      &::-webkit-calendar-picker-indicator {
        display: none;
        height: 0;
        margin: 0;
        opacity: 0;
        padding: 0;
        width: 0;
      }
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        display: none;
      }
      &:invalid::-webkit-datetime-edit {
        color: $inputTextPlaceholder;
      }
      &:focus::-webkit-datetime-edit {
        color: darken($inputTextPlaceholder, 15%) !important;
      }
      &::-webkit-date-and-time-value {
        text-align: left;
      }
    }
    &[type="date"] {
      text-transform: uppercase;
    }
    &[type="month"] {
      text-transform: capitalize;
    }
    /* ---------------------------------------------------------------- */
    /* Placeholder */
    /* ---------------------------------------------------------------- */
    &::-webkit-input-placeholder {
      color: $inputTextPlaceholder !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    &:-moz-placeholder {
      color: $inputTextPlaceholder !important;
      opacity: 1 !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    &::-moz-placeholder {
      color: $inputTextPlaceholder !important;
      opacity: 1 !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    &:-ms-input-placeholder {
      color: $inputTextPlaceholder !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    &::placeholder {
      color: $inputTextPlaceholder !important;
      opacity: 1 !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      background-color: $inputBgFocus;
      border-color: $inputBorderFocus;
      @include user-select;
      /* ---------------------------------------------------------------- */
      /* Focus Placeholder */
      /* ---------------------------------------------------------------- */
      &::-webkit-input-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
      }
      &:-moz-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
      }
      &::-moz-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
      }
      &:-ms-input-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
      }
      &::placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        border-color: $inputBorderHover;
        /* ---------------------------------------------------------------- */
        /* Hover Focus */
        /* ---------------------------------------------------------------- */
        &:focus {
          border-color: $inputBorderFocus;
        }
        /* ---------------------------------------------------------------- */
        /* Hover Placeholder */
        /* ---------------------------------------------------------------- */
        &::-webkit-input-placeholder {
          color: darken($inputTextPlaceholder, 15%) !important;
        }
        &:-moz-placeholder {
          color: darken($inputTextPlaceholder, 15%) !important;
        }
        &::-moz-placeholder {
          color: darken($inputTextPlaceholder, 15%) !important;
        }
        &:-ms-input-placeholder {
          color: darken($inputTextPlaceholder, 15%) !important;
        }
        &::placeholder {
          color: darken($inputTextPlaceholder, 15%) !important;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Input - Icon */
  /* ---------------------------------------------------------------- */
  > svg {
    flex-shrink: 0;
    height: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    * {
      fill: $inputIcon;
      @include slow-transition($props: "fill, stroke");
    }
    &:first-child {
      left: 18px;
    }
    &:last-child {
      right: 18px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    input {
      border-color: $inputBorderInvalid;
      color: $inputTextInvalid;
      &::-webkit-input-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:-moz-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &::-moz-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:-ms-input-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &::placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:focus {
        border-color: $inputBorderFocusInvalid;
        color: $inputText;
        &::-webkit-input-placeholder {
          color: darken($inputTextPlaceholderInvalid, 10%) !important;
        }
        &:-moz-placeholder {
          color: darken($inputTextPlaceholderInvalid, 10%) !important;
        }
        &::-moz-placeholder {
          color: darken($inputTextPlaceholderInvalid, 10%) !important;
        }
        &:-ms-input-placeholder {
          color: darken($inputTextPlaceholderInvalid, 10%) !important;
        }
        &::placeholder {
          color: darken($inputTextPlaceholderInvalid, 10%) !important;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: $inputBorderFocusInvalid;
          color: $inputTextInvalid;
          &:focus {
            color: $inputText;
          }
          &::-webkit-input-placeholder {
            color: darken($inputTextPlaceholderInvalid, 10%) !important;
          }
          &:-moz-placeholder {
            color: darken($inputTextPlaceholderInvalid, 10%) !important;
          }
          &::-moz-placeholder {
            color: darken($inputTextPlaceholderInvalid, 10%) !important;
          }
          &:-ms-input-placeholder {
            color: darken($inputTextPlaceholderInvalid, 10%) !important;
          }
          &::placeholder {
            color: darken($inputTextPlaceholderInvalid, 10%) !important;
          }
        }
      }
    }
    > svg * {
      fill: $inputIconInvalid;
    }
    &.input-password {
      > svg:last-child {
        :global(.platform-desktop) & {
          &:hover {
            opacity: 0.7;
            * {
              fill: $inputIconInvalid;
            }
          }
        }
      }
    }
    &.input-date,
    &.input-month {
      input {
        color: $inputTextPlaceholderInvalid;
        :global(.platform-desktop) & {
          &:hover {
            color: darken($inputTextPlaceholderInvalid, 10%);
          }
        }
        &:focus::-webkit-datetime-edit {
          color: darken($inputTextPlaceholderInvalid, 10%) !important;
        }
        &::-webkit-datetime-edit-day-field,
        &::-webkit-datetime-edit-month-field,
        &::-webkit-datetime-edit-year-field {
          &:focus {
            color: $inputTextInvalid;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  > input:read-only {
    cursor: default;
    pointer-events: none;
    &::selection {
      pointer-events: none;
    }
    + svg {
      pointer-events: none;
    }
    &:focus {
      background-color: $inputBg;
      border-color: $inputBorder;
      &::-webkit-input-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &:-moz-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &::-moz-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &:-ms-input-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &::placeholder {
        color: $inputTextPlaceholder !important;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        border-color: $inputBorder;
        &:focus {
          border-color: $inputBorder;
        }
        &::-webkit-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-ms-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::placeholder {
          color: $inputTextPlaceholder !important;
        }
      }
    }
  }
  > input[readonly=""] {
    cursor: default;
    pointer-events: none;
    &::selection {
      pointer-events: none;
    }
    + svg {
      pointer-events: none;
    }
    &:focus {
      background-color: $inputBg;
      border-color: $inputBorder;
      &::-webkit-input-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &:-moz-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &::-moz-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &:-ms-input-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &::placeholder {
        color: $inputTextPlaceholder !important;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        border-color: $inputBorder;
        &:focus {
          border-color: $inputBorder;
        }
        &::-webkit-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-ms-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::placeholder {
          color: $inputTextPlaceholder !important;
        }
      }
    }
  }
  &.invalid > input:read-only {
    &:focus {
      border-color: $inputBorderInvalid;
      color: $inputTextInvalid;
      &::-webkit-input-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:-moz-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &::-moz-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:-ms-input-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &::placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        border-color: $inputBorderInvalid;
        color: $inputTextInvalid;
        &:focus {
          color: $inputTextInvalid;
        }
        &::-webkit-input-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &:-moz-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &::-moz-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &:-ms-input-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &::placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
      }
    }
  }
  &.invalid > input[readonly=""] {
    &:focus {
      border-color: $inputBorderInvalid;
      color: $inputTextInvalid;
      &::-webkit-input-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:-moz-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &::-moz-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:-ms-input-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &::placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        border-color: $inputBorderInvalid;
        color: $inputTextInvalid;
        &:focus {
          color: $inputTextInvalid;
        }
        &::-webkit-input-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &:-moz-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &::-moz-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &:-ms-input-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &::placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
      }
    }
  }
  &.input-password.invalid > input[readonly=""] {
    + svg {
      :global(.platform-desktop) & {
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &.input-password.invalid > input:read-only {
    + svg {
      :global(.platform-desktop) & {
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &.input-date.invalid input[readonly=""],
  &.input-month.invalid input[readonly=""] {
    :global(.platform-desktop) & {
      &:hover {
        color: $inputTextPlaceholderInvalid;
      }
    }
    &:focus::-webkit-datetime-edit {
      color: $inputTextPlaceholderInvalid;
    }
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-year-field {
      &:focus {
        color: $inputTextInvalid;
      }
    }
  }
  &.input-date.invalid input:read-only,
  &.input-month.invalid input:read-only {
    :global(.platform-desktop) & {
      &:hover {
        color: $inputTextPlaceholderInvalid;
      }
    }
    &:focus::-webkit-datetime-edit {
      color: $inputTextPlaceholderInvalid;
    }
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-year-field {
      &:focus {
        color: $inputTextInvalid;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    input {
      background-color: $inputBg;
      border-color: $inputBorderDisabled;
      color: $inputTextDisabled;
      pointer-events: none;
      &:focus {
        border-color: $inputBorderDisabled;
        &::-webkit-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-ms-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::placeholder {
          color: $inputTextPlaceholder !important;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: $inputBorderDisabled;
          &::-webkit-input-placeholder {
            color: $inputTextPlaceholder !important;
          }
          &:-moz-placeholder {
            color: $inputTextPlaceholder !important;
          }
          &::-moz-placeholder {
            color: $inputTextPlaceholder !important;
          }
          &:-ms-input-placeholder {
            color: $inputTextPlaceholder !important;
          }
          &::placeholder {
            color: $inputTextPlaceholder !important;
          }
        }
      }
    }
    > svg {
      pointer-events: none;
      * {
        fill: $inputIconDisabled;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Autocomplete */
  /* ---------------------------------------------------------------- */
  &.input-autocomplete {
    display: block;
    input {
      padding-right: 47px;
    }
    > svg {
      z-index: 1;
      &:first-child {
        width: 18px;
        + input {
          padding-left: 50px;
        }
      }
      &:last-child {
        cursor: pointer;
        opacity: 1;
        width: 15px;
        @include slow-transition($props: "opacity");
        :global(.platform-desktop) & {
          &:hover {
            * {
              fill: $inputIconHover;
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Currency */
  /* ---------------------------------------------------------------- */
  &.input-currency {
    > svg:first-child {
      width: 14px;
      + input {
        padding-left: 42px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Date */
  /* ---------------------------------------------------------------- */
  &.input-date {
    display: block;
    > svg:first-child {
      width: 16px;
      + input {
        padding-left: 49px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Email */
  /* ---------------------------------------------------------------- */
  &.input-email {
    > svg:first-child {
      width: 20px;
      + input {
        padding-left: 52px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Integer */
  /* ---------------------------------------------------------------- */
  &.input-integer {
    display: block;
  }
  /* ---------------------------------------------------------------- */
  /* Month */
  /* ---------------------------------------------------------------- */
  &.input-month {
    display: block;
    > svg:first-child {
      width: 16px;
      + input {
        padding-left: 49px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Password */
  /* ---------------------------------------------------------------- */
  &.input-password {
    input {
      padding-right: 52px;
    }
    > svg:last-child {
      cursor: pointer;
      opacity: 1;
      width: 20px;
      @include slow-transition($props: "opacity");
      :global(.platform-desktop) & {
        &:hover {
          * {
            fill: $inputIconHover;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Postcode */
  /* ---------------------------------------------------------------- */
  &.input-postcode {
    display: block;
  }
  /* ---------------------------------------------------------------- */
  /* Sortcode */
  /* ---------------------------------------------------------------- */
  &.input-sortcode {
    display: block;
  }
  /* ---------------------------------------------------------------- */
  /* Telephone */
  /* ---------------------------------------------------------------- */
  &.input-tel {
    > svg:first-child {
      width: 18px;
      + input {
        padding-left: 50px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  &.input-text {
    display: block;
  }
  /* ---------------------------------------------------------------- */
  /* VRM */
  /* ---------------------------------------------------------------- */
  &.input-vrm {
    display: block;
    input {
      background-color: $inputVrmBg;
      border-color: $inputVrmBorder;
      font-family: $vrm;
      font-size: 32px;
      text-align: center;
      text-transform: uppercase;
      &::-webkit-input-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
        text-transform: uppercase !important;
      }
      &:-moz-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
        text-transform: uppercase !important;
      }
      &::-moz-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
        text-transform: uppercase !important;
      }
      &:-ms-input-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
        text-transform: uppercase !important;
      }
      &::placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
        text-transform: uppercase !important;
      }
      &:focus {
        background-color: rgba($inputVrmBg, 0.85);
        border-color: $inputBorderFocus;
        &::-webkit-input-placeholder {
          color: darken($inputTextPlaceholder, 25%) !important;
        }
        &:-moz-placeholder {
          color: darken($inputTextPlaceholder, 25%) !important;
        }
        &::-moz-placeholder {
          color: darken($inputTextPlaceholder, 25%) !important;
        }
        &:-ms-input-placeholder {
          color: darken($inputTextPlaceholder, 25%) !important;
        }
        &::placeholder {
          color: darken($inputTextPlaceholder, 25%) !important;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          &::-webkit-input-placeholder {
            color: darken($inputTextPlaceholder, 25%) !important;
          }
          &:-moz-placeholder {
            color: darken($inputTextPlaceholder, 25%) !important;
          }
          &::-moz-placeholder {
            color: darken($inputTextPlaceholder, 25%) !important;
          }
          &:-ms-input-placeholder {
            color: darken($inputTextPlaceholder, 25%) !important;
          }
          &::placeholder {
            color: darken($inputTextPlaceholder, 25%) !important;
          }
        }
      }
    }
    &.disabled {
      input {
        background-color: $inputBg;
        border-color: $inputBorderDisabled;
        &:focus {
          border-color: $inputBorderDisabled;
        }
        &::-webkit-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-ms-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::placeholder {
          color: $inputTextPlaceholder !important;
        }
        :global(.platform-desktop) & {
          &:hover {
            &::-webkit-input-placeholder {
              color: $inputTextPlaceholder !important;
            }
            &:-moz-placeholder {
              color: $inputTextPlaceholder !important;
            }
            &::-moz-placeholder {
              color: $inputTextPlaceholder !important;
            }
            &:-ms-input-placeholder {
              color: $inputTextPlaceholder !important;
            }
            &::placeholder {
              color: $inputTextPlaceholder !important;
            }
          }
        }
      }
    }
    &.input-vrm {
      input[readonly=""] {
        &:focus {
          background-color: $inputVrmBg;
          border-color: $inputVrmBorder;
        }
      }
      input:read-only {
        &:focus {
          background-color: $inputVrmBg;
          border-color: $inputVrmBorder;
        }
      }
    }
    &.invalid {
      input[readonly=""] {
        &:focus {
          border-color: $inputBorderInvalid;
        }
      }
      input:read-only {
        &:focus {
          border-color: $inputBorderInvalid;
        }
      }
      input {
        &::-webkit-input-placeholder {
          color: darken($inputTextPlaceholderInvalid, 17.5%) !important;
        }
        &:-moz-placeholder {
          color: darken($inputTextPlaceholderInvalid, 17.5%) !important;
        }
        &::-moz-placeholder {
          color: darken($inputTextPlaceholderInvalid, 17.5%) !important;
        }
        &:-ms-input-placeholder {
          color: darken($inputTextPlaceholderInvalid, 17.5%) !important;
        }
        &::placeholder {
          color: darken($inputTextPlaceholderInvalid, 17.5%) !important;
        }
        &:focus {
          &::-webkit-input-placeholder {
            color: $inputTextInvalid !important;
          }
          &:-moz-placeholder {
            color: $inputTextInvalid !important;
          }
          &::-moz-placeholder {
            color: $inputTextInvalid !important;
          }
          &:-ms-input-placeholder {
            color: $inputTextInvalid !important;
          }
          &::placeholder {
            color: $inputTextInvalid !important;
          }
        }
        :global(.platform-desktop) & {
          &:hover {
            &::-webkit-input-placeholder {
              color: $inputTextInvalid !important;
            }
            &:-moz-placeholder {
              color: $inputTextInvalid !important;
            }
            &::-moz-placeholder {
              color: $inputTextInvalid !important;
            }
            &:-ms-input-placeholder {
              color: $inputTextInvalid !important;
            }
            &::placeholder {
              color: $inputTextInvalid !important;
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Loading */
  /* ---------------------------------------------------------------- */
  &.loading {
    display: block;
    pointer-events: none;
    input {
      padding-right: 52px;
    }
    > svg:last-child {
      height: 20px;
      width: 20px;
    }
  }
}
