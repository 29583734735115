@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Review */
/* ---------------------------------------------------------------- */
.policy-review {
  background-color: $productBg;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  margin: 0 0 24px;
  @media (max-width: 1240px) {
    margin: 0 0 16px;
  }
  @media (max-width: 1024px) {
    margin: 0 0 12px;
  }
  @media (max-width: 767px) {
    border-radius: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > header {
    margin: 0 0 16px;
    padding: 24px 20px 0;
    @media (max-width: 1240px) {
      margin: 0 0 6px;
    }
    @media (max-width: 767px) {
      margin: 0 0 10px;
      padding: 24px 16px 0;
    }
    @media (max-width: 670px) {
      margin: 0 0 6px;
    }
    > h2 {
      color: $title;
      font-family: $bold;
      font-size: 28px;
      line-height: 32px;
      position: relative;
      text-transform: capitalize;
      @include no-user-select;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        font-size: 24px;
        line-height: 28px;
      }
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 23px;
        line-height: 27px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 22px;
        line-height: 26px;
      }
      &:first-child {
        margin-top: -5px;
      }
      &::after {
        background-color: $titleBorder;
        content: "";
        display: block;
        height: 2px;
        margin: 11px 0 0;
        position: relative;
        width: 100%;
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          margin: 6px 0 0;
        }
      }
    }
  }
  > section {
    /* ---------------------------------------------------------------- */
    /* Question */
    /* ---------------------------------------------------------------- */
    > div {
      border-left-width: 0;
      border-radius: 0;
      border-right-width: 0;
      padding-left: 20px;
      padding-right: 20px;
      @media (max-width: 1024px) {
        left: 0;
        padding: 12px 20px;
        width: 100%;
      }
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        margin-bottom: 4px;
      }
      @media (max-width: 767px) {
        padding-left: 16px;
        padding-right: 16px;
      }
      &:last-child[class] {
        margin-bottom: 12px;
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          margin-bottom: 4px;
        }
        @media (max-width: 767px) {
          margin-bottom: 4px;
        }
      }
      > div {
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          position: static;
        }
        /* ---------------------------------------------------------------- */
        /* Question - Col 1 - Label */
        /* ---------------------------------------------------------------- */
        &:nth-child(1) {
          flex-basis: 255px;
          max-width: 255px;
          @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
            flex-basis: 200px;
            max-width: 200px;
          }
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            flex-basis: 100%;
            margin-top: -4px;
            max-width: 100%;
            text-align: left;
          }
          @media (max-width: 767px) {
            align-items: flex-start;
          }
          > label {
            padding: 0 20px 0 0;
            @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
              padding: 0 34px 0 0;
            }
            @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
              font-size: 15px;
              line-height: 19px;
            }
            @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
        /* ---------------------------------------------------------------- */
        /* Question - Col 2 - Input & Help */
        /* ---------------------------------------------------------------- */
        &:nth-child(2) {
          flex-basis: calc(100% - (255px + 48px));
          max-width: calc(100% - (255px + 48px));
          @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
            flex-basis: calc(100% - (200px + 44px));
            max-width: calc(100% - (200px + 44px));
          }
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            flex-basis: 100%;
            margin: 11px 0 0;
            max-width: 100%;
          }
          @media (max-width: 767px) and (min-width: 671px) {
            margin: 0;
          }
          > div {
            /* ---------------------------------------------------------------- */
            /* Input / Select */
            /* ---------------------------------------------------------------- */
            &:nth-child(1) {
              width: 100%;
              @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
                position: static;
              }
              > div {
                width: 100%;
              }
              /* ---------------------------------------------------------------- */
              /* Help Tooltip */
              /* ---------------------------------------------------------------- */
              > svg[class] {
                @media (min-width: 1325px) {
                  right: -48px;
                }
                @media (max-width: 1324px) and (min-width: 768px) {
                  right: -44px;
                }
                @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
                  height: 26px;
                  width: 26px;
                }
                @media (max-width: 1240px) and (min-width: 768px) {
                  right: 20px;
                  top: 5px;
                }
                @media (max-width: 767px) and (min-width: 671px) {
                  top: 12px;
                }
                @media (max-width: 670px) {
                  right: 16px;
                  top: 5px;
                }
              }
            }
            /* ---------------------------------------------------------------- */
            /* Help Text */
            /* ---------------------------------------------------------------- */
            &:nth-child(2) {
              > div {
                @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
                  font-size: 15px;
                  line-height: 19px;
                }
                @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
                  font-size: 14px;
                  line-height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Footer */
  /* ---------------------------------------------------------------- */
  > footer {
    align-items: center;
    display: block;
    padding: 0 0 15px;
    position: relative;
    @media (max-width: 767px) {
      padding: 0 0 10px;
    }
  }
}
