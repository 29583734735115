@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Form */
/* ---------------------------------------------------------------- */
.form {
  display: block;
  /* ---------------------------------------------------------------- */
  /* Submitting */
  /* ---------------------------------------------------------------- */
  &.submitting {
    cursor: default;
    pointer-events: none;
  }
}
