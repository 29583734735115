@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Fieldset */
/* ---------------------------------------------------------------- */
.fieldset {
  background-color: $fieldsetBg;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  box-sizing: border-box;
  outline: none;
  padding: 32px;
  @media (max-width: 1071px) {
    padding: 32px 24px;
  }
  @media (max-width: 767px) {
    border-radius: 0;
    padding: 20px 16px;
  }
  + fieldset {
    margin-top: 20px;
    @media (max-width: 767px) {
      margin-top: 16px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Fieldset - Legend */
  /* ---------------------------------------------------------------- */
  > legend {
    float: left;
    margin: 0 0 31px;
    width: 100%;
    &:first-child {
      margin-top: -4px;
    }
    @media (max-width: 1024px) {
      margin: 0 0 28px;
    }
    @media (max-width: 767px) {
      margin: 0 0 21px;
    }
    + * {
      clear: both;
    }
    /* ---------------------------------------------------------------- */
    /* Fieldset - Legend - Title */
    /* ---------------------------------------------------------------- */
    > h2 {
      color: $title;
      font-family: $bold;
      font-size: 28px;
      line-height: 30px;
      position: relative;
      text-transform: capitalize;
      @include no-user-select;
      @media (max-width: 1024px) {
        font-size: 26px;
        line-height: 28px;
      }
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
      }
      &::after {
        background-color: $titleBorder;
        content: "";
        display: block;
        height: 2px;
        margin: 11px 0 0;
        position: absolute;
        width: 100%;
        @media (max-width: 1024px) {
          margin: 7px 0 0;
        }
        @media (max-width: 767px) {
          margin: 6px 0 0;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Fieldset - Button (Debug) */
  /* ---------------------------------------------------------------- */
  > button,
  > div[role="button"],
  > a {
    margin: 0 12px 16px 0;
  }
  > div {
    > button,
    > div[role="button"],
    > a {
      margin: 0 12px 16px 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
