@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Confirmation - Pages */
/* ---------------------------------------------------------------- */
.confirmation-pages {
  margin: 20px 0 0;
  @media (max-width: 575px) {
    margin: 16px 0 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > h3 {
    color: $confirmationPagesTitle;
    display: flex;
    font-family: $bold;
    font-size: 20px;
    line-height: 22px;
    margin: -3px 0 16px;
    position: relative;
    text-align: center;
    @media (max-width: 767px) {
      margin: -3px 16px 16px;
    }
    @media (max-width: 480px) {
      font-size: 18px;
      line-height: 20px;
    }
    > span {
      background-color: $pageBg;
      display: inline-block;
      padding: 0 16px;
      z-index: 1;
      @media (max-width: 480px) {
        max-width: 172px;
      }
    }
    &::before,
    &::after {
      background-color: $confirmationPagesTitle;
      border-radius: 2px;
      content: "";
      display: block;
      flex-grow: 1;
      height: 2px;
      position: relative;
      top: 11px;
      z-index: -1;
      @media (max-width: 480px) {
        top: 20px;
      }
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Page Links - Row */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    @media (max-width: 575px) {
      flex-direction: column;
    }
    /* ---------------------------------------------------------------- */
    /* Page Links */
    /* ---------------------------------------------------------------- */
    > a {
      background-color: $confirmationMessageBg;
      border-radius: 12px;
      box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
      overflow: hidden;
      width: 50%;
      will-change: transform;
      @media (max-width: 575px) {
        border-radius: 0;
        box-shadow: none;
        width: 100%;
      }
      &:first-child {
        margin-right: 16px;
        @media (max-width: 1024px) {
          margin-right: 12px;
        }
        @media (max-width: 767px) {
          margin-left: 16px;
          margin-right: 8px;
        }
        @media (max-width: 575px) {
          margin: 0;
        }
        > div:nth-child(1) > div {
          background-image: url("/images/faqs.jpg");
          background-size: cover;
          @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
            only screen and (min--moz-device-pixel-ratio: 1.25),
            only screen and (-o-min-device-pixel-ratio: 1.25 / 1),
            only screen and (min-device-pixel-ratio: 1.25),
            only screen and (min-resolution: 200dpi),
            only screen and (min-resolution: 1.25dppx) {
            background-image: url("/images/faqs@2x.jpg");
          }
        }
      }
      &:last-child {
        margin-left: 16px;
        @media (max-width: 1024px) {
          margin-left: 12px;
        }
        @media (max-width: 767px) {
          margin-left: 8px;
          margin-right: 16px;
        }
        @media (max-width: 575px) {
          margin: 0;
        }
        > div:nth-child(1) > div {
          background-image: url("/images/existing-customers.jpg");
          background-size: cover;
          @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
            only screen and (min--moz-device-pixel-ratio: 1.25),
            only screen and (-o-min-device-pixel-ratio: 1.25 / 1),
            only screen and (min-device-pixel-ratio: 1.25),
            only screen and (min-resolution: 200dpi),
            only screen and (min-resolution: 1.25dppx) {
            background-image: url("/images/existing-customers@2x.jpg");
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Focus */
      /* ---------------------------------------------------------------- */
      &:focus {
        > div {
          &:nth-child(1) > div {
            filter: sepia(100%);
            transform: scale(1.02);
          }
          &:nth-child(2) {
            h4,
            p {
              text-decoration: underline;
              text-decoration-color: $text;
            }
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Hover */
      /* ---------------------------------------------------------------- */
      :global(.platform-desktop) & {
        &:hover > div:nth-child(1) > div {
          filter: sepia(100%);
          transform: scale(1.02);
        }
      }
      /* ---------------------------------------------------------------- */
      /* Active */
      /* ---------------------------------------------------------------- */
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active > div:nth-child(1) > div {
          filter: sepia(50%);
          transform: scale(1);
        }
      }
      > div {
        /* ---------------------------------------------------------------- */
        /* Page Links - Image */
        /* ---------------------------------------------------------------- */
        &:nth-child(1) {
          background-color: $confirmationPagesImageBg;
          height: 155px;
          overflow: hidden;
          pointer-events: none;
          @media (max-width: 575px) {
            height: 117px;
          }
          > div {
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            filter: sepia(0%);
            height: 100%;
            pointer-events: none;
            transform: scale(1);
            @include slow-transition($mob: false, $props: "filter, transform");
          }
        }
        /* ---------------------------------------------------------------- */
        /* Page Links - Text Content */
        /* ---------------------------------------------------------------- */
        &:nth-child(2) {
          padding: 24px 40px 40px;
          pointer-events: none;
          @media (max-width: 1024px) {
            padding: 24px 24px 32px;
          }
          @media (max-width: 767px) {
            padding: 16px 16px 24px;
          }
          > h4 {
            color: $text;
            font-family: $bold;
            font-size: 22px;
            line-height: 26px;
            margin: -5px 0 9px;
            @media (max-width: 1024px) {
              font-size: 21px;
              line-height: 25px;
            }
            @media (max-width: 767px) {
              font-size: 20px;
              line-height: 24px;
            }
          }
          > p {
            color: $text;
            font-family: $regular;
            line-height: 20px;
            margin: 0 0 16px;
            width: 100%;
            word-break: break-word;
            word-wrap: break-word;
            &:last-child {
              margin: 0 0 -5px;
            }
          }
        }
      }
    }
  }
}
