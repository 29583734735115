@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Defaqto */
/* ---------------------------------------------------------------- */
.defaqto {
  background-image: url("/images/defaqto.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  display: block;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  right: 20px;
  top: 10px;
  width: 85px;
  &::before {
    content: "";
    display: block;
    padding-top: calc(calc(380px / 600px) * 100%);
  }
  @media (max-width: 1240px) {
    width: 71px;
  }
  @media (max-width: 884px) {
    width: 69px;
  }
  @media (max-width: 836px) {
    width: 68px;
  }
  @media (max-width: 767px) {
    right: 16px;
    width: 85px;
  }
  @media (max-width: 670px) {
    width: 70px;
  }
}
