@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Message Wrapper */
/* ---------------------------------------------------------------- */
.message-wrapper {
  background-color: $errorBg;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  box-sizing: border-box;
  padding: 32px;
  text-align: center;
  @media (max-width: 1024px) {
    padding: 32px 24px;
  }
  @media (max-width: 767px) {
    border-radius: 0;
    padding: 20px 16px;
  }
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  > header {
    position: relative;
    &::after {
      background-color: $errorSubtitleBorder;
      content: "";
      display: block;
      height: 2px;
      margin: 19px 0 20px;
      position: relative;
      width: 100%;
    }
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    > h2 {
      color: $errorTitle;
      font-family: $bold;
      font-size: 40px;
      line-height: 42px;
      margin: -8px 0 9px;
      &:last-child {
        margin-bottom: -2px;
        @media (max-width: 767px) {
          margin-bottom: -1px;
        }
      }
      @media (max-width: 1024px) {
        font-size: 36px;
        line-height: 38px;
      }
      @media (max-width: 767px) {
        font-size: 32px;
        line-height: 34px;
        margin: -5px 0 13px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Subtitle */
    /* ---------------------------------------------------------------- */
    > h3 {
      color: $errorSubtitle;
      font-size: 20px;
      line-height: 24px;
      @media (max-width: 767px) {
        font-size: 19px;
        line-height: 23px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Rich Text */
  /* ---------------------------------------------------------------- */
  p {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 15px;
    > strong {
      font-family: $bold;
      > span {
        color: $secondaryXXDark;
        display: inline-block;
        font-family: $bold;
        @include user-select;
      }
    }
    > i {
      font-family: $bold;
      font-style: normal;
      white-space: nowrap;
      @include user-select;
    }
  }
  ul,
  ol {
    margin: 0 0 15px;
    li {
      font-size: 18px;
      line-height: 22px;
    }
  }
  a {
    color: $errorHighlight;
    font-family: $bold;
    @include fast-transition($mob: false, $props: color);
    &:focus {
      color: darken($errorHighlight, 10%);
      text-decoration-color: darken($errorHighlight, 10%);
    }
    :global(.platform-desktop) & {
      &:hover {
        color: darken($errorHighlight, 10%);
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        color: darken($errorHighlight, 20%);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Times */
  /* ---------------------------------------------------------------- */
  h4 {
    font-family: $bold;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 15px;
  }
  > :global(.times) {
    display: block;
    margin: 0 auto 21px;
    max-width: 270px;
    > li {
      display: flex;
      margin: 0 0 11px;
      &:last-child {
        margin: 0;
      }
      > span {
        color: $secondaryXXDark;
        font-family: $bold;
        @include user-select;
        &:first-child {
          color: $text;
          margin-right: auto;
        }
        &:nth-child(3) {
          margin: 0 4px;
        }
      }
    }
  }
  small {
    display: block;
    margin: 0 0 27px;
  }
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  > div[role="button"],
  > button,
  > a {
    margin: 0 8px;
    @media (max-width: 767px) {
      margin: 0 6px;
    }
    @media (max-width: 450px) {
      margin: 0 6px 12px;
    }
    &:last-child {
      @media (max-width: 450px) {
        margin: 0 6px;
      }
    }
  }
  > div[role="button"],
  > button {
    min-width: 192px;
    @media (max-width: 450px) {
      width: 100%;
    }
  }
}
