@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Question Claims */
/* ---------------------------------------------------------------- */
.question-claims {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin: 0 0 40px;
  &:last-child {
    margin: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Empty State */
  /* ---------------------------------------------------------------- */
  > div:not([role="button"]) {
    align-items: center;
    background-color: $claimsConvictionsBg;
    border: 2px solid $claimsConvictionsBorder;
    border-radius: 6px;
    display: flex;
    padding: 20px;
    @media (max-width: 767px) {
      border-radius: 0;
      border-width: 2px 0;
      padding: 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    > svg {
      flex-shrink: 0;
      height: 40px;
      margin-right: 12px;
      width: 50px;
      /* ---------------------------------------------------------------- */
      /* Claim - Icon - Text */
      /* ---------------------------------------------------------------- */
      * {
        fill: $iconClaimText;
      }
      /* ---------------------------------------------------------------- */
      /* Claim - Icon - Highlighted */
      /* ---------------------------------------------------------------- */
      *:nth-child(3),
      *:nth-child(7),
      *:nth-child(22) {
        fill: $iconClaimHighlighted;
      }
      /* ---------------------------------------------------------------- */
      /* Claim - Icon - Page */
      /* ---------------------------------------------------------------- */
      *:nth-child(1),
      *:nth-child(5),
      *:nth-child(15) {
        fill: $iconClaimPage;
      }
      /* ---------------------------------------------------------------- */
      /* Claim - Icon - Stroke */
      /* ---------------------------------------------------------------- */
      *:nth-child(2),
      *:nth-child(6),
      *:nth-child(16) {
        fill: $iconClaimStroke;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    > h3 {
      color: $title;
      font-family: $semibold;
      font-size: 20px;
      line-height: 22px;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Empty - Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid > div:not([role="button"]) {
    background-color: $questionInvalidBg;
    border-color: $questionInvalidBorder;
    > h3 {
      color: $questionInvalidText;
    }
  }
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  > ul:not(div),
  > div:not([role="button"]) {
    align-self: stretch;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      margin: 0 -16px 20px;
    }
    /* ---------------------------------------------------------------- */
    /* Add Button */
    /* ---------------------------------------------------------------- */
    + div[role="button"],
    + button {
      margin: 0 0 0 auto;
    }
  }
}
