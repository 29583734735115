@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Product Breakdown Option */
/* ---------------------------------------------------------------- */
.product-breakdown-option {
  border-color: $productTableBorder;
  border-style: solid;
  border-width: 2px 2px 0;
  display: grid;
  grid-gap: 16px;
  grid-template:
    "title button"
    "desc button"
    "price button";
  padding: 20px;
  @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
    grid-template:
      "title title"
      "desc desc"
      "price button" auto / 1fr;
    padding: 16px 20px;
  }
  &:first-child {
    border-radius: 8px 8px 0 0;
  }
  &:last-child {
    border-bottom-width: 2px;
    border-radius: 0 0 8px 8px;
  }
  &:nth-child(even) {
    background-color: $productPrimaryBg;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h3 {
    font-family: $bold;
    font-size: 16px;
    grid-area: title;
    line-height: 20px;
    margin-block: -2px -5px;
    @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
      font-size: 15px;
      line-height: 19px;
      margin-block: -2px -4px;
    }
    @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Description */
  /* ---------------------------------------------------------------- */
  p {
    font-size: 14px;
    grid-area: desc;
    line-height: 17px;
    margin-block: -2px -4px;
    @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
      font-size: 13px;
      line-height: 16px;
      margin-block: -2px -3px;
    }
    @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
      font-size: 12px;
      line-height: 15px;
      margin-block: -1px -4px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Price */
  /* ---------------------------------------------------------------- */
  > span {
    align-items: flex-end;
    color: $primary;
    display: grid;
    grid-area: price;
    grid-auto-flow: column;
    grid-gap: 12px;
    justify-content: flex-start;
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
      align-items: center;
    }
    @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
      grid-auto-flow: row;
      grid-gap: 0;
    }
    > span {
      font-family: $bold;
      font-size: 16px;
      line-height: 20px;
      margin-block: -3px -4px;
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 15px;
        line-height: 19px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 14px;
        line-height: 18px;
        margin-block: -2px -4px;
      }
    }
    > small {
      font-family: $semibold;
      font-size: 13px;
      line-height: 15px;
      margin-block: -2px -3px;
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 12px;
        line-height: 14px;
        margin-block: -1px -3px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 11px;
        line-height: 13px;
        margin-block: 0 -3px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  button,
  div[role="button"] {
    grid-area: button;
    height: 50px;
    width: 64px;
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
      grid-column: 50px;
      margin-left: auto;
    }
    @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
      height: 40px;
    }
    svg {
      height: 20px;
      width: 20px;
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        height: 16px;
        width: 16px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Selected */
  /* ---------------------------------------------------------------- */
  &.selected {
    background-color: $productTableSelectedPrimaryBg;
    border-color: $productTableSelectedBorder;
    + article {
      border-top-color: $productTableSelectedBorder;
    }
  }
}
