@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Buttons */
/* ---------------------------------------------------------------- */
.buttons {
  display: flex;
  list-style: none;
  margin: 0 0 -16px;
  min-height: 66px;
  width: 412px;
  @media (max-width: 1024px) {
    width: calc(100% + 12px);
  }
  /* ---------------------------------------------------------------- */
  /* Buttons - Button Wrapper */
  /* ---------------------------------------------------------------- */
  > li {
    margin: 0 0 16px;
  }
  /* ---------------------------------------------------------------- */
  /* Horizontal */
  /* ---------------------------------------------------------------- */
  &.horizontal {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -6px;
    margin-right: -6px;
    width: 424px;
    @media (max-width: 1024px) {
      width: calc(100% + 12px);
    }
    > li {
      flex-basis: calc(33.33% - 12px);
      margin-left: 6px;
      margin-right: 6px;
      max-width: calc(33.33% - 12px);
      @media (max-width: 1024px) {
        flex-basis: calc(25% - 12px);
        max-width: calc(25% - 12px);
      }
      @media (max-width: 640px) {
        flex-basis: calc(33.33% - 12px);
        max-width: calc(33.33% - 12px);
      }
      @media (max-width: 450px) {
        flex-basis: calc(50% - 12px);
        max-width: calc(50% - 12px);
      }
      > button,
      div[role="button"] {
        width: 100%;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Vertical */
  /* ---------------------------------------------------------------- */
  &.vertical {
    flex-direction: column;
    > li {
      position: relative;
      width: 100%;
      > button,
      div[role="button"] {
        flex-direction: row;
        height: auto;
        justify-content: flex-start;
        padding: 13px 20px;
        width: 100%;
        > span {
          line-height: 20px;
          text-align: left;
          white-space: normal;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Checkboxes */
    /* ---------------------------------------------------------------- */
    &.checkbox {
      > li {
        > button,
        div[role="button"] {
          &::before,
          &::after {
            border-radius: 50%;
            content: "";
            display: block;
            flex-shrink: 0;
          }
          &::before {
            align-self: flex-start;
            background-color: $formButtonTextSelected;
            border-style: solid;
            border-width: 2px;
            box-sizing: border-box;
            height: 20px;
            margin-right: 20px;
            position: relative;
            width: 20px;
          }
          &::after {
            height: 10px;
            left: 27px;
            position: absolute;
            top: 20px;
            width: 10px;
          }
          > span {
            line-height: 20px;
            text-align: left;
            white-space: normal;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Columns */
    /* ---------------------------------------------------------------- */
    &.columns {
      flex-direction: row;
      flex-wrap: wrap;
      > li {
        width: calc(50% - 6px);
        @media (max-width: 640px) {
          width: 100%;
        }
        > div[role="button"],
        > button {
          justify-content: center;
          @media (max-width: 640px) {
            justify-content: flex-start;
          }
        }
        &:nth-child(even) {
          margin-left: 12px;
          @media (max-width: 640px) {
            margin-left: 0;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Icons */
  /* ---------------------------------------------------------------- */
  &.icons {
    > li {
      /* ---------------------------------------------------------------- */
      /* Icon */
      /* ---------------------------------------------------------------- */
      > div[role="button"],
      > button {
        flex-direction: column;
        height: 100%;
        padding: 10px 8px 9px;
        span {
          align-items: center;
          display: flex;
          font-size: 13px;
          height: 100%;
          justify-content: center;
          text-align: center;
          white-space: normal;
          width: 100%;
        }
        /* ---------------------------------------------------------------- */
        /* SVGs - Tagging Device */
        /* ---------------------------------------------------------------- */
        svg {
          /* ---------------------------------------------------------------- */
          /* Alphadot */
          /* ---------------------------------------------------------------- */
          &[data-svg="alphadot"] {
            height: 30px;
            width: 104px;
            + span {
              display: none;
            }
          }
          /* ---------------------------------------------------------------- */
          /* Datatag */
          /* ---------------------------------------------------------------- */
          &[data-svg="datatag"] {
            height: 42px;
            width: 104px;
            + span {
              display: none;
            }
          }
          /* ---------------------------------------------------------------- */
          /* Smartwater */
          /* ---------------------------------------------------------------- */
          &[data-svg="smartwater"] {
            height: 69px;
            width: 104px;
            + span {
              display: none;
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Icons - Alarm */
  /* ---------------------------------------------------------------- */
  &.icons-alarm {
    @media (max-width: 450px) {
      display: grid;
      display: -ms-grid;
      grid-auto-rows: 1fr;
      -ms-grid-columns: 1fr 1fr;
      -ms-grid-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }
    > li {
      order: 1;
      @media (max-width: 640px) and (min-width: 451px) {
        flex-basis: calc(33.33% - 12px);
        max-width: calc(33.33% - 12px);
      }
      @media (max-width: 450px) {
        flex-basis: 100%;
        max-width: 100%;
      }
      /* ---------------------------------------------------------------- */
      /* No Device Button */
      /* ---------------------------------------------------------------- */
      &:nth-child(1) {
        order: 2;
        @media (min-width: 1025px), (max-width: 640px) and (min-width: 451px) {
          flex-basis: calc(100% - 12px);
          max-width: calc(100% - 12px);
        }
        @media (max-width: 450px) {
          flex-basis: 100%;
          -ms-grid-column: 2;
          -ms-grid-row: 2;
          max-width: 100%;
        }
        > div[role="button"],
        > button {
          @media (min-width: 1025px), (max-width: 640px) and (min-width: 451px) {
            height: 50px;
            > span {
              font-size: 16px;
            }
          }
          svg {
            @media (min-width: 1025px), (max-width: 640px) and (min-width: 451px) {
              display: none;
            }
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Alphadot */
      /* ---------------------------------------------------------------- */
      &:nth-child(2) {
        -ms-grid-column: 2;
        -ms-grid-row: 1;
      }
      /* ---------------------------------------------------------------- */
      /* Datatag */
      /* ---------------------------------------------------------------- */
      &:nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      /* ---------------------------------------------------------------- */
      /* Smartwater */
      /* ---------------------------------------------------------------- */
      &:nth-child(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
    }
  }
}
