@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modal Loading Requote */
/* ---------------------------------------------------------------- */
.modalLoadingRequote {
  background-color: $modalBg;
  border-radius: 12px;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  justify-content: center;
  max-height: calc(100% - 64px);
  max-width: calc(720px - 64px);
  overflow: hidden;
  position: relative;
  z-index: 1;
  @media (max-width: 1024px) {
    max-height: calc(100% - 48px);
    max-width: calc(720px - 48px);
  }
  @media (max-width: 767px) {
    max-height: calc(100% - 32px);
    max-width: calc(100% - 32px);
  }
  :global(.MuiDialog-container) & {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
  /* ---------------------------------------------------------------- */
  /* Scroll Container */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 32px;
    position: relative;
    scrollbar-color: $modalScrollBarThumb $modalScrollBar;
    scrollbar-width: thin;
    @media (max-width: 1024px) {
      padding: 28px;
    }
    @media (max-width: 767px) {
      padding: 24px;
    }
    @media (max-width: 584px) {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    /* ---------------------------------------------------------------- */
    /* Scroll Bar - IE */
    /* ---------------------------------------------------------------- */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      scrollbar-3dlight-color: $modalScrollBarThumb;
      scrollbar-arrow-color: $modalScrollBarThumb;
      scrollbar-base-color: $modalScrollBar;
      scrollbar-face-color: $modalScrollBarThumb;
      scrollbar-highlight-color: $modalScrollBarThumb;
      scrollbar-shadow-color: $modalScrollBarThumb;
      scrollbar-track-color: $modalScrollBar;
    }
    /* ---------------------------------------------------------------- */
    /* Scroll Bar - Webkit / Android / Samsung */
    /* ---------------------------------------------------------------- */
    :global(.platform-windows) &,
    :global(.platform-mobile) & {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: $modalScrollBar;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $modalScrollBarThumb;
        border-radius: 4px;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Loading Dots */
  /* ---------------------------------------------------------------- */
  .dots {
    margin-bottom: 16px;
    @media (max-width: 1024px) {
      margin-bottom: 14px;
    }
    @media (max-width: 767px) {
      margin-bottom: 12px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  p {
    margin: -4px 0 -5px;
    max-width: 470px;
    text-align: center;
    @include user-select;
    @media (max-width: 584px) {
      max-width: 330px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Trustpilot */
  /* ---------------------------------------------------------------- */
  a {
    color: $text;
    display: inline-flex;
    margin-top: 32px;
    @include fast-transition($props: "color");
    @media (max-width: 1024px) {
      margin-top: 28px;
    }
    @media (max-width: 767px) {
      margin-top: 24px;
    }
    @media (max-width: 584px) {
      align-items: center;
      flex-direction: column;
      margin-bottom: -3px;
    }
    &:focus {
      color: $hyperlink;
      text-decoration: underline;
      text-decoration-color: $hyperlink;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $hyperlink;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        color: $hyperlinkHover;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Header Trustpilot - Loading */
    /* ---------------------------------------------------------------- */
    &.loading {
      visibility: hidden;
    }
    /* ---------------------------------------------------------------- */
    /* Header Trustpilot - Columns */
    /* ---------------------------------------------------------------- */
    > div {
      display: flex;
      flex-shrink: 0;
      height: 22px;
      @media (max-width: 584px) {
        height: auto;
      }
      &:nth-child(1) {
        > div {
          /* ---------------------------------------------------------------- */
          /* Header Trustpilot - Trust Score */
          /* ---------------------------------------------------------------- */
          &:nth-child(1) {
            font-family: $semibold;
            font-size: 18px;
            line-height: 22px;
            margin: 2px 9px 0 0;
          }
          /* ---------------------------------------------------------------- */
          /* Header Trustpilot - Stars Container */
          /* ---------------------------------------------------------------- */
          &:nth-child(2) {
            /* ---------------------------------------------------------------- */
            /* Header Trustpilot - Stars */
            /* ---------------------------------------------------------------- */
            > svg {
              height: 22px;
              width: 120px;
              :global {
                .tp-star {
                  .tp-star__canvas {
                    fill: $headerEmptyStar;
                  }
                  .tp-star__canvas--half {
                    fill: $headerEmptyStar;
                    visibility: hidden;
                  }
                }
              }
            }
            > svg.header-trustpilot-stars-1 {
              :global .tp-star:nth-child(1) .tp-star__canvas {
                fill: $headerFullStar;
              }
            }
            > svg.header-trustpilot-stars-1-5 {
              :global .tp-star:nth-child(-n + 1) .tp-star__canvas {
                fill: $headerFullStar;
              }
              :global .tp-star:nth-child(2) .tp-star__canvas--half {
                fill: $headerFullStar;
                visibility: visible;
              }
            }
            > svg.header-trustpilot-stars-2 {
              :global .tp-star:nth-child(-n + 2) .tp-star__canvas {
                fill: $headerFullStar;
              }
            }
            > svg.header-trustpilot-stars-2-5 {
              :global .tp-star:nth-child(-n + 2) .tp-star__canvas {
                fill: $headerFullStar;
              }
              :global .tp-star:nth-child(3) .tp-star__canvas--half {
                fill: $headerFullStar;
                visibility: visible;
              }
            }
            > svg.header-trustpilot-stars-3 {
              :global .tp-star:nth-child(-n + 3) .tp-star__canvas {
                fill: $headerFullStar;
              }
            }
            > svg.header-trustpilot-stars-3-5 {
              :global .tp-star:nth-child(-n + 3) .tp-star__canvas {
                fill: $headerFullStar;
              }
              :global .tp-star:nth-child(4) .tp-star__canvas--half {
                fill: $headerFullStar;
                visibility: visible;
              }
            }
            > svg.header-trustpilot-stars-4 {
              :global .tp-star:nth-child(-n + 4) .tp-star__canvas {
                fill: $headerFullStar;
              }
            }
            > svg.header-trustpilot-stars-4-5 {
              :global .tp-star:nth-child(-n + 4) .tp-star__canvas {
                fill: $headerFullStar;
              }
              :global .tp-star:nth-child(5) .tp-star__canvas--half {
                fill: $headerFullStar;
                visibility: visible;
              }
            }
            > svg.header-trustpilot-stars-5 {
              :global .tp-star:nth-child(-n + 5) .tp-star__canvas {
                fill: $headerFullStar;
              }
            }
          }
        }
      }
      &:nth-child(2) {
        /* ---------------------------------------------------------------- */
        /* Header Trustpilot - Number Of Reviews */
        /* ---------------------------------------------------------------- */
        > div {
          font-size: 16px;
          line-height: 20px;
          margin: 4px 9px 0 10px;
          white-space: nowrap;
          @media (max-width: 584px) {
            margin-left: 0;
            margin-right: 7px;
          }
          > span {
            font-family: $semibold;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Header Trustpilot - Trustpilot Logo */
        /* ---------------------------------------------------------------- */
        > svg {
          height: 22px;
          margin: 1px 0 0;
          width: 89px;
          /* ---------------------------------------------------------------- */
          /* Text Path */
          /* ---------------------------------------------------------------- */
          path:nth-of-type(1) {
            fill: $text;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Defaqto */
  /* ---------------------------------------------------------------- */
  > div > svg {
    height: 36px;
    margin-top: 32px;
    width: 240px;
    @media (max-width: 1024px) {
      margin-top: 28px;
    }
    @media (max-width: 767px) {
      margin-top: 24px;
    }
  }
}
