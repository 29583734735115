@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Confirmation - Social */
/* ---------------------------------------------------------------- */
.confirmation-social {
  align-self: center;
  background-color: $confirmationMessageBg;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  margin: 20px auto 0;
  max-width: 400px;
  padding: 20px;
  @media (max-width: 767px) {
    align-self: stretch;
    background-color: $footerBg;
    border-radius: 0;
    box-shadow: none;
    margin: 20px 0 0;
    max-width: 100%;
    padding: 20px 16px 5px;
    position: relative;
  }
  @media (max-width: 640px) {
    padding-bottom: 1px;
  }
  @media (max-width: 575px) {
    margin: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > h5 {
    color: $confirmationSocialTitle;
    font-family: $bold;
    font-size: 18px;
    line-height: 22px;
    margin: -4px 0 11px;
    text-align: center;
    @media (max-width: 767px) {
      color: $footerLink;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Icon List */
  /* ---------------------------------------------------------------- */
  > ul {
    display: flex;
    justify-content: center;
    /* ---------------------------------------------------------------- */
    /* Item */
    /* ---------------------------------------------------------------- */
    li {
      margin: 0 8px;
      /* ---------------------------------------------------------------- */
      /* Link */
      /* ---------------------------------------------------------------- */
      > a {
        display: block;
        height: 30px;
        position: relative;
        /* ---------------------------------------------------------------- */
        /* Focus */
        /* ---------------------------------------------------------------- */
        &::after {
          border: 1px solid $confirmationSocailIcon;
          border-radius: 50%;
          content: "";
          height: 34px;
          left: 50%;
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 34px;
          @include fast-transition($props: "opacity, border-color");
        }
        &:focus {
          svg * {
            fill: $confirmationSocailIconHover;
            @media (max-width: 767px) {
              fill: $confirmationSocailIcon;
            }
          }
          &::after {
            border-color: $confirmationSocailIconHover;
            opacity: 1;
            @media (max-width: 767px) {
              border-color: $confirmationSocailIcon;
            }
          }
        }
        /* ---------------------------------------------------------------- */
        /* Hover */
        /* ---------------------------------------------------------------- */
        :global(.platform-desktop) & {
          &:hover {
            svg * {
              fill: $confirmationSocailIconHover;
              @media (max-width: 767px) {
                fill: $confirmationSocailIcon;
              }
            }
            &::after {
              border-color: $confirmationSocailIconHover;
              @media (max-width: 767px) {
                border-color: $confirmationSocailIcon;
              }
            }
          }
        }
        /* ---------------------------------------------------------------- */
        /* Active */
        /* ---------------------------------------------------------------- */
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          &:active {
            svg * {
              fill: $confirmationSocailIconActive;
              @media (max-width: 767px) {
                fill: $confirmationSocailIconHover;
              }
            }
            &::after {
              border-color: $confirmationSocailIconActive;
              @media (max-width: 767px) {
                border-color: $confirmationSocailIconHover;
              }
            }
          }
        }
        /* ---------------------------------------------------------------- */
        /* Icon */
        /* ---------------------------------------------------------------- */
        svg {
          height: 30px;
          width: 30px;
          * {
            fill: $confirmationSocailIcon;
            @include fast-transition($props: "fill");
            @media (max-width: 767px) {
              fill: $footerLink;
            }
          }
        }
      }
    }
  }
}
