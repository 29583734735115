@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Progress */
/* ---------------------------------------------------------------- */
.progress {
  counter-reset: steps;
  display: flex;
  height: 40px;
  margin: 0 0 20px;
  @media (max-width: 767px) {
    height: 36px;
    margin: 16px;
  }
  /* ---------------------------------------------------------------- */
  /* Progress - Item */
  /* ---------------------------------------------------------------- */
  > div,
  > button {
    counter-increment: steps;
    /* ---------------------------------------------------------------- */
    /* Progress - Item - Numbers */
    /* ---------------------------------------------------------------- */
    &::before {
      content: counter(steps);
    }
    &:last-child::before {
      content: none;
    }
  }
}
