@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Address Search Questions */
/* ---------------------------------------------------------------- */
.address-search-questions {
  background-color: $questionSecondaryBg;
  border: 2px solid transparent;
  border-color: $questionSecondaryBorder;
  border-radius: 12px;
  margin: 0 0 12px;
  padding: 12px 12px 26px;
  position: relative;
  @media (max-width: 1024px) {
    border-radius: 0;
    border-width: 2px 0;
    box-sizing: border-box;
    left: -24px;
    padding: 12px 24px 26px;
    width: calc(100% + 48px);
  }
  @media (max-width: 767px) {
    left: -16px;
    padding: 12px 16px 18px;
    width: calc(100% + 32px);
  }
  &:last-child {
    margin: 0;
  }
  /* Question */
  /* ---------------------------------------------------------------- */
  > div {
    border-radius: 10px;
    @media (max-width: 1024px) {
      border-radius: 0;
    }
    /* ---------------------------------------------------------------- */
    /* Label */
    /* ---------------------------------------------------------------- */
    > div:nth-child(1) {
      flex-basis: 300px;
      max-width: 300px;
      @media (max-width: 1024px) {
        flex-basis: calc(100% - 28px);
        max-width: calc(100% - 28px);
      }
    }
  }
}
