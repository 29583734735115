@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Item */
/* ---------------------------------------------------------------- */
.included {
  align-items: center;
  display: flex;
  /* ---------------------------------------------------------------- */
  /* Div */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    display: inline-flex;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 16px;
    @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
      align-items: flex-start;
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 11px;
    }
    &:nth-child(1) {
      max-width: 100%;
      @include user-select;
      > span {
        padding-right: 12px;
        text-align: left;
        @media (max-width: 1240px) {
          padding-right: 0;
        }
        @media (max-width: 550px) {
          padding-right: 0;
        }
      }
      > svg {
        flex-shrink: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Tick Icon */
    /* ---------------------------------------------------------------- */
    > svg {
      height: 20px;
      margin-right: 11px;
      width: 20px;
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        height: 16px;
        margin-right: 7px;
        width: 16px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        height: 13px;
        margin-right: 8px;
        position: relative;
        top: 3px;
        width: 13px;
      }
      * {
        fill: $productOverviewTick;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Optional */
  /* ---------------------------------------------------------------- */
  &.optional {
    align-items: flex-start;
    background-color: $productBg;
    border: 2px solid $productOverviewButtonBorder;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    margin: 0 -12px 12px;
    padding: 8px 10px;
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 550px) {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      position: relative;
    }
    @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
      margin-top: -1px;
    }
    + .optional {
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        margin-top: 0;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    > div:nth-child(1) {
      align-items: flex-start;
      margin: 0;
      > svg {
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          position: relative;
          top: 1px;
        }
        @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
          top: 3px;
        }
      }
      > span {
        font-family: $bold;
      }
    }
  }
}
