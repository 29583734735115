@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modal Loading Quote */
/* ---------------------------------------------------------------- */
.modelLoadingQuote {
  background-color: $modalBg;
  border-radius: 12px;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  justify-content: center;
  max-height: calc(100% - 64px);
  max-width: calc(720px - 64px);
  overflow: hidden;
  position: relative;
  z-index: 1;
  @media (max-width: 1024px) {
    max-height: calc(100% - 48px);
    max-width: calc(720px - 48px);
  }
  @media (max-width: 767px) {
    max-height: calc(100% - 32px);
    max-width: calc(100% - 32px);
  }
  :global(.MuiDialog-container) & {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
  /* ---------------------------------------------------------------- */
  /* Scroll Container */
  /* ---------------------------------------------------------------- */
  > div {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 32px;
    position: relative;
    scrollbar-color: $modalScrollBarThumb $modalScrollBar;
    scrollbar-width: thin;
    @media (max-width: 1024px) {
      padding: 28px;
    }
    @media (max-width: 767px) {
      padding: 24px;
    }
    @media (max-width: 584px) {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    /* ---------------------------------------------------------------- */
    /* Scroll Bar - IE */
    /* ---------------------------------------------------------------- */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      scrollbar-3dlight-color: $modalScrollBarThumb;
      scrollbar-arrow-color: $modalScrollBarThumb;
      scrollbar-base-color: $modalScrollBar;
      scrollbar-face-color: $modalScrollBarThumb;
      scrollbar-highlight-color: $modalScrollBarThumb;
      scrollbar-shadow-color: $modalScrollBarThumb;
      scrollbar-track-color: $modalScrollBar;
    }
    /* ---------------------------------------------------------------- */
    /* Scroll Bar - Webkit / Android / Samsung */
    /* ---------------------------------------------------------------- */
    :global(.platform-windows) &,
    :global(.platform-mobile) & {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: $modalScrollBar;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $modalScrollBarThumb;
        border-radius: 4px;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Loading Dots */
  /* ---------------------------------------------------------------- */
  .dots {
    margin-bottom: 16px;
    @media (max-width: 1024px) {
      margin-bottom: 14px;
    }
    @media (max-width: 767px) {
      margin-bottom: 12px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  p {
    font-family: $bold;
    margin: -4px auto -5px;
    max-width: 470px;
    text-align: center;
    @include user-select;
    @media (max-width: 584px) {
      max-width: 330px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Logo List */
  /* ---------------------------------------------------------------- */
  ul {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    width: 100%;
    @media (max-width: 1024px) {
      margin-top: 28px;
    }
    @media (max-width: 767px) {
      margin-top: 24px;
    }
    @media (max-width: 584px) {
      margin-top: 18px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Logo Item */
  /* ---------------------------------------------------------------- */
  li {
    box-shadow: 0 0 30px rgba($black, 0.3);
    flex-shrink: 0;
    height: auto;
    margin: 0;
    padding-left: 22%;
    padding-top: 22%;
    position: relative;
    width: auto;
    /* ---------------------------------------------------------------- */
    /* Logo */
    /* ---------------------------------------------------------------- */
    div {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      bottom: 12px;
      left: 12px;
      position: absolute;
      right: 12px;
      top: 12px;
      @media (max-width: 584px) {
        bottom: 9px;
        left: 9px;
        right: 9px;
        top: 9px;
      }
    }
  }
}
