@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modal Confirm */
/* ---------------------------------------------------------------- */
.modal-confirm {
  background-color: $modalBg;
  border-radius: 12px;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  justify-content: center;
  max-height: calc(100% - 64px);
  max-width: calc(720px - 64px);
  min-height: 186px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  @media (max-width: 1024px) {
    max-height: calc(100% - 48px);
    max-width: calc(720px - 48px);
    min-height: 173px;
  }
  @media (max-width: 767px) {
    max-height: calc(100% - 32px);
    max-width: 100%;
    min-height: 152px;
    width: calc(100% - 32px);
  }
  :global(.MuiDialog-container) & {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);
    margin: 32px;
    @media (max-width: 1024px) {
      margin: 24px;
    }
    @media (max-width: 767px) {
      margin: 16px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  > header {
    align-items: center;
    background-color: $modalHeaderBg;
    border-bottom: 1px solid $modalHeaderBorder;
    display: flex;
    flex-shrink: 0;
    padding: 12px 24px;
    @media (max-width: 1024px) {
      padding: 10px 20px;
    }
    @media (max-width: 767px) {
      padding: 8px 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Header - Title */
    /* ---------------------------------------------------------------- */
    > h2 {
      color: $title;
      font-family: $bold;
      font-size: 19px;
      line-height: 23px;
      margin-bottom: -1px;
      padding: 3px 16px 4px 0;
      width: 100%;
      @media (max-width: 767px) {
        font-size: 17px;
        line-height: 21px;
      }
      @media (max-width: 440px) {
        font-size: 16px;
        line-height: 20px;
      }
      @media (max-width: 350px) {
        padding-right: 0;
      }
      &:only-child {
        padding: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Header - Icon */
    /* ---------------------------------------------------------------- */
    svg {
      flex-shrink: 0;
      margin-left: auto;
      @media (max-width: 350px) {
        display: none;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Scroll Container */
  /* ---------------------------------------------------------------- */
  > div {
    box-sizing: border-box;
    margin: 20px 0 24px 24px;
    max-height: 480px;
    overflow-y: auto;
    padding-right: 24px;
    position: relative;
    scrollbar-color: $modalScrollBarThumb $modalScrollBar;
    scrollbar-width: thin;
    @media (max-width: 1024px) {
      margin: 20px 0 20px 20px;
      padding-right: 20px;
    }
    @media (max-width: 767px) {
      margin: 16px 0 16px 16px;
      padding-right: 16px;
    }
    :global(.platform-windows) & {
      padding-right: 17px;
      width: calc(100% - 25px);
      @media (max-width: 1024px) {
        padding-right: 13px;
        width: calc(100% - 21px);
      }
      @media (max-width: 767px) {
        padding-right: 9px;
        width: calc(100% - 17px);
      }
    }
    :global(.browser-firefox.platform-windows) & {
      width: calc(100% - 24px);
      @media (max-width: 1024px) {
        width: calc(100% - 20px);
      }
      @media (max-width: 767px) {
        width: calc(100% - 16px);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Scroll Bar - IE */
    /* ---------------------------------------------------------------- */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      scrollbar-3dlight-color: $modalScrollBarThumb;
      scrollbar-arrow-color: $modalScrollBarThumb;
      scrollbar-base-color: $modalScrollBar;
      scrollbar-face-color: $modalScrollBarThumb;
      scrollbar-highlight-color: $modalScrollBarThumb;
      scrollbar-shadow-color: $modalScrollBarThumb;
      scrollbar-track-color: $modalScrollBar;
    }
    /* ---------------------------------------------------------------- */
    /* Scroll Bar - Webkit / Android / Samsung */
    /* ---------------------------------------------------------------- */
    :global(.platform-windows) &,
    :global(.platform-mobile) & {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: $modalScrollBar;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $modalScrollBarThumb;
        border-radius: 4px;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Content */
    /* ---------------------------------------------------------------- */
    > * {
      @include user-select;
      &:first-child {
        margin-top: -3px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $title;
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 15px;
      @media (max-width: 1024px) {
        font-size: 18px;
        line-height: 22px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    strong {
      font-family: $bold;
    }
    p,
    li {
      font-size: 16px;
      line-height: 20px;
      @media (max-width: 1024px) {
        font-size: 15px;
        line-height: 19px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    p,
    ul,
    ol {
      margin: 0 0 15px;
    }
    ul {
      li {
        padding-left: 16px;
        position: relative;
        &::before {
          background-color: $modalListBullet;
          border-radius: 50%;
          content: "";
          display: block;
          height: 8px;
          left: 0;
          position: absolute;
          top: 6px;
          width: 8px;
        }
      }
    }
    li {
      margin: 0 0 11px;
      &:last-child {
        margin: 0;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Footer */
  /* ---------------------------------------------------------------- */
  > footer {
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    padding: 0 24px 24px;
    width: 100%;
    @media (max-width: 1024px) {
      padding: 0 20px 20px;
    }
    @media (max-width: 767px) {
      padding: 0 16px 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Footer - Buttons */
    /* ---------------------------------------------------------------- */
    > div[role="button"],
    > button {
      flex-shrink: 0;
      @media (max-width: 767px) {
        padding: 0 20px;
      }
      @media (max-width: 440px) {
        flex-basis: calc(50% - 4px);
        max-width: calc(50% - 4px);
        padding: 0 12px;
      }
      span {
        @media (max-width: 1024px) {
          font-size: 15px;
        }
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
      &:first-child {
        margin: 0 20px 0 auto;
        @media (max-width: 1024px) {
          margin: 0 16px 0 auto;
        }
        @media (max-width: 767px) {
          margin: 0 12px 0 auto;
        }
        @media (max-width: 440px) {
          margin: 0 8px 0 auto;
        }
      }
    }
  }
  &.claim {
    > header > svg {
      height: 38px;
      width: 47px;
      @media (max-width: 440px) {
        height: 30px;
        width: 36px;
      }
      /* ---------------------------------------------------------------- */
      /* Claim - Icon - Text */
      /* ---------------------------------------------------------------- */
      * {
        fill: $iconClaimText;
      }
      /* ---------------------------------------------------------------- */
      /* Claim - Icon - Highlighted */
      /* ---------------------------------------------------------------- */
      *:nth-child(3),
      *:nth-child(7),
      *:nth-child(22) {
        fill: $iconClaimHighlighted;
      }
      /* ---------------------------------------------------------------- */
      /* Claim - Icon - Page */
      /* ---------------------------------------------------------------- */
      *:nth-child(1),
      *:nth-child(5),
      *:nth-child(15) {
        fill: $iconClaimPage;
      }
      /* ---------------------------------------------------------------- */
      /* Claim - Icon - Stroke */
      /* ---------------------------------------------------------------- */
      *:nth-child(2),
      *:nth-child(6),
      *:nth-child(16) {
        fill: $iconClaimStroke;
      }
    }
  }
  &.bike {
    > header > svg {
      height: 38px;
      width: 48px;
      @media (max-width: 440px) {
        height: 30px;
        width: 36px;
      }
      * {
        @include fast-transition($mob: true, $props: "fill");
        /* ---------------------------------------------------------------- */
        /* Vehicle - Icon - Stroke */
        /* ---------------------------------------------------------------- */
        &:nth-child(1) {
          fill: $iconBikeStroke;
        }
        /* ---------------------------------------------------------------- */
        /* Vehicle - Icon - Seat */
        /* ---------------------------------------------------------------- */
        &:nth-child(2) {
          fill: $iconBikeSeat;
        }
        /* ---------------------------------------------------------------- */
        /* Vehicle - Icon - Tyres */
        /* ---------------------------------------------------------------- */
        &:nth-child(3),
        &:nth-child(4) {
          fill: $iconBikeTyres;
        }
        /* ---------------------------------------------------------------- */
        /* Vehicle - Icon - Body */
        /* ---------------------------------------------------------------- */
        &:nth-child(5) {
          fill: $iconBikeBody;
        }
        /* ---------------------------------------------------------------- */
        /* Vehicle - Icon - Wheels */
        /* ---------------------------------------------------------------- */
        &:nth-child(6),
        &:nth-child(7) {
          fill: $iconBikeWheels;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Legal Expenses & Loss Recovery */
  /* ---------------------------------------------------------------- */
  &.legal {
    > header > svg {
      height: 38px;
      width: 38px;
      @media (max-width: 440px) {
        height: 30px;
        width: 30px;
      }
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Head */
      /* ---------------------------------------------------------------- */
      *:nth-child(1),
      *:nth-child(2),
      *:nth-child(3) {
        fill: $iconHammerHead;
      }
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Base */
      /* ---------------------------------------------------------------- */
      *:nth-child(4) {
        fill: $iconHammerBase;
      }
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Gavel */
      /* ---------------------------------------------------------------- */
      *:nth-child(5),
      *:nth-child(6) {
        fill: $iconHammerGavel;
      }
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Handle */
      /* ---------------------------------------------------------------- */
      *:nth-child(7) {
        fill: $iconHammerHandle;
      }
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Stroke */
      /* ---------------------------------------------------------------- */
      *:nth-child(8),
      *:nth-child(9) {
        fill: $iconHammerStroke;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Helmet & Leathers */
  /* ---------------------------------------------------------------- */
  &.helmet {
    > header > svg {
      height: 38px;
      width: 41px;
      @media (max-width: 440px) {
        height: 30px;
        width: 32px;
      }
      /* ---------------------------------------------------------------- */
      /* Helmet - Icon - Outer Shell */
      /* ---------------------------------------------------------------- */
      *:nth-child(1) {
        fill: $iconHelmetOuterShell;
      }
      /* ---------------------------------------------------------------- */
      /* Helmet - Icon - Base */
      /* ---------------------------------------------------------------- */
      *:nth-child(2) {
        fill: $iconHelmetBase;
      }
      /* ---------------------------------------------------------------- */
      /* Helmet - Icon - Visor */
      /* ---------------------------------------------------------------- */
      *:nth-child(3) {
        fill: $iconHelmetVisor;
      }
      /* ---------------------------------------------------------------- */
      /* Helmet - Icon - Pivot */
      /* ---------------------------------------------------------------- */
      *:nth-child(4) {
        fill: $iconHelmetPivot;
      }
      /* ---------------------------------------------------------------- */
      /* Helmet - Icon - Stroke */
      /* ---------------------------------------------------------------- */
      *:nth-child(5) {
        fill: $iconHelmetStroke;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Personal Accident */
  /* ---------------------------------------------------------------- */
  &.accident {
    > header > svg {
      height: 38px;
      width: 38px;
      @media (max-width: 440px) {
        height: 30px;
        width: 30px;
      }
      /* ---------------------------------------------------------------- */
      /* Accident - Icon - Bg */
      /* ---------------------------------------------------------------- */
      *:nth-child(1) {
        fill: $accidentBg;
      }
      /* ---------------------------------------------------------------- */
      /* Accident - Icon - Cross */
      /* ---------------------------------------------------------------- */
      *:nth-child(2) {
        fill: $accidentCross;
      }
      /* ---------------------------------------------------------------- */
      /* Accident - Icon - Stroke */
      /* ---------------------------------------------------------------- */
      *:nth-child(3),
      *:nth-child(4),
      *:nth-child(5),
      *:nth-child(6) {
        fill: $accidentStroke;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* RAC Breakdown Cover */
  /* ---------------------------------------------------------------- */
  &.breakdown {
    > header > svg {
      height: 21px;
      width: 62px;
      @media (max-width: 440px) {
        height: 16px;
        width: 48px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Devitt Enhanced */
  /* ---------------------------------------------------------------- */
  &.enhanced {
    > header > svg {
      height: 38px;
      width: 84px;
      @media (max-width: 440px) {
        height: 30px;
        width: 67px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Devitt Evolution */
  /* ---------------------------------------------------------------- */
  &.evolution {
    > header > svg {
      height: 38px;
      width: 84px;
      @media (max-width: 440px) {
        height: 30px;
        width: 67px;
      }
    }
  }
}
