@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Quote Tiers */
/* ---------------------------------------------------------------- */
.quote-tiers {
  background-color: $quoteBg;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  flex-basis: calc(100% - 464px);
  flex-direction: column;
  margin-right: 16px;
  max-width: calc(100% - 464px);
  overflow: hidden;
  width: 100%;
  @media (max-width: 1350px) {
    flex-basis: calc(65% - 12px);
    margin-right: 12px;
    max-width: calc(65% - 12px);
  }
  @media (max-width: 1024px) {
    flex-basis: calc(65% - 8px);
    margin-right: 8px;
    max-width: calc(65% - 8px);
  }
  @media (max-width: 767px) {
    border-radius: 0;
    flex-basis: auto;
    margin-bottom: 32px;
    margin-right: 0;
    max-width: 100%;
  }
  @media (max-width: 440px) {
    margin-bottom: 24px;
  }
  /* ---------------------------------------------------------------- */
  /* Layout */
  /* ---------------------------------------------------------------- */
  > header,
  > div,
  > footer {
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      padding: 0 40px;
      @media (max-width: 1375px) {
        padding: 0 32px;
      }
      @media (max-width: 1350px) {
        padding: 0 24px;
      }
      @media (max-width: 1230px) {
        padding: 0 20px;
      }
      @media (max-width: 767px) {
        padding: 0 16px;
      }
      @media (max-width: 685px) {
        padding: 0 12px;
      }
      > div {
        display: flex;
        &:nth-child(1) {
          flex-basis: calc(100% - 444px);
          max-width: calc(100% - 444px);
          @media (max-width: 1350px) {
            flex-basis: calc(100% - 408px);
            max-width: calc(100% - 408px);
          }
          @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
            flex-basis: calc(100% - 372px);
            max-width: calc(100% - 372px);
          }
        }
      }
    }
  }
  > footer > div > div {
    align-items: center;
  }
  > header > div > div,
  > footer > div > div {
    display: flex;
    flex-basis: 140px;
    flex-shrink: 0;
    justify-content: center;
    max-width: 140px;
    @media (max-width: 1350px) {
      flex-basis: 128px;
      max-width: 128px;
    }
    @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
      flex-basis: 116px;
      max-width: 116px;
    }
    &:nth-child(1) {
      @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
        display: none;
      }
    }
    &:nth-child(2) {
      margin-left: auto;
      margin-right: 6px;
      &:nth-last-child(2) {
        @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
          flex-basis: calc(50% - 6px);
          margin: 0 6px 0 0;
          max-width: calc(50% - 6px);
        }
      }
      &:last-child {
        @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
          flex-basis: 100%;
          margin: 0;
          max-width: 100%;
        }
      }
      @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
        flex-basis: calc(33.33% - 8px);
        margin: 0 4px;
        max-width: calc(33.33% - 8px);
      }
    }
    &:nth-child(3) {
      margin: 0 6px;
      &:last-child {
        margin-right: 0;
        @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
          flex-basis: calc(50% - 6px);
          margin: 0 0 0 6px;
          max-width: calc(50% - 6px);
        }
      }
      @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
        flex-basis: calc(33.33% - 8px);
        margin: 0 4px;
        max-width: calc(33.33% - 8px);
      }
    }
    &:nth-child(4) {
      margin-left: 6px;
      @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
        flex-basis: calc(33.33% - 8px);
        margin: 0 4px;
        max-width: calc(33.33% - 8px);
      }
    }
  }
  > div > div {
    @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
      flex-direction: column;
    }
    > div {
      &:nth-child(1) {
        @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
          flex-basis: auto;
          justify-content: center;
          margin: 0 0 8px;
          max-width: 100%;
        }
      }
      &:nth-child(2) > div {
        align-items: center;
        display: flex;
        flex-basis: 140px;
        flex-shrink: 0;
        justify-content: center;
        max-width: 140px;
        @media (max-width: 1350px) {
          flex-basis: 128px;
          max-width: 128px;
        }
        @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
          flex-basis: 116px;
          max-width: 116px;
        }
        &:nth-child(1) {
          margin-left: auto;
          margin-right: 6px;
          &:nth-last-child(2) {
            @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
              flex-basis: calc(50% - 6px);
              margin: 0 6px 0 0;
              max-width: calc(50% - 6px);
            }
          }
          &:only-child {
            @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
              flex-basis: 100%;
              margin: 0;
              max-width: 100%;
            }
          }
          @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
            flex-basis: calc(33.33% - 8px);
            margin: 0 4px;
            max-width: calc(33.33% - 8px);
          }
        }
        &:nth-child(2) {
          margin: 0 6px;
          &:last-child {
            margin-right: 0;
            @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
              flex-basis: calc(50% - 6px);
              margin: 0 0 0 6px;
              max-width: calc(50% - 6px);
            }
          }
          @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
            flex-basis: calc(33.33% - 8px);
            margin: 0 4px;
            max-width: calc(33.33% - 8px);
          }
        }
        &:nth-child(3) {
          margin-left: 6px;
          @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
            flex-basis: calc(33.33% - 8px);
            margin: 0 4px;
            max-width: calc(33.33% - 8px);
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  > header {
    @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
      margin-bottom: 21px;
    }
    @media (max-width: 440px) {
      margin-bottom: 17px;
    }
    > div {
      background-color: $quoteBg;
      padding-bottom: 24px;
      padding-top: 24px;
      @media (max-width: 1350px) {
        padding-bottom: 20px;
        padding-top: 20px;
      }
      @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
        padding-bottom: 0;
        padding-top: 0;
      }
      /* ---------------------------------------------------------------- */
      /* Mobile Title */
      /* ---------------------------------------------------------------- */
      &:nth-child(1) {
        display: none;
        @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
          color: $primary;
          display: block;
          font-family: $bold;
          font-size: 25px;
          line-height: 30px;
          margin: -6px 0 -1px;
          padding-top: 24px;
          text-align: center;
        }
        @media (max-width: 440px) {
          font-size: 23px;
          line-height: 27px;
          padding-top: 21px;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Logos */
      /* ---------------------------------------------------------------- */
      &:nth-child(2) {
        @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
          margin-bottom: 10px;
          padding-top: 15px;
        }
        @media (max-width: 440px) {
          padding-top: 11px;
        }
        > div {
          /* ---------------------------------------------------------------- */
          /* Title */
          /* ---------------------------------------------------------------- */
          &:nth-child(1) {
            color: $primary;
            font-family: $bold;
            font-size: 28px;
            justify-content: flex-start;
            line-height: 33px;
            margin: 2px 0 -3px;
            @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
              font-size: 25px;
              line-height: 30px;
              margin: -1px 0 -6px;
            }
          }
          svg {
            height: 45px;
            width: 100px;
            @media (max-width: 1230px) {
              height: 36px;
              width: 80px;
            }
            @media (max-width: 767px) {
              height: 36px;
              width: 80px;
            }
            @media (max-width: 440px) {
              height: 34px;
              width: 75px;
            }
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Mobile Price */
      /* ---------------------------------------------------------------- */
      &:nth-child(3) {
        display: none;
        @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
          display: flex;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Features */
  /* ---------------------------------------------------------------- */
  > div {
    /* stylelint-disable-next-line no-duplicate-selectors */
    > div {
      background-color: $quoteBg;
      @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
        padding: 5px 20px 12px;
      }
      @media (max-width: 685px) {
        padding-inline: 12px;
      }
      &:nth-child(odd) {
        background-color: $quoteFeaturesBg;
        border-bottom: 2px solid $quoteFeaturesBorder;
        border-top: 2px solid $quoteFeaturesBorder;
      }
      /* stylelint-disable-next-line no-duplicate-selectors */
      > div {
        flex-grow: 1;
        height: 44px;
        @media (max-width: 1350px) {
          height: 42px;
        }
        @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
          height: 40px;
        }
        @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
          height: auto;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Buttons */
    /* ---------------------------------------------------------------- */
    div[role="button"],
    button {
      align-self: center;
      cursor: pointer;
      display: inline-flex;
      font-family: $regular;
      font-size: 16px;
      line-height: 25px;
      position: relative;
      text-align: left;
      text-decoration: underline;
      text-decoration-color: $quoteLegal;
      text-decoration-style: dotted;
      text-underline-offset: 5px;
      white-space: nowrap;
      @include fast-transition($props: "color, text-decoration-color");
      @media (max-width: 1350px) {
        font-size: 15px;
        line-height: 24px;
      }
      @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
        font-size: 14px;
        line-height: 23px;
      }
      @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
        font-size: 16px;
        line-height: 25px;
      }
      @media (max-width: 440px) {
        font-size: 15px;
        text-align: center;
      }
      &:focus {
        color: $hyperlinkHover;
        text-decoration-color: $hyperlinkHover;
      }
      :global(.platform-desktop) & {
        &:hover {
          color: $hyperlinkHover;
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          color: $hyperlinkActive;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Ticks */
    /* ---------------------------------------------------------------- */
    svg {
      height: 20px;
      width: 20px;
      @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
        height: 18px;
        width: 18px;
      }
      @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
        height: 16px;
        width: 16px;
      }
      * {
        fill: $quoteTickBg;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Footer */
  /* ---------------------------------------------------------------- */
  > footer {
    > div {
      background-color: $quoteBg;
    }
    .price {
      align-items: center;
      background-color: $quotePriceBg;
      border-bottom: 2px solid $quotePriceBorder;
      border-top: 2px solid $quotePriceBorder;
      box-sizing: border-box;
      height: 82px;
      @media (max-width: 1350px) {
        height: 78px;
      }
      @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
        height: 74px;
      }
      @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
        display: none;
      }
      /* ---------------------------------------------------------------- */
      /* Upgrade Title */
      /* ---------------------------------------------------------------- */
      > div {
        @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
          font-size: 15px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Price Row */
  /* ---------------------------------------------------------------- */
  .price {
    > div {
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      &:first-child {
        align-items: flex-start;
      }
    }
    > * {
      color: $quotePrice;
      /* ---------------------------------------------------------------- */
      /* Upgrade title */
      /* ---------------------------------------------------------------- */
      &:first-child {
        color: $text;
        font-family: $bold;
        @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
          display: none;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Monthly / Annual Prices */
      /* ---------------------------------------------------------------- */
      > strong {
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;
        font-family: $semibold;
        font-size: 16px;
        justify-content: center;
        line-height: 18px;
        margin: 2px 0 6px;
        white-space: pre;
        @media (max-width: 1350px) {
          font-size: 15px;
          line-height: 17px;
        }
        @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
          font-size: 14px;
          line-height: 16px;
        }
        @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
          font-size: 10px;
          line-height: 12px;
          margin: 0 0 2px;
        }
        @media (max-width: 440px) {
          font-size: 9px;
          line-height: 12px;
        }
        &:only-child {
          margin: 0;
        }
        > span {
          font-family: $bold;
          font-size: 20px;
          line-height: 20px;
          @media (max-width: 1350px) {
            font-size: 19px;
            line-height: 19px;
          }
          @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
            font-size: 17px;
            line-height: 17px;
          }
          @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
            font-size: 14px;
            line-height: 14px;
          }
          @media (max-width: 440px) {
            font-size: 13px;
            line-height: 13px;
          }
        }
      }
      > span {
        font-family: $regular;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        @media (max-width: 1350px) {
          font-size: 11px;
          line-height: 14px;
        }
        @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
          font-size: 10px;
          line-height: 13px;
        }
        @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
          font-size: 11px;
          line-height: 14px;
        }
        @media (max-width: 440px) {
          font-size: 10px;
          line-height: 13px;
        }
      }
      span {
        display: block;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  .buttons {
    padding-top: 20px;
    &:last-child {
      padding-bottom: 28px;
      @media (max-width: 1350px) {
        padding-bottom: 21px;
      }
      @media (max-width: 767px) {
        padding-bottom: 29px;
      }
      @media (max-width: 440px) {
        padding-bottom: 21px;
      }
    }
    @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
      border-top: 2px solid $quoteFeaturesBorder;
    }
    div[role="button"],
    button {
      border-radius: 6px;
      height: 50px;
      padding: 0 12px;
      width: 100%;
      @media (max-width: 440px) {
        flex-direction: column;
        height: 75px;
        padding: 0 16px;
      }
      @media (max-width: 360px) {
        padding: 0 8px;
      }
      svg {
        height: 20px;
        width: 20px;
        @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
          height: 18px;
          width: 18px;
        }
        @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
          height: 20px;
          width: 20px;
        }
        @media (max-width: 440px) {
          margin: 1px 0 5px;
        }
        + span {
          width: auto;
        }
      }
      span {
        font-family: $semibold;
        text-align: center;
        width: 100%;
        @media (max-width: 1350px) {
          font-size: 15px;
        }
        @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
          font-size: 16px;
        }
        @media (max-width: 440px) {
          white-space: normal;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Discount */
  /* ---------------------------------------------------------------- */
  .discount {
    padding-bottom: 28px;
    @media (max-width: 1350px) {
      padding-bottom: 21px;
    }
    @media (max-width: 767px) {
      padding-bottom: 29px;
    }
    @media (max-width: 440px) {
      padding-bottom: 21px;
    }
    > div {
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
    }
    strong {
      color: $quoteDiscount;
      display: block;
      font-family: $bold;
      font-size: 15px;
      line-height: 18px;
      margin: 13px 0 1px;
      text-align: center;
      width: 100%;
      @media (max-width: 1350px) {
        font-size: 14px;
        line-height: 17px;
      }
      @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
        font-size: 13px;
        line-height: 16px;
      }
      @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
        font-size: 13px;
        line-height: 16px;
        margin: 9px 0 2px;
      }
      @media (max-width: 440px) {
        font-size: 12px;
        line-height: 15px;
      }
    }
    span {
      color: $quoteDiscount;
      display: block;
      font-family: $medium;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      width: 100%;
      @media (max-width: 1350px) {
        font-size: 13px;
        line-height: 16px;
      }
      @media (max-width: 1230px), (max-width: 767px) and (min-width: 686px) {
        font-size: 12px;
        line-height: 15px;
      }
      @media (max-width: 1134px) and (min-width: 768px), (max-width: 685px) {
        font-size: 12px;
        line-height: 15px;
      }
      @media (max-width: 440px) {
        font-size: 11px;
        line-height: 14px;
      }
    }
  }
}
