@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Main */
/* ---------------------------------------------------------------- */
.main {
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0 auto;
  max-width: 1360px;
  min-width: 320px;
  padding: 0 32px;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 0 24px;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
}
