@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Progress Item */
/* ---------------------------------------------------------------- */
.progress-item {
  align-items: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: $progressBg;
  border-bottom: 6px solid $progressBorder;
  box-shadow: 0 6px 6px rgba($progressBoxShadow, 0.15);
  box-sizing: border-box;
  color: $progressText;
  display: flex;
  flex-basis: calc(33.33% - 16px);
  flex-shrink: 0;
  font-family: $semibold;
  justify-content: center;
  margin: 0 12px;
  max-width: calc(33.33% - 16px);
  padding: 0 12px;
  position: relative;
  vertical-align: top;
  @include fast-transition($mob: true, $props: "transform, background-color, border-color");
  @media (max-width: 767px) {
    border: none;
    box-sizing: border-box;
    flex-basis: auto;
    justify-content: flex-start;
    margin: 0 8px;
    max-width: none;
    padding: 0 8px;
  }
  @media (max-width: 430px) {
    flex-basis: 40px;
    max-width: 40px;
  }
  &:first-child {
    border-radius: 6px 0 0 6px;
    margin-left: 0;
    padding-left: 16px;
    @media (max-width: 767px) {
      padding-left: 10px;
    }
    @media (max-width: 430px) {
      flex-basis: 42px;
      max-width: 42px;
    }
    > svg:first-child {
      display: none;
    }
  }
  &:last-child {
    border-radius: 0 6px 6px 0;
    flex-basis: calc(33.34% - 16px);
    margin-right: 0;
    max-width: calc(33.34% - 16px);
    padding-right: 16px;
    @media (max-width: 767px) {
      flex-basis: auto;
      max-width: none;
      padding-right: 10px;
    }
    @media (max-width: 430px) {
      flex-basis: 42px;
      max-width: 42px;
    }
    > svg:last-child {
      display: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Progress Item Button */
  /* ---------------------------------------------------------------- */
  @at-root button#{&} {
    font-weight: normal;
    overflow: visible;
    vertical-align: top;
  }
  @at-root button#{&},
    &[role="button"] {
    cursor: pointer;
    flex-shrink: 0;
    transform: scale(1);
    transform-origin: 50% 50%;
    &:focus {
      background-color: darken($progressBg, 7.5%);
      &::before {
        background-color: darken($progressBg, 7.5%);
      }
      > div {
        text-decoration: underline;
        text-decoration-color: $progressText;
      }
      > svg *:nth-child(2),
      > svg *:nth-child(3) {
        fill: darken($progressBg, 7.5%);
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: darken($progressBg, 7.5%);
        &::before {
          background-color: darken($progressBg, 7.5%);
        }
        > svg *:nth-child(2),
        > svg *:nth-child(3) {
          fill: darken($progressBg, 7.5%);
        }
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        background-color: darken($progressBg, 15%);
        transform: scale(0.98);
        &::before {
          background-color: darken($progressBg, 15%);
        }
        > svg *:nth-child(2),
        > svg *:nth-child(3) {
          fill: darken($progressBg, 15%);
        }
      }
    }
    :global(.platform-windows) & {
      &:active {
        transform: scale(1);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Progress Item - Number */
  /* ---------------------------------------------------------------- */
  &::before {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: $progressBg;
    border: 2px solid $progressBorder;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    flex-shrink: 0;
    font-family: $semibold;
    font-size: 16px;
    height: 24px;
    line-height: 20px;
    margin: 0 8px 0 0;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    width: 24px;
    z-index: 1;
    @include fast-transition($mob: true, $props: "background-color");
    @media (max-width: 767px) {
      font-size: 15px;
      letter-spacing: -0.5px;
      line-height: 20px;
      word-spacing: -0.5px;
      :global(.platform-windows.browser-firefox) & {
        line-height: 18px;
      }
    }
    @at-root .available#{&} {
      border-color: $progressBorderComplete;
      color: $progressTextComplete;
    }
    @at-root .active#{&} {
      background-color: $progressBorderActive;
      border-color: $progressBorderActive;
      color: $progressBg;
      @media (max-width: 767px) {
        background-color: $progressBg;
        color: $progressTextActive;
        margin: 0 8px 0 0;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Progress Item - Text */
  /* ---------------------------------------------------------------- */
  > div {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    flex-shrink: 0;
    font-size: 16px;
    line-height: 19px;
    pointer-events: none;
    position: relative;
    z-index: 1;
    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 18px;
    }
    @media (max-width: 430px) {
      display: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Progress Item -Icons */
  /* ---------------------------------------------------------------- */
  > svg {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    flex-shrink: 0;
    /* ---------------------------------------------------------------- */
    /* Chevron Tail */
    /* ---------------------------------------------------------------- */
    &:first-child {
      height: 40px;
      left: -13px;
      position: absolute;
      top: 0;
      width: 14px;
      @media (max-width: 767px) {
        height: 36px;
        left: -8.5px;
        width: 9px;
      }
      * {
        @include fast-transition($mob: true, $props: "fill");
        &:nth-child(1) {
          fill: $progressBorder;
          @media (max-width: 767px) {
            fill: $progressBg;
          }
          @media (max-width: 767px) {
            display: none;
          }
        }
        &:nth-child(2) {
          fill: $progressBg;
          @media (max-width: 767px) {
            display: none;
          }
        }
        &:nth-child(3) {
          display: none;
          fill: $progressBg;
          @media (max-width: 767px) {
            display: block;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Bike */
    /* ---------------------------------------------------------------- */
    &:nth-child(2) {
      height: 28px;
      margin: 0 8px 0 0;
      width: 28px;
      @media (max-width: 767px) {
        height: 24px;
        width: 24px;
      }
      * {
        fill: $progressIcon;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Chevron Head */
    /* ---------------------------------------------------------------- */
    &:last-child {
      height: 40px;
      position: absolute;
      right: -16px;
      top: 0;
      width: 17px;
      @media (max-width: 767px) {
        height: 36px;
        right: -8px;
        width: 9px;
      }
      * {
        @include fast-transition($mob: true, $props: "fill");
        &:nth-child(1) {
          fill: $progressBorder;
          @media (max-width: 767px) {
            fill: $progressBg;
          }
          @media (max-width: 767px) {
            display: none;
          }
        }
        &:nth-child(2) {
          fill: $progressBg;
          @media (max-width: 767px) {
            display: none;
          }
        }
        &:nth-child(3) {
          display: none;
          fill: $progressBg;
          @media (max-width: 767px) {
            display: block;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Available */
  /* ---------------------------------------------------------------- */
  &.available {
    border-color: $progressBorderComplete;
    color: $progressTextComplete;
    @at-root button#{&},
      &[role="button"] {
      &:focus {
        > div {
          text-decoration-color: $progressTextComplete;
        }
      }
    }
    &::before {
      border-color: $progressBorderComplete;
      color: $progressTextComplete;
    }
    > svg {
      &:nth-child(1),
      &:nth-child(3) {
        *:nth-child(1) {
          fill: $progressBorderComplete;
          @media (max-width: 767px) {
            fill: $progressBg;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Active */
  /* ---------------------------------------------------------------- */
  &.active {
    border-color: $progressBorderActive;
    color: $progressTextActive;
    @media (max-width: 767px) {
      flex-grow: 1;
    }
    @media (max-width: 430px) {
      max-width: 100%;
    }
    @at-root button#{&},
      &[role="button"] {
      &:focus {
        > div {
          text-decoration-color: $progressTextActive;
        }
      }
    }
    &::before {
      background-color: $progressBorderActive;
      border-color: $progressBorderActive;
      color: $progressBg;
    }
    > div {
      @media (max-width: 767px) {
        display: block;
        font-size: 15px;
        letter-spacing: -0.5px;
        line-height: 18px;
        word-spacing: -0.5px;
      }
    }
    > svg {
      &:nth-child(1),
      &:nth-child(3) {
        *:nth-child(1) {
          fill: $progressBorderActive;
          @media (max-width: 767px) {
            fill: $progressBg;
          }
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Read Only */
  /* -------------------------------------------------------------- */
  &.read-only {
    @at-root button#{&},
      &[role="button"] {
      cursor: default;
      pointer-events: none;
      &:focus {
        background-color: $progressBg;
        &::before {
          background-color: $progressBg;
        }
        > div {
          text-decoration: none;
        }
        > svg {
          * {
            &:nth-child(2) {
              fill: $progressBg;
            }
            &:nth-child(3) {
              @media (max-width: 767px) {
                fill: $progressBg;
              }
            }
          }
        }
      }
    }
  }
}
