@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Loading Wheel */
/* ---------------------------------------------------------------- */
.loadingWheel {
  align-self: center;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
  height: 120px;
  margin: 0 auto;
  overflow: hidden;
  width: 120px;
  /* ---------------------------------------------------------------- */
  /* Spinner */
  /* ---------------------------------------------------------------- */
  > svg {
    height: 120px;
    width: 120px;
    /* ---------------------------------------------------------------- */
    /* Wheel */
    /* ---------------------------------------------------------------- */
    > :global(#Wheel) {
      animation: wheel-spin 2s linear infinite;
      transform-origin: 50% 50%;
      will-change: transform;
      @keyframes wheel-spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }
  }
}
