@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Header Contact */
/* ---------------------------------------------------------------- */
.header-contact {
  align-items: flex-end;
  cursor: default;
  display: flex;
  margin: 0 0 1px;
  @media (max-width: 767px) {
    height: 32px;
  }
  /* ---------------------------------------------------------------- */
  /* Header Contact - Text */
  /* ---------------------------------------------------------------- */
  > p {
    color: $headerText;
    font-family: $bold;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 20px;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header Contact - Text */
  /* ---------------------------------------------------------------- */
  > div:not([role="button"]) {
    color: $headerTextNumber;
    font-family: $bold;
    margin: 0 0 0 5px;
    @media (max-width: 767px) {
      display: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header Contact - Buttons */
  /* ---------------------------------------------------------------- */
  > [role="button"],
  > button {
    @media (max-width: 767px) {
      height: 32px;
    }
    @media (max-width: 375px) {
      padding: 0 18px;
    }
    @media (max-width: 350px) {
      padding: 0 14px;
    }
    /* ---------------------------------------------------------------- */
    /* Header Contact - Phone Button */
    /* ---------------------------------------------------------------- */
    &:nth-child(2) {
      background-color: transparent;
      border: 0;
      border-color: transparent;
      transition: none;
      @media (max-width: 767px) {
        align-items: center;
        cursor: pointer;
        display: inline-flex;
        padding: 0 26px;
        position: relative;
        &:focus::after {
          background-color: $primaryButtonBgHover;
          border-color: $primaryButtonBorderHover;
        }
        :global(.platform-desktop) & {
          &:hover::after {
            background-color: $primaryButtonBgHover;
            border-color: $primaryButtonBorderHover;
          }
        }
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          &:active::after {
            background-color: $primaryButtonBgActive;
            border-color: $primaryButtonBorderActive;
          }
        }
      }
      @media (max-width: 375px) {
        padding: 0 20px;
      }
      @media (max-width: 350px) {
        padding: 0 16px;
      }
      @media (min-width: 768px) {
        color: $primaryButtonBg;
        cursor: pointer;
        font-family: $bold;
        height: auto;
        margin: 0 0 0 5px;
        padding: 0;
        &:focus {
          color: $primaryButtonBgHover;
          text-decoration: underline;
          text-decoration-color: $primaryButtonBgHover;
        }
        :global(.platform-desktop) & {
          &:hover {
            color: $primaryButtonBgHover;
          }
        }
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          &:active {
            color: $primaryButtonBgActive;
          }
        }
      }
      &::after {
        @media (max-width: 767px) {
          background-color: $primaryButtonBg;
          border: 2px solid $primaryButtonBorder;
          border-radius: 4px;
          box-sizing: border-box;
          content: "";
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 0;
          @include fast-transition($props: "border-color, background-color");
        }
      }
      /* ---------------------------------------------------------------- */
      /* Header Contact - Phone Button Icon */
      /* ---------------------------------------------------------------- */
      > svg {
        @media (max-width: 767px) {
          flex-shrink: 0;
          height: 16px;
          margin: 0;
          pointer-events: none;
          position: relative;
          width: 16px;
          z-index: 1;
          * {
            fill: $primaryButtonText;
          }
        }
        @media (min-width: 768px) {
          display: none;
        }
      }
    }
    &:last-child {
      @media (max-width: 767px) {
        margin-left: 8px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Header Contact - Chat Button */
    /* ---------------------------------------------------------------- */
    &:nth-child(3) {
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header Contact - Chat Button Maximized */
  /* ---------------------------------------------------------------- */
  &.maximized {
    > [role="button"],
    > button {
      &:nth-child(3) {
        background-color: $successButtonBgHover;
        border-color: $successButtonBorderHover;
      }
    }
  }
  > div,
  button {
    /* ---------------------------------------------------------------- */
    /* Header Contact - Text - Phone */
    /* ---------------------------------------------------------------- */
    > span {
      cursor: text;
      display: none;
      font-size: 20px;
      @include user-select;
      @media (min-width: 768px) {
        color: inherit;
        display: block;
      }
      @media (max-width: 1024px) {
        font-size: 18px;
      }
    }
  }
}
