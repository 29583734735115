@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Product Policy Info */
/* ---------------------------------------------------------------- */
.product-policy-info {
  background-color: $productBg;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  margin: 0 0 24px;
  position: relative;
  @media (max-width: 1240px) {
    margin: 0 0 16px;
  }
  @media (max-width: 1024px) {
    margin: 0 0 12px;
  }
  @media (max-width: 767px) {
    border-radius: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > header {
    margin: 0 0 15px;
    padding: 24px 20px 0;
    @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
      margin: 0 0 14px;
    }
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
      margin: 0 0 6px;
    }
    @media (max-width: 767px) and (min-width: 671px) {
      margin: 0 0 15px;
    }
    @media (max-width: 767px) {
      padding: 24px 16px 0;
    }
    > h2 {
      color: $title;
      font-family: $bold;
      font-size: 28px;
      line-height: 32px;
      margin: 0 0 26px;
      position: relative;
      text-transform: capitalize;
      @include no-user-select;
      @media (max-width: 1240px) {
        margin: 0 0 20px;
      }
      @media (max-width: 1024px) {
        margin: 0 0 16px;
      }
      @media (max-width: 767px) {
        margin: 0 0 20px;
      }
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        font-size: 24px;
        line-height: 28px;
      }
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 23px;
        line-height: 27px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 22px;
        line-height: 26px;
      }
      &:first-child {
        margin-right: 105px;
        margin-top: -5px;
        @media (max-width: 1240px) {
          margin-right: 91px;
        }
        @media (max-width: 884px) {
          margin-right: 89px;
        }
        @media (max-width: 836px) {
          margin-right: 88px;
        }
        @media (max-width: 767px) {
          margin-right: 101px;
        }
        @media (max-width: 670px) {
          margin-right: 87px;
        }
        @media (max-width: 390px) {
          margin-right: 84px;
        }
      }
      &::after {
        background-color: $titleBorder;
        content: "";
        display: block;
        height: 2px;
        margin: 11px 0 0;
        position: relative;
        width: 100%;
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          margin: 6px 0 0;
        }
      }
    }
    > p {
      @include user-select;
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 15px;
        line-height: 19px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Info */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    margin: 0 0 24px;
    padding: 0 20px;
    @include user-select;
    @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
      margin: 0 0 20px;
    }
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
      flex-direction: column;
      margin: 0;
    }
    @media (max-width: 767px) {
      padding: 0 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Columns */
    /* ---------------------------------------------------------------- */
    > table {
      border-collapse: collapse;
      font-family: $regular;
      width: 50%;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        width: 100%;
      }
      &:nth-child(1) {
        tr > td:nth-child(1) {
          text-align: right;
          white-space: nowrap;
          width: 1%;
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            text-align: left;
            white-space: normal;
            width: 50%;
          }
        }
      }
      &:nth-child(2) {
        tr > td:nth-child(1) {
          text-align: right;
          white-space: nowrap;
          width: 1%;
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            text-align: left;
            white-space: normal;
            width: 50%;
          }
        }
        tr:last-child {
          border: none;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Row */
      /* ---------------------------------------------------------------- */
      tr {
        vertical-align: top;
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          border-bottom: 2px solid $titleBorder;
        }
        /* ---------------------------------------------------------------- */
        /* Columns */
        /* ---------------------------------------------------------------- */
        > td {
          line-height: 22px;
          padding: 0;
          @include user-select;
          @media (max-width: 1342px) and (min-width: 1325px) {
            :global(.platform-windows) & {
              font-size: 14px;
              line-height: 20px;
            }
          }
          @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
            font-size: 14px;
            line-height: 20px;
          }
          @media (max-width: 1258px) and (min-width: 1241px) {
            :global(.platform-windows) & {
              font-size: 13px;
              line-height: 19px;
            }
          }
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            font-size: 15px;
            line-height: 19px;
            padding: 9px 8px 7px 0;
          }
          @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
            font-size: 14px;
            line-height: 18px;
          }
          @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
            font-size: 13px;
            line-height: 17px;
          }
          @media (max-width: 767px) and (min-width: 671px) {
            padding: 0;
          }
          /* ---------------------------------------------------------------- */
          /* Label */
          /* ---------------------------------------------------------------- */
          &:nth-child(1) {
            color: $productLegal;
            padding-right: 12px;
            @media (max-width: 1324px) and (min-width: 1240px),
              (max-width: 710px) and (min-width: 671px),
              (max-width: 414px) {
              padding-right: 8px;
            }
          }
          /* ---------------------------------------------------------------- */
          /* Value */
          /* ---------------------------------------------------------------- */
          &:nth-child(2) {
            font-family: $bold;
            padding-right: 0;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Cost Breakdown */
  /* ---------------------------------------------------------------- */
  > footer {
    background-color: $productPrimaryBg;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top: 2px solid $productPrimaryBorder;
    display: flex;
    flex-direction: column;
    padding: 15px 20px 20px;
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
      padding-bottom: 0;
      padding-top: 0;
    }
    @media (max-width: 767px) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 16px;
    }
    @media (max-width: 670px) {
      border: none;
      padding: 0 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Columns */
    /* ---------------------------------------------------------------- */
    > div {
      display: flex;
      flex-shrink: 0;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        display: block;
      }
      /* ---------------------------------------------------------------- */
      /* Breakdown */
      /* ---------------------------------------------------------------- */
      &:nth-child(1) {
        color: $productTitle;
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 15px;
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          margin: 0;
        }
        > table {
          border-collapse: collapse;
          font-family: $regular;
          width: 50%;
          @include user-select;
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            width: 100%;
          }
          tr {
            vertical-align: top;
            @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
              border-bottom: 2px solid $productBg;
            }
            > td {
              @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
                padding: 8px 12px 8px 0;
              }
              @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
                font-size: 13px;
                line-height: 17px;
                padding-right: 8px;
              }
              &:first-child {
                padding-right: 8px;
              }
              &:last-child {
                text-align: right;
                white-space: nowrap;
                width: 1%;
                @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
                  text-align: left;
                  white-space: normal;
                  width: 50%;
                }
              }
            }
          }
          /* ---------------------------------------------------------------- */
          /* Package */
          /* ---------------------------------------------------------------- */
          &:nth-child(1) {
            box-sizing: border-box;
            font-family: $bold;
            tr > td:last-child {
              padding-right: 48px;
              @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
                padding-right: 24px;
              }
              @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
                padding-right: 0;
              }
            }
          }
          &:only-child {
            margin-left: auto;
            tr {
              @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
                border: none;
              }
              > td:last-child {
                padding-right: 0;
              }
            }
          }
          /* ---------------------------------------------------------------- */
          /* Interest / Deposit  */
          /* ---------------------------------------------------------------- */
          &:nth-child(2) {
            /* ---------------------------------------------------------------- */
            /* Row  */
            /* ---------------------------------------------------------------- */
            tr {
              &:last-child {
                @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
                  border: none;
                }
              }
              > td:last-child {
                font-family: $bold;
                padding-left: 12px;
                @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Total Cost */
      /* ---------------------------------------------------------------- */
      &:nth-child(2) {
        align-items: center;
        background-color: $productPolicyInfoTotalBg;
        border-radius: 8px;
        flex-shrink: 0;
        padding: 16px;
        @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
          padding: 15px 16px;
        }
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          align-items: stretch;
          border-radius: 0;
          box-sizing: border-box;
          left: -20px;
          position: relative;
          width: calc(100% + 40px);
        }
        @media (max-width: 670px) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          left: -16px;
          width: calc(100% + 32px);
        }
        &:last-child {
          @media (max-width: 1240px) and (min-width: 768px) {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Row */
        /* ---------------------------------------------------------------- */
        > div {
          color: $productTitle;
          display: flex;
          flex-basis: 50%;
          flex-shrink: 0;
          font-family: $bold;
          max-width: 50%;
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            flex-basis: 100%;
            max-width: 100%;
          }
          > div {
            @include user-select;
            @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
              flex-basis: 50%;
              max-width: 50%;
            }
          }
          /* ---------------------------------------------------------------- */
          /* Discount */
          /* ---------------------------------------------------------------- */
          &:nth-child(1) {
            box-sizing: border-box;
            font-size: 16px;
            line-height: 20px;
            padding-right: 48px;
            @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
              font-size: 14px;
              line-height: 18px;
              padding-right: 24px;
            }
            @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
              font-size: 14px;
              line-height: 18px;
              padding-right: 0;
            }
            @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
              font-size: 13px;
              line-height: 17px;
            }
            > div:first-child {
              font-family: $semibold;
            }
            > div:last-child {
              margin-left: auto;
            }
          }
          /* ---------------------------------------------------------------- */
          /* Total */
          /* ---------------------------------------------------------------- */
          &:nth-child(2) {
            font-size: 20px;
            justify-content: flex-end;
            line-height: 24px;
            @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
              font-size: 17px;
              line-height: 21px;
            }
            @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
              margin-bottom: -1px;
            }
            @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
              font-size: 17px;
              line-height: 21px;
            }
            > div:nth-child(1) {
              margin-right: 12px;
              @include user-select;
              @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
                margin-right: 8px;
              }
              @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
                margin-right: 0;
              }
            }
            > div > span {
              font-size: 16px;
              line-height: 20px;
              margin-left: 8px;
              @include user-select;
              @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
                font-size: 13px;
                line-height: 17px;
              }
              @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
                font-size: 12px;
                line-height: 15px;
              }
              @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
                font-size: 11px;
                line-height: 14px;
              }
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Legal */
  /* ---------------------------------------------------------------- */
  small {
    color: $productLegal;
    font-family: $regular;
    font-size: 12px;
    margin: 9px 0 -3px;
    @include user-select;
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
      margin-bottom: 17px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Plan Saving */
  /* ---------------------------------------------------------------- */
  &.plan-saving {
    > footer > div:nth-child(2) > div:nth-child(1) {
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        margin: 0 0 9px;
      }
    }
  }
}
