@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Button */
/* ---------------------------------------------------------------- */
.button {
  align-items: center;
  background-color: $formButtonBg;
  border-color: $formButtonBorder;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  padding: 0 20px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  @include slow-transition($mob: true, $props: "border-color, background-color");
  &::before {
    border-color: $formButtonBorder;
    @include slow-transition($mob: true, $props: "border-color");
  }
  &::after {
    background-color: transparent;
    @include slow-transition($mob: true, $props: "background-color");
  }
  /* ---------------------------------------------------------------- */
  /* Focus */
  /* ---------------------------------------------------------------- */
  &:focus {
    background-color: $formButtonBgHover;
    border-color: $formButtonBorderHover;
    span {
      text-decoration: underline;
      text-decoration-color: $formButtonText;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Hover */
  /* ---------------------------------------------------------------- */
  :global(.platform-desktop) & {
    &:not(.selected):hover {
      background-color: $formButtonBgHover;
      border-color: $formButtonBorderHover;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button - Icon */
  /* ---------------------------------------------------------------- */
  svg {
    flex-shrink: 0;
    height: 42px;
    margin: 0 0 9px;
    width: 42px;
    &:only-child {
      margin: 0;
    }
    * {
      fill: $formButtonText;
      @include slow-transition($mob: true, $props: "fill");
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button - Text */
  /* ---------------------------------------------------------------- */
  span {
    color: $formButtonText;
    font-family: $medium;
    font-size: 16px;
    line-height: normal;
    pointer-events: none;
    white-space: nowrap;
    @include slow-transition($mob: true, $props: "color");
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    background-color: $formButtonBgInvalid;
    border-color: $formButtonBorderInvalid;
    &::before {
      border-color: $formButtonBorderInvalid;
    }
    svg * {
      fill: $formButtonTextInvalid;
    }
    span {
      color: $formButtonTextInvalid;
    }
    &:focus {
      span {
        text-decoration-color: $formButtonTextInvalid;
      }
    }
    :global(.platform-desktop) & {
      &:not(.selected):hover {
        background-color: $formButtonBgHoverInvalid;
        border-color: $formButtonBorderHoverInvalid;
      }
    }
    &.selected {
      background-color: $formButtonBgSelectedInvalid;
      border-color: $formButtonBorderSelectedInvalid;
      &::after {
        background-color: $formButtonBgSelectedInvalid;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Selected */
  /* ---------------------------------------------------------------- */
  &.selected {
    background-color: $formButtonBgSelected;
    border-color: $formButtonBorderSelected;
    &::after {
      background-color: $formButtonBgSelected;
    }
    svg * {
      fill: $formButtonTextSelected;
    }
    span {
      color: $formButtonTextSelected;
    }
    &:focus {
      span {
        text-decoration-color: $formButtonTextSelected;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  &.read-only {
    cursor: default;
    pointer-events: none;
    &:focus {
      background-color: $formButtonBg;
      border-color: $formButtonBorder;
      > span {
        text-decoration: none;
      }
    }
    &.invalid:focus {
      background-color: $formButtonBgInvalid;
      border-color: $formButtonBorderInvalid;
    }
    &.selected:focus {
      background-color: $formButtonBgSelected;
      border-color: $formButtonBorderSelected;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    background-color: $formButtonBgDisabled;
    border-color: $formButtonBorderDisabled;
    pointer-events: none;
    &::before {
      border-color: $formButtonBorderDisabled;
    }
    &:focus {
      background-color: $formButtonBgDisabled;
      border-color: $formButtonBorderDisabled;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $formButtonBgDisabled;
        border-color: $formButtonBorderDisabled;
      }
    }
    svg * {
      fill: $formButtonTextDisabled;
    }
    span {
      color: $formButtonTextDisabled;
    }
  }
}
