@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Payment - legal */
/* ---------------------------------------------------------------- */
.payment-legal {
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 0 -14px;
  padding: 12px 0;
  position: relative;
  @media (max-width: 1024px) {
    border-radius: 0;
    border-width: 2px 0;
    box-sizing: border-box;
    left: -24px;
    padding: 12px 24px;
    width: calc(100% + 48px);
  }
  @media (max-width: 767px) {
    left: -16px;
    margin-bottom: -8px;
    padding: 12px 16px;
    width: calc(100% + 32px);
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  > p {
    font-family: $regular;
    font-size: 14px;
    line-height: 17px;
    margin: 0 0 15px;
    @include user-select;
    &:first-child {
      margin-top: -3px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Checkbox */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Text Container */
    /* ---------------------------------------------------------------- */
    > div:nth-child(2) {
      align-items: center;
      display: flex;
      flex: 1;
      /* ---------------------------------------------------------------- */
      /* Text Container */
      /* ---------------------------------------------------------------- */
      > div {
        display: block;
        font-family: $bold;
        font-size: 16px;
        line-height: 20px;
        padding-left: 12px;
        position: relative;
        @media (max-width: 767px) {
          font-size: 15px;
          line-height: 19px;
        }
        /* ---------------------------------------------------------------- */
        /* Links */
        /* ---------------------------------------------------------------- */
        > div[role="button"],
        > button {
          color: $paymentLegalLink;
          cursor: pointer;
          display: inline-block;
          font-size: 16px;
          line-height: 20px;
          @include fast-transition($props: "color");
          @media (max-width: 767px) {
            font-size: 15px;
            line-height: 19px;
          }
          span {
            font-family: $bold;
          }
          &:focus {
            color: darken($paymentLegalLink, 10%);
            span {
              text-decoration: underline;
              text-decoration-color: darken($paymentLegalLink, 10%);
            }
          }
          :global(.platform-desktop) & {
            &:hover {
              color: darken($paymentLegalLink, 10%);
            }
          }
          :global(.platform-desktop) &,
          :global(.platform-mobile) & {
            &:active {
              color: darken($paymentLegalLink, 20%);
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    background-color: $questionInvalidBg;
    border-color: $questionInvalidBorder;
    padding: 12px;
    @media (max-width: 1024px) {
      padding: 12px 24px;
    }
    @media (max-width: 767px) {
      padding: 12px 16px;
    }
    &:last-child {
      margin: 0;
      @media (max-width: 767px) {
        margin-bottom: -4px;
      }
    }
  }
}
