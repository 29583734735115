@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Loading Dots */
/* ---------------------------------------------------------------- */
.loadingDots {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  @include no-user-select;
  /* ---------------------------------------------------------------- */
  /* Dots */
  /* ---------------------------------------------------------------- */
  > div {
    animation: blueOrangeLoading 8s cubic-bezier((0.76, 0, 0.24, 1)) infinite;
    border-radius: 8px;
    display: inline-block;
    height: 8px;
    margin: 0 2px;
    width: 8px;
    will-change: background-color, height;
  }
}
/* ---------------------------------------------------------------- */
/* Stagger Animation */
/* ---------------------------------------------------------------- */
@for $i from 1 through 5 {
  .loadingDots > div:nth-child(#{$i}) {
    animation-delay: #{$i * 0.2}s;
  }
}
/* ---------------------------------------------------------------- */
/* Animation */
/* ---------------------------------------------------------------- */
@keyframes blueLoading {
  0%,
  100% {
    background-color: $primaryLight;
    height: 8px;
  }
  20% {
    height: 32px;
  }
  40% {
    height: 8px;
  }
  50% {
    background-color: $primary;
    height: 8px;
  }
  70% {
    height: 32px;
  }
  90% {
    height: 8px;
  }
}
@keyframes orangeLoading {
  0%,
  100% {
    background-color: $secondaryLight;
    height: 8px;
  }
  20% {
    height: 32px;
  }
  40% {
    height: 8px;
  }
  50% {
    background-color: $secondary;
    height: 8px;
  }
  70% {
    height: 32px;
  }
  90% {
    height: 8px;
  }
}
@keyframes blueOrangeLoading {
  0%,
  100% {
    background-color: $primaryLight;
    height: 8px;
  }
  8.33% {
    height: 32px;
  }
  16.66% {
    height: 8px;
  }
  25% {
    background-color: $primary;
    height: 8px;
  }
  33.33% {
    height: 32px;
  }
  41.66% {
    height: 8px;
  }
  50% {
    background-color: $secondary;
    height: 8px;
  }
  58.33% {
    height: 32px;
  }
  66.66% {
    height: 8px;
  }
  75% {
    background-color: $secondaryLight;
    height: 8px;
  }
  83.33% {
    height: 32px;
  }
  91.66% {
    height: 8px;
  }
}
