@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Option */
/* ---------------------------------------------------------------- */
.option {
  align-items: center;
  background-color: $productBg;
  border: 2px solid $productOverviewButtonBorder;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  margin: 0 -12px 12px;
  min-height: 57px;
  padding: 8px 10px;
  @media (max-width: 1240px) and (min-width: 768px), (max-width: 550px) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    padding-right: 66px;
    position: relative;
  }
  @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
    margin-top: -1px;
  }
  + .option {
    @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
      margin-top: 0;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  > div[role="button"],
  > button {
    align-items: flex-start;
    cursor: pointer;
    display: inline-flex;
    font-family: $bold;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
      font-size: 14px;
      line-height: 18px;
    }
    &:nth-child(2) {
      align-items: center;
      flex-shrink: 0;
      margin-left: auto;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        margin: 4px 0 -1px 32px;
      }
      @media (max-width: 670px) and (min-width: 551px) {
        margin: 0 0 0 auto;
      }
      @media (max-width: 550px) and (min-width: 415px) {
        margin: 4px 0 -1px 32px;
      }
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        margin-left: 24px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        margin-left: 21px;
      }
      &:focus {
        > span:nth-child(2) {
          color: $hyperlinkHover;
          text-decoration: underline;
          text-decoration-color: $hyperlinkHover;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          > span:nth-child(2) {
            color: $hyperlinkHover;
          }
        }
      }
      > span {
        /* ---------------------------------------------------------------- */
        /* Toggle Button */
        /* ---------------------------------------------------------------- */
        &:nth-child(1) {
          align-self: flex-start;
          background-color: $productOverviewButtonBg;
          border-radius: 13.5px;
          flex-shrink: 0;
          height: 26px;
          margin-left: 12px;
          order: 2;
          width: 44px;
          @include slow-transition($mob: true, $props: "background-color");
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 550px) {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
          > span {
            background-color: $productBg;
            border-radius: 50%;
            display: block;
            height: 22px;
            left: 2px;
            position: relative;
            top: 2px;
            transform: translate3d(0);
            width: 22px;
            will-change: transform;
            @include slow-transition($mob: true, $props: "transform");
            > svg {
              height: 11px;
              left: 50%;
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 11px;
              &:nth-child(2) {
                opacity: 0;
              }
            }
          }
        }
        /* ---------------------------------------------------------------- */
        /* Toggle Label */
        /* ---------------------------------------------------------------- */
        &:nth-child(2) {
          color: $productTitle;
          display: block;
          flex-shrink: 0;
          order: 1;
          text-align: right;
          text-decoration-color: $productTitle;
          @include fast-transition($mob: true, $props: "color");
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 550px) {
            align-items: flex-end;
            display: flex;
            margin-bottom: 1px;
          }
          /* ---------------------------------------------------------------- */
          /* Selected Payment Cost */
          /* ---------------------------------------------------------------- */
          > span {
            display: block;
            @media (max-width: 1240px) and (min-width: 768px), (max-width: 550px) {
              display: flex;
              &::after {
                margin-right: 10px;
              }
            }
          }
          /* ---------------------------------------------------------------- */
          /* Unselected Payment Cost */
          /* ---------------------------------------------------------------- */
          > small {
            display: table;
            font-size: 12px;
            line-height: 16px;
            margin: 1px 0 0;
            @media (max-width: 1240px) and (min-width: 768px), (max-width: 550px) {
              bottom: 0;
              display: flex;
              margin: 0;
              position: relative;
              &::before {
                content: "(";
                margin-left: 10px;
              }
              &::after {
                content: ")";
              }
            }
          }
        }
      }
    }
    &:focus {
      color: $hyperlinkHover;
      text-decoration: underline;
      text-decoration-color: $hyperlinkHover;
      span {
        text-decoration: none;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $hyperlinkHover;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        color: $hyperlinkActive;
      }
    }
    &:nth-child(1) {
      @include user-select;
      > span {
        padding-right: 12px;
        text-align: left;
        @include fast-transition($mob: true, $props: "color");
      }
      > svg {
        flex-shrink: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Info Icon */
    /* ---------------------------------------------------------------- */
    > svg {
      height: 20px;
      margin-right: 11px;
      width: 20px;
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        height: 16px;
        margin-right: 7px;
        position: relative;
        top: 1px;
        width: 16px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        height: 13px;
        margin-right: 8px;
        top: 3px;
        width: 13px;
      }
      * {
        fill: $productOverviewInfo;
      }
    }
  } /* ---------------------------------------------------------------- */
  /* Selected */
  /* ---------------------------------------------------------------- */
  &.selected {
    background-color: $productOverviewButtonBgSelected;
    border-color: $productOverviewButtonSelected;
    > div[role="button"],
    > button {
      &:nth-child(2) {
        > span:nth-child(1) {
          background-color: $productOverviewButtonSelected;
          > span {
            transform: translate3d(18px, 0, 0);
            > svg {
              &:nth-child(1) {
                opacity: 0;
              }
              &:nth-child(2) {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    background-color: $productOverviewButtonBgDisabled;
    border-color: $productOverviewButtonBorderDisabled;
    > div[role="button"],
    > button {
      > svg * {
        fill: $productOverviewButtonTextDisabled;
      }
      span {
        color: $productOverviewButtonTextDisabled;
      }
      &:nth-child(1) {
        &:focus {
          text-decoration-color: darken($productOverviewButtonTextDisabled, 10%);
          span {
            color: darken($productOverviewButtonTextDisabled, 10%);
            text-decoration-color: darken($productOverviewButtonTextDisabled, 10%);
          }
        }
        :global(.platform-desktop) & {
          &:hover {
            span {
              color: darken($productOverviewButtonTextDisabled, 10%);
            }
          }
        }
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          &:active {
            span {
              color: darken($productOverviewButtonTextDisabled, 20%);
            }
          }
        }
      }
      &:nth-child(2) {
        cursor: default;
        pointer-events: none;
        > span {
          &:nth-child(1) {
            background-color: $productOverviewButtonTextDisabled;
            > span {
              transform: translate3d(18px, 0, 0);
              > svg {
                &:nth-child(1) {
                  opacity: 0;
                }
                &:nth-child(2) {
                  opacity: 1;
                }
              }
            }
            > span > svg * {
              fill: $productOverviewButtonTextDisabled;
            }
          }
          &:nth-child(2) {
            color: $productOverviewButtonTextDisabled;
          }
        }
        :focus {
          > span:nth-child(2) {
            color: $productOverviewButtonTextDisabled;
            text-decoration: none;
          }
        }
        :global(.platform-desktop) & {
          &:hover {
            > span:nth-child(2) {
              color: $productOverviewButtonTextDisabled;
            }
          }
        }
      }
    }
  }
}
