@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Address Search Button */
/* ---------------------------------------------------------------- */
.address-search-button {
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 12px;
  margin: 0 0 12px;
  padding: 12px 0;
  position: relative;
  @media (max-width: 1024px) {
    border-radius: 0;
    border-width: 2px 0;
    box-sizing: border-box;
    left: -24px;
    padding: 12px 24px;
    width: calc(100% + 48px);
  }
  @media (max-width: 767px) {
    left: -16px;
    padding: 12px 16px;
    width: calc(100% + 32px);
  }
  &:last-child {
    margin: 0 0 -14px;
    @media (max-width: 767px) {
      margin-bottom: -8px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  > div {
    box-sizing: border-box;
    flex-basis: 412px;
    flex-shrink: 0;
    margin-left: 314px;
    max-width: 412px;
    @media (max-width: 1024px) {
      flex-basis: auto;
      margin-left: 0;
      max-width: 100%;
    }
    > div[role="button"],
    > button {
      height: 50px;
      margin-bottom: 0;
      width: 100%;
    }
    > p {
      font-size: 16px;
      line-height: 20px;
      margin: 20px 0 -5px 0;
      padding: 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Loading */
  /* ---------------------------------------------------------------- */
  &.loading {
    pointer-events: none;
    > span {
      align-items: center;
      background-color: $vehiclesLoadingIconBg;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      height: 32px;
      justify-content: center;
      left: 766px;
      position: absolute;
      top: 12px;
      width: 32px;
      @media (max-width: 1060px) {
        left: 738px;
      }
      @media (max-width: 1024px) {
        height: 28px;
        left: auto;
        margin: 0 0 6px auto;
        position: relative;
        top: auto;
        width: 28px;
      }
      > svg {
        height: 28px;
        width: 28px;
        @media (max-width: 1024px) {
          height: 24px;
          width: 24px;
        }
        * {
          &:nth-child(1) {
            stroke: $vehiclesLoadingIcon;
          }
          &:nth-child(2) {
            fill: $vehiclesLoadingIcon;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    background-color: $questionInvalidBg;
    border-color: $questionInvalidBorder;
  }
}
