@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Defaqto */
/* ---------------------------------------------------------------- */
.defaqto {
  background-image: url("/images/defaqto.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  display: block;
  font-size: 0;
  line-height: 0;
  margin-right: 24px;
  overflow: hidden;
  width: 96px;
  &::before {
    content: "";
    display: block;
    padding-top: calc(calc(380px / 600px) * 100%);
  }
}
