@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Quote Wrapper */
/* ---------------------------------------------------------------- */
.quote-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  > header {
    display: flex;
    margin: 0 0 15px;
    width: 100%;
    @media (max-width: 767px) {
      align-items: center;
      height: 28px;
      margin: 0;
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    > div[role="button"],
    > button {
      align-items: center;
      color: $productText;
      cursor: pointer;
      display: inline-flex;
      font-family: $bold;
      font-size: 16px;
      line-height: 20px;
      position: relative;
      @include fast-transition($props: "color");
      @media (max-width: 767px) {
        display: none;
      }
      &::after {
        background-color: $productText;
        bottom: 0;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%;
        @include fast-transition($props: "background-color");
      }
      > svg {
        height: 11px;
        margin: 0 8px 0 0;
        width: 17px;
        * {
          fill: $productText;
          @include fast-transition($props: "fill");
        }
      }
      &:focus {
        color: darken($productText, 10%);
        &::after {
          background-color: darken($productText, 10%);
        }
        > svg * {
          fill: darken($productText, 10%);
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          color: darken($productText, 10%);
          &::after {
            background-color: darken($productText, 10%);
          }
          > svg * {
            fill: darken($productText, 10%);
          }
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          color: darken($productText, 20%);
          &::after {
            background-color: darken($productText, 20%);
          }
          > svg * {
            fill: darken($productText, 20%);
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Web Reference text */
    /* ---------------------------------------------------------------- */
    > p {
      color: $productText;
      font-family: $bold;
      line-height: 20px;
      margin-left: auto;
      @include user-select;
      @media (max-width: 767px) {
        margin-right: auto;
      }
      > span {
        @include user-select;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Wrapper */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
}
