@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modal Info */
/* ---------------------------------------------------------------- */
.modal-info {
  background-color: $modalBg;
  border-radius: 12px;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  justify-content: center;
  max-height: calc(100% - 64px);
  max-width: calc(720px - 64px);
  min-height: 164px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  @media (max-width: 1024px) {
    max-height: calc(100% - 48px);
    max-width: calc(720px - 48px);
    min-height: 149px;
  }
  @media (max-width: 767px) {
    max-height: calc(100% - 32px);
    max-width: 100%;
    min-height: 146px;
    width: calc(100% - 32px);
  }
  :global(.MuiDialog-container) & {
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);
    margin: 32px;
    @media (max-width: 1024px) {
      margin: 24px;
    }
    @media (max-width: 767px) {
      margin: 16px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  > header {
    align-items: center;
    background-color: $modalHeaderBg;
    border-bottom: 1px solid $modalHeaderBorder;
    display: flex;
    flex-shrink: 0;
    padding: 20px 24px;
    @media (max-width: 1024px) {
      padding: 16px 20px;
    }
    @media (max-width: 767px) {
      padding: 12px 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Header - Title */
    /* ---------------------------------------------------------------- */
    > h2 {
      color: $title;
      font-family: $bold;
      font-size: 24px;
      line-height: 30px;
      margin: -7px 0 -8px;
      padding-right: 24px;
      width: 100%;
      @media (max-width: 1024px) {
        font-size: 21px;
        line-height: 27px;
        padding-right: 20px;
      }
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
        padding-right: 16px;
      }
      &:only-child {
        padding: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Header - Button */
    /* ---------------------------------------------------------------- */
    > div[role="button"],
    > button {
      cursor: pointer;
      height: 18px;
      margin-bottom: -2px;
      margin-top: -1px;
      overflow: visible;
      position: relative;
      width: 18px;
      &::before {
        border: 1px solid transparent;
        border-radius: 50%;
        box-sizing: border-box;
        content: "";
        height: calc(100% + 16px);
        left: -8px;
        pointer-events: none;
        position: absolute;
        top: -8px;
        width: calc(100% + 16px);
        @include fast-transition($props: "border");
      }
      &:focus {
        &::before {
          border: 1px solid $modalCloseIconHover;
        }
        svg * {
          fill: $modalCloseIconHover;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          svg * {
            fill: $modalCloseIconHover;
          }
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          svg * {
            fill: $modalCloseIconActive;
          }
        }
      }
      svg {
        height: 18px;
        width: 18px;
        * {
          fill: $modalCloseIcon;
          @include fast-transition($props: "fill");
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Scroll Container */
  /* ---------------------------------------------------------------- */
  > div {
    box-sizing: border-box;
    margin: 20px 0 24px 24px;
    max-height: 480px;
    overflow-y: auto;
    padding-right: 24px;
    position: relative;
    scrollbar-color: $modalScrollBarThumb $modalScrollBar;
    scrollbar-width: thin;
    @media (max-width: 1024px) {
      margin: 20px 0 20px 20px;
      padding-right: 20px;
    }
    @media (max-width: 767px) {
      margin: 16px 0 16px 16px;
      padding-right: 16px;
    }
    :global(.platform-windows) & {
      padding-right: 17px;
      width: calc(100% - 25px);
      @media (max-width: 1024px) {
        padding-right: 13px;
        width: calc(100% - 21px);
      }
      @media (max-width: 767px) {
        padding-right: 9px;
        width: calc(100% - 17px);
      }
    }
    :global(.browser-firefox.platform-windows) & {
      width: calc(100% - 24px);
      @media (max-width: 1024px) {
        width: calc(100% - 20px);
      }
      @media (max-width: 767px) {
        width: calc(100% - 16px);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Scroll Bar - IE */
    /* ---------------------------------------------------------------- */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      scrollbar-3dlight-color: $modalScrollBarThumb;
      scrollbar-arrow-color: $modalScrollBarThumb;
      scrollbar-base-color: $modalScrollBar;
      scrollbar-face-color: $modalScrollBarThumb;
      scrollbar-highlight-color: $modalScrollBarThumb;
      scrollbar-shadow-color: $modalScrollBarThumb;
      scrollbar-track-color: $modalScrollBar;
    }
    /* ---------------------------------------------------------------- */
    /* Scroll Bar - Webkit / Android / Samsung */
    /* ---------------------------------------------------------------- */
    :global(.platform-windows) &,
    :global(.platform-mobile) & {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: $modalScrollBar;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $modalScrollBarThumb;
        border-radius: 4px;
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Content */
    /* ---------------------------------------------------------------- */
    > * {
      @include user-select;
      &:first-child {
        margin-top: -3px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    h1,
    h2 {
      color: $title;
      font-size: 20px;
      line-height: 24px;
      margin: 0 0 15px;
      @media (max-width: 1024px) {
        font-size: 18px;
        line-height: 22px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    h3 {
      color: $title;
      font-family: $bold;
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 15px;
      @media (max-width: 1024px) {
        font-size: 15px;
        line-height: 19px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    strong {
      font-family: $bold;
    }
    p,
    li,
    button {
      font-size: 16px;
      line-height: 20px;
      @media (max-width: 1024px) {
        font-size: 15px;
        line-height: 19px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    p,
    ul,
    ol {
      margin: 0 0 15px;
    }
    ul {
      li {
        padding-left: 16px;
        position: relative;
        &::before {
          background-color: $modalListBullet;
          border-radius: 50%;
          content: "";
          display: block;
          height: 8px;
          left: 0;
          position: absolute;
          top: 6px;
          width: 8px;
        }
      }
    }
    li {
      margin: 0 0 11px;
      &:last-child {
        margin: 0;
      }
    }
    a,
    button,
    div[role="button"] {
      color: $hyperlink;
      @include fast-transition($mob: false, $props: color);
      &:focus {
        color: $hyperlinkHover;
        text-decoration: underline;
        text-decoration-color: $hyperlinkHover;
      }
      :global(.platform-desktop) & {
        &:hover {
          color: $hyperlinkHover;
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          color: $hyperlinkActive;
        }
      }
    }
    button,
    div[role="button"] {
      cursor: pointer;
      display: inline;
      font-family: $bold;
    }
    /* ---------------------------------------------------------------- */
    /* DD Logo */
    /* ---------------------------------------------------------------- */
    > svg[data-svg="direct-debit"] {
      float: right;
      height: 28px;
      margin-left: 12px;
      margin-top: 0;
      position: relative;
      width: 87px;
      @media (max-width: 767px) {
        height: 25px;
        width: 78px;
      }
      & + * {
        margin-top: -3px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Footer */
  /* ---------------------------------------------------------------- */
  > footer {
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    padding: 0 24px 24px;
    width: 100%;
    @media (max-width: 1024px) {
      padding: 0 20px 20px;
    }
    @media (max-width: 767px) {
      padding: 0 16px 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Footer - Button */
    /* ---------------------------------------------------------------- */
    > div[role="button"],
    > button {
      flex-shrink: 0;
      margin: 0 0 0 auto;
      min-width: 150px;
      @media (max-width: 767px) {
        min-width: 142px;
        padding: 0 20px;
      }
      @media (max-width: 440px) {
        flex-basis: calc(50% - 4px);
        max-width: calc(50% - 4px);
        min-width: auto;
        padding: 0 12px;
      }
      span {
        @media (max-width: 1024px) {
          font-size: 15px;
        }
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
}
