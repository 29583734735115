@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Documents Item */
/* ---------------------------------------------------------------- */
.item {
  flex-basis: calc(50% - 12px);
  margin: 0 6px 8px;
  min-width: calc(50% - 12px);
  @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
    margin-bottom: 8px;
    min-width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Link */
  /* ---------------------------------------------------------------- */
  > a {
    align-items: center;
    background-color: $productPolicyLinkBg;
    border-color: $productPolicyLinkBorder;
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-start;
    padding: 9px 12px;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    width: 100%;
    @include slow-transition($mob: true, $props: "border-color, background-color");
    @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
      padding-bottom: 8px;
      padding-left: 8px;
      padding-right: 8px;
    }
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
      flex-direction: row;
      justify-content: flex-start;
      padding: 8px 16px;
    }
    &::before {
      content: none;
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      background-color: $productPolicyLinkBgHover;
      border-color: $productPolicyLinkBorderHover;
      span {
        text-decoration: underline;
        text-decoration-color: $productPolicyLinkText;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        background-color: $productPolicyLinkBgHover;
        border-color: $productPolicyLinkBorderHover;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Active */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        background-color: $productPolicyLinkBgActive;
        border-color: $productPolicyLinkBorderActive;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    span:last-child {
      color: $productPolicyLinkText;
      font-family: $medium;
      font-size: 16px;
      line-height: 20px;
      pointer-events: none;
      text-align: left;
      white-space: normal;
      @include slow-transition($mob: true, $props: "color");
      @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
        font-size: 15px;
        line-height: 19px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      flex-shrink: 0;
      height: 30px;
      margin: 0 10px 0 0;
      width: 30px;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        height: 30px;
        margin: 0 16px 0 0;
        width: 30px;
      }
      * {
        fill: $productPolicyLinkText;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Adobe Link */
  /* ---------------------------------------------------------------- */
  &:last-child {
    > a {
      background-color: $productAdobeLinkBg;
      border-color: $productAdobeLinkBorder;
      height: 100%;
      min-height: 50px;
      padding: 0 8px 0 0;
      @include fast-transition($props: "background-color");
      /* ---------------------------------------------------------------- */
      /* Focus */
      /* ---------------------------------------------------------------- */
      &:focus {
        background-color: rgba($productAdobeLinkBorder, 0.15);
        border-color: $productAdobeLinkBorder;
        span {
          text-decoration-color: $text;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Hover */
      /* ---------------------------------------------------------------- */
      :global(.platform-desktop) & {
        &:hover {
          background-color: rgba($productAdobeLinkBorder, 0.15);
          border-color: $productAdobeLinkBorder;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Active */
      /* ---------------------------------------------------------------- */
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          background-color: rgba($productAdobeLinkBorder, 0.3);
          border-color: $productAdobeLinkBorder;
        }
      }
      span {
        color: $text;
        @media (max-width: 321px) {
          white-space: nowrap;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Icon Background */
      /* ---------------------------------------------------------------- */
      > .adobe {
        align-items: center;
        background-color: $productAdobeLinkBorder;
        display: flex;
        height: 100%;
        justify-content: center;
        margin: 0 12px 0 0;
        overflow: hidden;
        position: relative;
        width: 51px;
        @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
          margin: 0 10px 0 0;
        }
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          margin: 0 11px 0 0;
        }
        @media (max-width: 321px) {
          margin: 0 8px 0 0;
        }
        &::before {
          background: $productAdobeLinkLogoBg;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
          content: "";
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: calc(100% - 2px);
          z-index: 0;
        }
        svg {
          height: 36px;
          margin: 0;
          position: relative;
          width: 35px;
          z-index: 1;
          * {
            fill: $productAdobeLinkLogoIcon;
          }
        }
      }
    }
  }
}
