@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modal Wrapper */
/* ---------------------------------------------------------------- */
.modal-wrapper {
  display: flex;
  margin: 0 0 30px;
  @media (max-width: 767px) {
    margin-left: 16px;
    margin-right: 16px;
  }
  > div {
    cursor: pointer;
    display: table;
    > * {
      max-width: 100%;
      width: 100%;
    }
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  &-title {
    color: white;
    margin: 25px 0 20px;
    h1 + & {
      margin-top: 0;
    }
    @media (max-width: 767px) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}
