@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Contact */
/* ---------------------------------------------------------------- */
.policy-contact {
  background-color: $productBg;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  margin: 0 0 24px;
  @media (max-width: 1240px) {
    margin: 0 0 16px;
  }
  @media (max-width: 1024px) {
    margin: 0 0 12px;
  }
  @media (max-width: 767px) {
    border-radius: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > header {
    margin: 0 0 26px;
    padding: 24px 20px 0;
    @media (max-width: 1240px) {
      margin: 0 0 20px;
    }
    @media (max-width: 1024px) {
      margin: 0 0 16px;
    }
    @media (max-width: 767px) {
      margin: 0 0 20px;
      padding: 24px 16px 0;
    }
    > h2 {
      color: $title;
      font-family: $bold;
      font-size: 28px;
      line-height: 32px;
      position: relative;
      text-transform: capitalize;
      @include no-user-select;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        font-size: 24px;
        line-height: 28px;
      }
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 23px;
        line-height: 27px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 22px;
        line-height: 26px;
      }
      &:first-child {
        margin-top: -5px;
      }
      &::after {
        background-color: $titleBorder;
        content: "";
        display: block;
        height: 2px;
        margin: 11px 0 0;
        position: relative;
        width: 100%;
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          margin: 6px 0 0;
        }
      }
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    @media (max-width: 767px) {
      padding: 0 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    > p {
      margin: 0 0 11px;
      @include user-select;
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 15px;
        line-height: 19px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    > div {
      /* ---------------------------------------------------------------- */
      /* Buttons */
      /* ---------------------------------------------------------------- */
      > .buttons {
        padding: 4px 0 30px;
        width: calc(100% + 12px);
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          padding: 4px 0 28px;
          width: 100%;
        }
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          padding-top: 5px;
        }
        > li {
          flex-basis: calc(20% - 12px);
          max-width: calc(20% - 12px);
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            margin-bottom: 8px;
            min-width: 100%;
          }
          > div[role="button"],
          > button {
            @media (max-width: 1324px) and (min-width: 768px), (max-width: 710px) and (min-width: 671px) {
              padding-bottom: 8px;
              padding-left: 8px;
              padding-right: 8px;
            }
            @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
              flex-direction: row;
              padding: 8px 16px;
            }
            span {
              @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
                font-size: 16px;
                justify-content: flex-start;
              }
            }
            svg {
              @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
                height: 30px;
                margin: 0 16px 0 0;
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
}
