@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Product Footer */
/* ---------------------------------------------------------------- */
.product-footer {
  background-color: $productBg;
  border-top: 2px solid $productShoppingCartBorder;
  bottom: 0;
  box-shadow: 0 -1px 6px rgba($text, 0.6);
  left: 0;
  min-width: 320px;
  padding: 17px 0 20px;
  position: fixed;
  width: 100%;
  z-index: 99;
  @media (max-width: 1240px) {
    padding: 13px 0 16px;
  }
  @media (max-width: 767px) {
    height: auto;
    padding: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Container */
  /* ---------------------------------------------------------------- */
  > div {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    max-width: 1360px;
    padding: 0 32px;
    @media (max-width: 1024px) {
      padding: 0 24px;
    }
    @media (max-width: 767px) {
      padding: 0 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    > p:nth-child(1) {
      font-family: $bold;
      font-size: 14px;
      line-height: 17px;
      margin: 0 0 4px;
      @media (max-width: 767px) {
        display: none;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Button Row */
    /* ---------------------------------------------------------------- */
    > div {
      display: flex;
      @media (max-width: 767px) {
        align-items: center;
        flex-direction: column;
      }
      > div {
        align-items: center;
        display: flex;
        @media (max-width: 767px) {
          width: 100%;
        }
        /* ---------------------------------------------------------------- */
        /* Radio Buttons */
        /* ---------------------------------------------------------------- */
        &:nth-child(1) {
          @media (max-width: 767px) {
            height: 70px;
          }
          > div {
            align-items: center;
            display: flex;
            /* ---------------------------------------------------------------- */
            /* Desktop Button */
            /* ---------------------------------------------------------------- */
            &:nth-child(1) {
              > div[role="button"],
              > button {
                @media (max-width: 1024px) {
                  font-size: 15px;
                  padding: 0 16px 0 8px;
                }
                @media (max-width: 940px) {
                  font-size: 14px;
                  padding: 0 12px 0 8px;
                }
              }
              @media (max-width: 767px) {
                display: none;
              }
            }
            /* ---------------------------------------------------------------- */
            /* Mobile Button */
            /* ---------------------------------------------------------------- */
            &:nth-child(2):not([role="button"]) {
              display: none;
              @media (max-width: 767px) {
                display: flex;
                justify-content: center;
                width: 100%;
              }
            }
            > div[role="button"],
            > button {
              align-items: center;
              background-color: $productButtonBg;
              border: 2px solid $productButtonBorder;
              border-radius: 6px;
              color: $productTitle;
              cursor: pointer;
              display: inline-flex;
              flex-shrink: 0;
              font-family: $bold;
              font-size: 16px;
              height: 40px;
              line-height: 20px;
              margin: 0 12px 0 0;
              padding: 0 20px 0 12px;
              position: relative;
              @include slow-transition($mob: true, $props: "border-color, background-color");
              @media (max-width: 767px) {
                padding: 0 12px 0 8px;
              }
              @media (max-width: 440px) {
                font-size: 15px;
                justify-content: center;
                margin: 0 8px 0 0;
                padding: 0 4px;
                width: calc(50% - 4px);
              }
              &:last-child {
                margin: 0;
              }
              > svg {
                height: 20px;
                margin: 0 12px 0 -32px;
                opacity: 0;
                width: 20px;
                @include slow-transition($mob: true, $props: "opacity");
                @media (max-width: 767px) {
                  margin: 0 8px 0 -28px;
                }
                @media (max-width: 340px) {
                  height: 18px;
                  margin: 0 6px 0 -24px;
                  width: 18px;
                }
                * {
                  fill: $productButtonSelectedText;
                }
              }
              > span {
                white-space: nowrap;
                @include slow-transition($mob: true, $props: "color");
              }
              &::before {
                background-color: $productBg;
                border: 2px solid $productButtonBorder;
                border-radius: 50%;
                box-sizing: border-box;
                content: "";
                flex-shrink: 0;
                height: 20px;
                margin: 0 12px 0 0;
                position: relative;
                width: 20px;
                @media (max-width: 767px) {
                  margin: 0 8px 0 0;
                }
                @media (max-width: 340px) {
                  height: 18px;
                  margin: 0 6px 0 0;
                  width: 18px;
                }
              }
              &:focus {
                background-color: $productButtonBgHover;
                border-color: $productButtonBorderHover;
                text-decoration: underline;
                text-decoration-color: $productTitle;
              }
              :global(.platform-desktop) & {
                &:hover {
                  background-color: $productButtonBgHover;
                  border-color: $productButtonBorderHover;
                }
              }
              /* ---------------------------------------------------------------- */
              /* Selected */
              /* ---------------------------------------------------------------- */
              &.selected {
                background-color: $productButtonSelectedBg;
                border-color: $productButtonSelectedBorder;
                pointer-events: none;
                &::before {
                  display: none;
                }
                span {
                  color: $productButtonSelectedText;
                }
                svg {
                  margin: 0 12px 0 0;
                  opacity: 1;
                  @media (max-width: 767px) {
                    margin: 0 8px 0 0;
                  }
                  @media (max-width: 340px) {
                    margin: 0 6px 0 0;
                  }
                }
                &:focus {
                  background-color: $productButtonSelectedBg;
                  border-color: $productButtonSelectedBorder;
                  text-decoration-color: $productButtonSelectedBorder;
                }
                :global(.platform-desktop) & {
                  &:hover {
                    background-color: $productButtonSelectedBg;
                    border-color: $productButtonSelectedBorder;
                  }
                }
              }
            }
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Price & Confirm Button */
      /* ---------------------------------------------------------------- */
      .confirm {
        height: 54px;
        margin-left: auto;
        @media (max-width: 767px) {
          background-color: $productPrimaryBg;
          height: 72px;
          justify-content: center;
          margin: 0 -16px;
          width: calc(100% + 32px);
        }
        @media (max-width: 440px) {
          box-sizing: border-box;
          padding: 0 16px;
        }
        > div:not([role="button"]) {
          font-family: $bold;
          margin: 0 15px 0 0;
          @media (max-width: 1024px) {
            margin: 0 11px 0 0;
          }
          @media (max-width: 767px) {
            margin: 0 15px 0 0;
          }
          @media (max-width: 440px) {
            align-items: center;
            display: flex;
            margin: 0 8px 0 0;
            width: calc(50% - 4px);
          }
          > strong {
            font-size: 38px;
            line-height: 46px;
            @media (max-width: 1240px) {
              font-size: 30px;
              line-height: 38px;
            }
            @media (max-width: 1024px) {
              font-size: 28px;
              line-height: 36px;
            }
            @media (max-width: 940px) {
              font-size: 24px;
              line-height: 32px;
            }
            @media (max-width: 767px) {
              font-size: 22px;
              line-height: 27px;
            }
            @media (max-width: 365px) {
              font-size: 18px;
              line-height: 23px;
            }
          }
          > span {
            font-size: 30px;
            line-height: 37px;
            margin: 0 0 0 8px;
            @media (max-width: 1240px) {
              font-size: 22px;
              line-height: 29px;
            }
            @media (max-width: 1024px) {
              font-size: 20px;
              line-height: 27px;
            }
            @media (max-width: 940px) {
              font-size: 16px;
              line-height: 28px;
            }
            @media (max-width: 767px) {
              font-size: 14px;
              line-height: 17px;
            }
            @media (max-width: 365px) {
              font-size: 12px;
              line-height: 15px;
            }
          }
        }
        > div[role="button"],
        > button {
          /* ---------------------------------------------------------------- */
          /* Desktop */
          /* ---------------------------------------------------------------- */
          &:nth-child(2) {
            @media (max-width: 1024px) {
              padding: 0 16px;
            }
            @media (max-width: 940px) {
              padding: 0 12px;
            }
            @media (max-width: 767px) {
              padding: 0 24px;
            }
            @media (max-width: 440px) {
              width: calc(50% - 4px);
            }
            > span {
              @media (max-width: 1024px) {
                font-size: 15px;
              }
              @media (max-width: 940px) {
                font-size: 14px;
              }
              @media (max-width: 767px) {
                font-size: 16px;
              }
            }
          }
          span {
            order: 1;
          }
          svg {
            margin: 0 0 0 8px;
            order: 2;
            width: 8px;
            @media (max-width: 1024px) {
              width: 7px;
            }
            @media (max-width: 767px) {
              width: 8px;
            }
          }
        }
        &:only-child {
          > div:nth-child(1) {
            @media (max-width: 767px) {
              display: flex;
            }
          }
          > div:not([role="button"]) > span {
            margin-top: 4px;
            @media (max-width: 1024px) {
              margin-top: 6px;
            }
            @media (max-width: 767px) {
              margin-top: 0;
            }
          }
          > div[role="button"],
          > button {
            &:nth-child(2) > span:nth-child(2) {
              @media (max-width: 840px) {
                display: block;
              }
              @media (max-width: 767px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Instalments */
  /* ---------------------------------------------------------------- */
  &.instalments {
    > div > div > .confirm {
      height: auto;
      @media (max-width: 767px) {
        height: 70px;
      }
    }
  }
}
