@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Question Vrm Additional Vehicle */
/* ---------------------------------------------------------------- */
.question-vrm-additional-vehicle {
  width: 412px;
  @media (max-width: 1024px) {
    width: 100%;
  }
}
/* ---------------------------------------------------------------- */
/* Question Vrm Additional Vehicle Buttons */
/* ---------------------------------------------------------------- */
.question-vrm-additional-vehicle-buttons {
  margin-top: 12px;
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  > div {
    > div[role="button"],
    > button {
      height: 50px;
      width: 100%;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Loading */
  /* ---------------------------------------------------------------- */
  &.loading {
    pointer-events: none;
    > span {
      align-items: center;
      background-color: $vehiclesLoadingIconBg;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      height: 32px;
      justify-content: center;
      position: absolute;
      right: -72px;
      top: 0;
      width: 32px;
      z-index: 1;
      @media (max-width: 1060px) {
        right: -44px;
      }
      @media (max-width: 1024px) {
        height: 28px;
        right: 24px;
        top: 5px;
        width: 28px;
      }
      @media (max-width: 767px) {
        right: 16px;
      }
      > svg {
        height: 28px;
        width: 28px;
        @media (max-width: 1024px) {
          height: 24px;
          width: 24px;
        }
        * {
          &:nth-child(1) {
            stroke: $vehiclesLoadingIcon;
          }
          &:nth-child(2) {
            fill: $vehiclesLoadingIcon;
          }
        }
      }
    }
  }
}
