@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* USP */
/* ---------------------------------------------------------------- */
.usp-bar {
  background: $uspBg;
  flex-shrink: 0;
  height: 36px;
  width: 100%;
  @media (max-width: 1024px) {
    display: none;
  }
  /* ---------------------------------------------------------------- */
  /* USP - Container */
  /* ---------------------------------------------------------------- */
  > ul {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    margin: 0 auto;
    max-width: 1360px;
    padding: 0 32px;
    @media (max-width: 1024px) {
      padding: 0 24px;
    }
    @media (max-width: 767px) {
      padding: 0 16px;
    }
    /* ---------------------------------------------------------------- */
    /* USP - Item */
    /* ---------------------------------------------------------------- */
    > li {
      align-items: center;
      color: $uspText;
      display: flex;
      font-family: $semibold;
      font-size: 13px;
      height: 100%;
      line-height: 17px;
      margin: 0 auto;
      overflow: hidden;
      text-transform: uppercase;
      white-space: nowrap;
      @media (max-width: 1099px) {
        font-size: 12px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:nth-child(even) {
        /* ---------------------------------------------------------------- */
        /* USP - Separators */
        /* ---------------------------------------------------------------- */
        > span {
          background-color: $uspBorder;
          display: block;
          height: 100%;
          width: 2px;
        }
      }
      /* ---------------------------------------------------------------- */
      /* USP - Icon */
      /* ---------------------------------------------------------------- */
      > svg {
        flex-shrink: 0;
        height: 22px;
        pointer-events: none;
        width: 22px;
        @media (max-width: 1099px) {
          height: 20px;
          width: 20px;
        }
        @media (max-width: 1024px) {
          display: none;
        }
        * {
          fill: $uspIcon;
        }
      }
      /* ---------------------------------------------------------------- */
      /* USP - Text */
      /* ---------------------------------------------------------------- */
      > span {
        margin-left: 8px;
        @media (max-width: 1024px) {
          margin-left: 0;
        }
        &:only-child {
          margin-left: 0;
        }
      }
    }
  }
}
