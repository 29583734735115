@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Error Call Us */
/* ---------------------------------------------------------------- */
.error-call-us {
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  > span,
  > div[role="button"],
  > button {
    align-items: center;
    color: $errorTextInvalid;
    display: inline-flex;
    font-family: $medium;
    font-size: 20px;
    line-height: 24px;
    margin: -4px 0 0 30px;
    @include user-select;
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    > svg {
      height: 20px;
      margin: 0 8px 0 0;
      width: 20px;
      @include no-user-select;
      > * {
        fill: $errorTextInvalid;
        @include fast-transition($props: "fill");
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Link */
  /* ---------------------------------------------------------------- */
  > div[role="button"],
  > button {
    color: $errorTextInvalid;
    cursor: pointer;
    @include fast-transition($props: "color");
    &:focus {
      color: darken($errorTextInvalid, 10%);
      text-decoration: underline;
      text-decoration-color: darken($errorTextInvalid, 10%);
      svg * {
        fill: darken($errorTextInvalid, 10%);
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        color: darken($errorTextInvalid, 10%);
        svg * {
          fill: darken($errorTextInvalid, 10%);
        }
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        color: darken($errorTextInvalid, 20%);
        svg * {
          fill: darken($errorTextInvalid, 20%);
        }
      }
    }
  }
}
