@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Payment - Title */
/* ---------------------------------------------------------------- */
legend.payment-title {
  box-sizing: border-box;
  display: block;
  margin: 0 0 28px;
  position: relative;
  @media (max-width: 767px) {
    margin: 0 0 22px;
  }
  @media (max-width: 385px) {
    padding-right: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > h2 {
    margin: 0 0 11px;
    padding-right: 102px;
    @media (max-width: 400px) {
      padding-right: 0;
    }
    &::after {
      content: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  > span {
    display: block;
    font-family: $regular;
    font-size: 14px;
    line-height: 17px;
  }
  /* ---------------------------------------------------------------- */
  /* Icon */
  /* ---------------------------------------------------------------- */
  > svg {
    height: 28px;
    position: absolute;
    right: 0;
    top: 0;
    width: 87px;
    @media (max-width: 400px) {
      display: none;
    }
  }
}
