@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Conviction */
/* ---------------------------------------------------------------- */
.conviction {
  align-items: center;
  background-color: $claimsConvictionsBg;
  border: 2px solid $claimsConvictionsBorder;
  border-radius: 6px;
  display: flex;
  margin: 0 0 12px;
  padding: 20px;
  @media (max-width: 767px) {
    align-items: flex-start;
    border-radius: 0;
    border-width: 2px 0;
    flex-direction: column;
    padding: 16px;
  }
  &:last-child {
    margin: 0;
  }
  > div {
    &:nth-child(1) {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      padding-right: 20px;
      width: 100%;
      @media (max-width: 767px) {
        padding-right: 0;
      }
      /* ---------------------------------------------------------------- */
      /* Icon */
      /* ---------------------------------------------------------------- */
      > svg {
        flex-shrink: 0;
        height: 40px;
        margin-right: 12px;
        width: 50px;
        /* ---------------------------------------------------------------- */
        /* Hammer - Icon - Head */
        /* ---------------------------------------------------------------- */
        *:nth-child(1),
        *:nth-child(2),
        *:nth-child(3) {
          fill: $iconConvictionHammerHead;
        }
        /* ---------------------------------------------------------------- */
        /* Hammer - Icon - Base */
        /* ---------------------------------------------------------------- */
        *:nth-child(4) {
          fill: $iconConvictionHammerBase;
        }
        /* ---------------------------------------------------------------- */
        /* Hammer - Icon - Gavel */
        /* ---------------------------------------------------------------- */
        *:nth-child(5),
        *:nth-child(6) {
          fill: $iconConvictionHammerGavel;
        }
        /* ---------------------------------------------------------------- */
        /* Hammer - Icon - Handle */
        /* ---------------------------------------------------------------- */
        *:nth-child(7) {
          fill: $iconConvictionHammerHandle;
        }
        /* ---------------------------------------------------------------- */
        /* Hammer - Icon - Stroke */
        /* ---------------------------------------------------------------- */
        *:nth-child(8),
        *:nth-child(9) {
          fill: $iconConvictionHammerStroke;
        }
      }
      > div {
        /* ---------------------------------------------------------------- */
        /* Title */
        /* ---------------------------------------------------------------- */
        > h2 {
          color: $title;
          font-family: $semibold;
          font-size: 20px;
          line-height: 22px;
          @include user-select;
          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 20px;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Error */
        /* ---------------------------------------------------------------- */
        > div {
          display: flex;
          margin: 16px 0 0;
          @media (max-width: 1024px) {
            margin: 16px 0 0;
          }
          @media (max-width: 767px) {
            margin: 16px 0 8px;
          }
          > svg {
            flex-shrink: 0;
            height: 20px;
            width: 20px;
            * {
              fill: $questionInvalidText;
            }
          }
          > p {
            color: $questionInvalidText;
            font-size: 16px;
            line-height: 20px;
            margin: 0 0 -4px;
            padding: 0 0 0 10px;
            @media (max-width: 1024px) {
              padding: 0 0 0 16px;
            }
            @media (max-width: 767px) {
              padding: 0 0 0 12px;
            }
          }
        }
      }
    }
    &:nth-child(2) {
      flex-shrink: 0;
      margin: 0 0 0 auto;
      @media (max-width: 767px) {
        margin: 13px 0 0;
        width: 100%;
      }
      /* ---------------------------------------------------------------- */
      /* Buttons */
      /* ---------------------------------------------------------------- */
      > div {
        @media (max-width: 1024px) {
          display: none;
        }
        > div[role="button"],
        > button {
          &:first-child {
            margin-right: 12px;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Desktop */
        /* ---------------------------------------------------------------- */
        &:nth-child(1) {
          > div[role="button"],
          > button {
            @media (max-width: 1240px) {
              padding: 0 16px;
            }
          }
        }
        /* ---------------------------------------------------------------- */
        /* Mobile */
        /* ---------------------------------------------------------------- */
        &:nth-child(2) {
          display: none;
          position: relative;
          @media (max-width: 1024px) {
            display: flex;
          }
          > span {
            display: none;
            @media (max-width: 415px) {
              background-color: darken($claimsConvictionsBorder, 5%);
              display: block;
              height: 30px;
              left: 50%;
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 2px;
            }
          }
          > div {
            &:first-child {
              @media (max-width: 1024px) {
                margin-right: 12px;
              }
              @media (max-width: 767px) {
                margin-left: auto;
              }
            }
            @media (max-width: 415px) {
              display: flex;
              flex-basis: 50%;
              max-width: 50%;
            }
            > div[role="button"],
            > button {
              flex-shrink: 0;
              @media (max-width: 415px) {
                margin: 0 auto;
              }
              > svg {
                height: 12px;
                margin: -2px 4px 0 -1px;
                width: 12px;
              }
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    background-color: $questionInvalidBg;
    border-color: $questionInvalidBorder;
    > div:nth-child(1) > div > h2 {
      color: $questionInvalidText;
    }
    > div:nth-child(1) > svg {
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Head */
      /* ---------------------------------------------------------------- */
      *:nth-child(1),
      *:nth-child(2),
      *:nth-child(3) {
        fill: $iconConvictionHammerHeadInvalid;
      }
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Base */
      /* ---------------------------------------------------------------- */
      *:nth-child(4) {
        fill: $iconConvictionHammerBaseInvalid;
      }
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Gavel */
      /* ---------------------------------------------------------------- */
      *:nth-child(5),
      *:nth-child(6) {
        fill: $iconConvictionHammerGavelInvalid;
      }
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Handle */
      /* ---------------------------------------------------------------- */
      *:nth-child(7) {
        fill: $iconConvictionHammerHandleInvalid;
      }
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Stroke */
      /* ---------------------------------------------------------------- */
      *:nth-child(8),
      *:nth-child(9) {
        fill: $iconConvictionHammerStrokeInvalid;
      }
    }
    > div:nth-child(2) > div:nth-child(2) > span {
      background-color: $questionInvalidText;
    }
  }
}
