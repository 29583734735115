@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Overview */
/* ---------------------------------------------------------------- */
.policy-overview {
  background-color: $productBg;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  @media (max-width: 767px) {
    border-radius: 0;
    margin: 0 0 12px;
  }
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  > header {
    align-items: center;
    background-color: $productPrimaryBg;
    border-bottom: 2px solid $productPrimaryBorder;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    height: 80px;
    padding: 0 20px;
    position: relative;
    @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
      height: 72px;
    }
    @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
      height: 68px;
    }
    @media (max-width: 767px) {
      border-radius: 0;
      padding: 0 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Plan Icon */
    /* ---------------------------------------------------------------- */
    > svg {
      overflow: hidden;
      width: 104px;
      &[data-svg="basic"] {
        height: 30px;
      }
      &[data-svg="enhanced"] {
        height: 47px;
      }
      &[data-svg="evolution"] {
        height: 47px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    > div[role="button"],
    > button {
      display: none;
      @media (max-width: 767px) {
        background-color: transparent !important;
        display: inline-flex;
        margin-left: auto;
      }
      @media (max-width: 414px) {
        padding: 0 16px;
      }
      @media (max-width: 390px) {
        padding: 0 8px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  > div {
    padding: 20px 20px 12px;
    @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
      padding-bottom: 12px;
      padding-top: 18px;
    }
    @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
      padding-top: 17px;
    }
    @media (max-width: 767px) {
      padding-left: 16px;
      padding-right: 16px;
    }
    > ul {
      /* ---------------------------------------------------------------- */
      /* Features */
      /* ---------------------------------------------------------------- */
      &:nth-child(1) {
        &:only-child {
          padding-bottom: 8px;
          @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
            padding-bottom: 7px;
          }
          @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
            padding-bottom: 6px;
          }
        }
        > li {
          &:last-child {
            > div {
              margin-bottom: 0;
            }
          }
          > div {
            font-family: $regular;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Closed */
  /* ---------------------------------------------------------------- */
  &.closed {
    > header {
      @media (max-width: 767px) {
        border-color: $productPrimaryBg;
      }
    }
    > div {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}
