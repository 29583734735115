@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Recall Control */
/* ---------------------------------------------------------------- */
.recall-control {
  > div {
    display: flex;
    flex-basis: 412px;
    margin-left: 314px;
    max-width: 412px;
    @media (max-width: 1024px) {
      flex-basis: 100%;
      margin-left: 0;
      max-width: 100%;
    }
    /* ---------------------------------------------------------------- */
    /* Recall Control - Submit Row */
    /* ---------------------------------------------------------------- */
    &:nth-child(1) {
      margin-bottom: 29px;
      @media (max-width: 1024px) {
        margin-bottom: 23px;
      }
      /* ---------------------------------------------------------------- */
      /* Submit */
      /* ---------------------------------------------------------------- */
      > div[role="button"],
      > button {
        margin-left: auto;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Recall Control - Info Row */
    /* ---------------------------------------------------------------- */
    &:nth-child(2) {
      flex-direction: column;
      position: relative;
      /* ---------------------------------------------------------------- */
      /* Icon */
      /* ---------------------------------------------------------------- */
      > svg {
        height: 24px;
        left: -39px;
        position: absolute;
        top: 3px;
        width: 24px;
        @media (max-width: 1024px) {
          left: 0;
        }
        * {
          fill: $recallIcon;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Info */
      /* ---------------------------------------------------------------- */
      > p {
        margin: 0 0 16px;
        @include user-select;
        @media (max-width: 1024px) {
          padding-left: 39px;
        }
        &:last-child {
          margin-bottom: -4px;
        }
        strong {
          font-family: $semibold;
          font-weight: normal;
        }
      }
    }
  }
}
