/* User Select */
@mixin user-select() {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
/* No User Select */
@mixin no-user-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Fast Transition */
@mixin fast-transition($mob: false, $timing: cubic-bezier(0.42, 0, 0.58, 1), $props: "all") {
  transition-duration: 0.1s;
  transition-property: #{$props};
  transition-timing-function: #{$timing};
  @if not($mob) {
    :global(.platform-mobile) & {
      transition: none;
    }
  }
}
/* Slow Transition */
@mixin slow-transition($mob: false, $timing: cubic-bezier(0.42, 0, 0.58, 1), $props: "all") {
  transition-duration: 0.3s;
  transition-property: #{$props};
  transition-timing-function: #{$timing};
  @if not($mob) {
    :global(.platform-mobile) & {
      transition: none;
    }
  }
}
/* Add white (tint) function */
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}
/* Add black (shade) function */
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}
