@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Debug */
/* ---------------------------------------------------------------- */
.debug {
  color: #333;
  display: block;
  flex-basis: 100%;
  flex-shrink: 0;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: -3px;
  margin-top: 6px;
  > strong {
    font-weight: 600;
  }
  & + .debug {
    margin-top: 16px;
  }
}
