@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Button */
/* ---------------------------------------------------------------- */
.button {
  align-items: center;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  padding: 0 24px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  vertical-align: top;
  @include fast-transition($props: "border-color, background-color");
  @at-root {
    a#{&} {
      font-family: $bold;
      &:focus {
        text-decoration: underline;
        text-decoration-color: $primaryButtonText;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Button - Icon */
  /* -------------------------------------------------------------- */
  svg {
    flex-shrink: 0;
    height: 16px;
    margin: 0 8px 0 0;
    pointer-events: none;
    width: 16px;
    &:only-child {
      margin: 0;
    }
    * {
      @include fast-transition($props: "fill, stroke");
    }
  }
  /* -------------------------------------------------------------- */
  /* Button - Text */
  /* -------------------------------------------------------------- */
  span {
    font-family: $bold;
    font-size: 16px;
    line-height: normal;
    pointer-events: none;
    white-space: nowrap;
    @include fast-transition($props: "color");
    @at-root :focus#{&} {
      text-decoration: underline;
      text-decoration-color: $primaryButtonText;
    }
  }
  /* -------------------------------------------------------------- */
  /* Primary */
  /* -------------------------------------------------------------- */
  &.primary {
    background-color: $primaryButtonBg;
    border-color: $primaryButtonBorder;
    &:focus {
      background-color: $primaryButtonBgHover;
      border-color: $primaryButtonBorderHover;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $primaryButtonBgHover;
        border-color: $primaryButtonBorderHover;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        background-color: $primaryButtonBgActive;
        border-color: $primaryButtonBorderActive;
        span {
          color: $primaryButtonTextActive;
        }
      }
    }
    svg * {
      fill: $primaryButtonText;
    }
    span {
      color: $primaryButtonText;
    }
    &.button-disabled {
      background-color: $primaryButtonBgDisabled;
      border-color: $primaryButtonBorderDisabled;
      :global(.platform-desktop) & {
        &:focus,
        &:hover {
          background-color: $primaryButtonBgDisabled;
          border-color: $primaryButtonBorderDisabled;
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          background-color: $primaryButtonBgDisabled;
          border-color: $primaryButtonBorderDisabled;
        }
      }
      span {
        color: $primaryButtonTextDisabled;
      }
      svg * {
        fill: $primaryButtonTextDisabled;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Primary Clear */
  /* -------------------------------------------------------------- */
  &.primary-clear {
    background-color: transparent;
    border: none;
    height: auto;
    padding: 0;
    @at-root {
      a#{&} {
        color: $secondaryButtonText;
        &:focus {
          color: $primaryButtonBgHover;
          text-decoration-color: $primaryButtonBgHover;
        }
        &:hover {
          :global(.platform-desktop) & {
            color: $primaryButtonBgHover;
          }
        }
        &:active {
          :global(.platform-desktop) &,
          :global(.platform-mobile) & {
            color: $primaryButtonBgActive;
          }
        }
        &.button-disabled {
          color: $primaryButtonBgDisabled;
        }
        &.button-disabled:focus {
          :global(.platform-desktop) &,
          :global(.platform-mobile) & {
            color: $primaryButtonBgDisabled;
          }
        }
        &.button-disabled:hover {
          :global(.platform-desktop) & {
            color: $primaryButtonBgDisabled;
          }
        }
        &.button-disabled:active {
          :global(.platform-desktop) &,
          :global(.platform-mobile) & {
            color: $primaryButtonBgDisabled;
          }
        }
      }
    }
    span {
      color: $secondaryButtonText;
      @at-root :focus#{&} {
        color: shade($primaryButtonBgHover, 30%);
        text-decoration-color: shade($primaryButtonBgHover, 30%);
      }
      @at-root :hover#{&} {
        :global(.platform-desktop) & {
          color: shade($primaryButtonBgHover, 30%);
        }
      }
      @at-root :active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          color: shade($primaryButtonBgActive, 40%);
        }
      }
      @at-root .button-disabled#{&} {
        color: $primaryButtonBgDisabled;
      }
      @at-root .button-disabled:focus#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          color: $primaryButtonBgDisabled;
        }
      }
      @at-root .button-disabled:hover#{&} {
        :global(.platform-desktop) & {
          color: $primaryButtonBgDisabled;
        }
      }
      @at-root .button-disabled:active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          color: $primaryButtonBgDisabled;
        }
      }
    }
    svg * {
      fill: $secondaryButtonText;
      @at-root :focus#{&} {
        fill: shade($primaryButtonBgHover, 30%);
      }
      @at-root :hover#{&} {
        :global(.platform-desktop) & {
          fill: shade($primaryButtonBgHover, 30%);
        }
      }
      @at-root :active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          fill: shade($primaryButtonBgActive, 40%);
        }
      }
      @at-root .button-disabled#{&} {
        fill: $primaryButtonBgDisabled;
      }
      @at-root .button-disabled:focus#{&} {
        fill: $primaryButtonBgDisabled;
      }
      @at-root .button-disabled:hover#{&} {
        :global(.platform-desktop) & {
          fill: $primaryButtonBgDisabled;
        }
      }
      @at-root .button-disabled:active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          fill: $primaryButtonBgDisabled;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Secondary */
  /* -------------------------------------------------------------- */
  &.secondary {
    background-color: $secondaryButtonBg;
    border-color: $secondaryButtonBorder;
    &:focus {
      background-color: $secondaryButtonBgHover;
      border-color: $secondaryButtonBorderHover;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $secondaryButtonBgHover;
        border-color: $secondaryButtonBorderHover;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        background-color: $secondaryButtonBgActive;
        border-color: $secondaryButtonBorderActive;
      }
    }
    span {
      color: $secondaryButtonText;
      @at-root :focus#{&} {
        color: $secondaryButtonTextHover;
        text-decoration-color: $secondaryButtonTextHover;
      }
      @at-root :hover#{&} {
        :global(.platform-desktop) & {
          color: $secondaryButtonTextHover;
        }
      }
      @at-root :active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          color: $secondaryButtonTextActive;
        }
      }
      @at-root .button-disabled#{&} {
        color: $secondaryButtonTextDisabled;
      }
      @at-root .button-disabled:focus#{&} {
        color: $secondaryButtonTextDisabled;
      }
      @at-root .button-disabled:hover#{&} {
        :global(.platform-desktop) & {
          color: $secondaryButtonTextDisabled;
        }
      }
      @at-root .button-disabled:active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          color: $secondaryButtonTextDisabled;
        }
      }
    }
    svg * {
      fill: $secondaryButtonText;
      @at-root :focus#{&} {
        fill: $secondaryButtonTextHover;
      }
      @at-root :hover#{&} {
        :global(.platform-desktop) & {
          fill: $secondaryButtonTextHover;
        }
      }
      @at-root :active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          fill: $secondaryButtonTextActive;
        }
      }
      @at-root .button-disabled#{&} {
        fill: $secondaryButtonTextDisabled;
      }
      @at-root .button-disabled:focus#{&} {
        fill: $secondaryButtonTextDisabled;
      }
      @at-root .button-disabled:hover#{&} {
        :global(.platform-desktop) & {
          fill: $secondaryButtonTextDisabled;
        }
      }
      @at-root .button-disabled:active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          fill: $secondaryButtonTextDisabled;
        }
      }
    }
    &.button-disabled {
      background-color: $secondaryButtonBgDisabled;
      border-color: $secondaryButtonBorderDisabled;
      :global(.platform-desktop) & {
        &:hover {
          background-color: $secondaryButtonBgDisabled;
          border-color: $secondaryButtonBorderDisabled;
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          background-color: $secondaryButtonBgDisabled;
          border-color: $secondaryButtonBorderDisabled;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Secondary Clear */
  /* -------------------------------------------------------------- */
  &.secondary-clear {
    background-color: transparent;
    border: none;
    height: auto;
    padding: 0;
    @at-root {
      a#{&} {
        color: $formButtonText;
        &:focus {
          color: darken($formButtonText, 10%);
          text-decoration-color: darken($formButtonText, 10%);
        }
        &:hover {
          :global(.platform-desktop) & {
            color: darken($formButtonText, 10%);
          }
        }
        &:active {
          :global(.platform-desktop) &,
          :global(.platform-mobile) & {
            color: darken($formButtonText, 20%);
          }
        }
        &.button-disabled {
          color: $primaryButtonBgDisabled;
        }
        &.button-disabled:focus {
          color: $primaryButtonBgDisabled;
        }
        &.button-disabled:hover {
          :global(.platform-desktop) & {
            color: $primaryButtonBgDisabled;
          }
        }
        &.button-disabled:active {
          :global(.platform-desktop) &,
          :global(.platform-mobile) & {
            color: $primaryButtonBgDisabled;
          }
        }
      }
    }
    span {
      color: $formButtonText;
      @at-root :focus#{&} {
        color: darken($formButtonText, 10%);
        text-decoration-color: darken($formButtonText, 10%);
      }
      @at-root :hover#{&} {
        :global(.platform-desktop) & {
          color: darken($formButtonText, 10%);
        }
      }
      @at-root :active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          color: darken($formButtonText, 20%);
        }
      }
      @at-root .button-disabled#{&} {
        color: $primaryButtonBgDisabled;
      }
      @at-root .button-disabled:focus#{&} {
        color: $primaryButtonBgDisabled;
      }
      @at-root .button-disabled:hover#{&} {
        :global(.platform-desktop) & {
          color: $primaryButtonBgDisabled;
        }
      }
      @at-root .button-disabled:active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          color: $primaryButtonBgDisabled;
        }
      }
    }
    svg * {
      fill: $formButtonText;
      @at-root :focus#{&} {
        fill: darken($formButtonText, 10%);
      }
      @at-root :hover#{&} {
        :global(.platform-desktop) & {
          fill: darken($formButtonText, 10%);
        }
      }
      @at-root :active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          fill: darken($formButtonText, 20%);
        }
      }
      @at-root .button-disabled#{&} {
        fill: $primaryButtonBgDisabled;
      }
      @at-root .button-disabled:focus#{&} {
        fill: $primaryButtonBgDisabled;
      }
      @at-root .button-disabled:hover#{&} {
        :global(.platform-desktop) & {
          fill: $primaryButtonBgDisabled;
        }
      }
      @at-root .button-disabled:active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          fill: $primaryButtonBgDisabled;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Success */
  /* -------------------------------------------------------------- */
  &.success {
    background-color: $successButtonBg;
    border-color: $successButtonBorder;
    &:focus {
      background-color: $successButtonBgHover;
      border-color: $successButtonBorderHover;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $successButtonBgHover;
        border-color: $successButtonBorderHover;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        background-color: $successButtonBgActive;
        border-color: $successButtonBorderActive;
      }
    }
    svg * {
      fill: $successButtonText;
      @at-root .button-disabled#{&} {
        fill: $successButtonTextDisabled;
      }
    }
    span {
      color: $successButtonText;
      @at-root .button-disabled#{&} {
        color: $successButtonTextDisabled;
      }
    }
    &.button-disabled {
      background-color: $successButtonBgDisabled;
      border-color: $successButtonBorderDisabled;
      :global(.platform-desktop) & {
        &:hover {
          background-color: $successButtonBgDisabled;
          border-color: $successButtonBorderDisabled;
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          background-color: $successButtonBgDisabled;
          border-color: $successButtonBorderDisabled;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Success Inverted */
  /* -------------------------------------------------------------- */
  &.success-inverted {
    background-color: transparent;
    border-color: $successButtonInvertedBorder;
    &:focus {
      background-color: $successButtonInvertedBgHover;
      border-color: $successButtonInvertedBorderHover;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $successButtonInvertedBgHover;
        border-color: $successButtonInvertedBorderHover;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        background-color: transparent;
        border-color: $successButtonInvertedBorderActive;
      }
    }
    svg * {
      fill: $successButtonInvertedIcon;
      @at-root :focus#{&} {
        fill: $successButtonInvertedIconHover;
      }
      @at-root :hover#{&} {
        :global(.platform-desktop) & {
          fill: $successButtonInvertedIconHover;
        }
      }
      @at-root :active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          fill: $successButtonInvertedIconActive;
        }
      }
      @at-root .button-disabled#{&} {
        fill: $successButtonTextDisabled;
      }
    }
    span {
      color: $successButtonInvertedText;
      @at-root :focus#{&} {
        color: $successButtonInvertedTextHover;
        text-decoration-color: $successButtonInvertedTextHover;
      }
      @at-root :hover#{&} {
        :global(.platform-desktop) & {
          color: $successButtonInvertedTextHover;
        }
      }
      @at-root :active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          color: $successButtonInvertedTextActive;
        }
      }
      @at-root .button-disabled#{&} {
        color: $successButtonTextDisabled;
      }
    }
    &.button-disabled {
      background-color: $successButtonBgDisabled;
      border-color: $successButtonBorderDisabled;
      :global(.platform-desktop) & {
        &:hover {
          background-color: $successButtonBgDisabled;
          border-color: $successButtonBorderDisabled;
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          background-color: $successButtonBgDisabled;
          border-color: $successButtonBorderDisabled;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Invalid */
  /* -------------------------------------------------------------- */
  &.invalid {
    background-color: $invalidButtonBg;
    border-color: $invalidButtonBorder;
    &:focus {
      background-color: $invalidButtonBgHover;
      border-color: $invalidButtonBorderHover;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $invalidButtonBgHover;
        border-color: $invalidButtonBorderHover;
      }
    }
    :global(.platform-desktop) &,
    :global(.platform-mobile) & {
      &:active {
        background-color: $invalidButtonBgActive;
        border-color: $invalidButtonBorderActive;
      }
    }
    svg * {
      fill: $invalidButtonText;
      @at-root .button-disabled#{&} {
        fill: $invalidButtonTextDisabled;
      }
    }
    span {
      color: $invalidButtonText;
      @at-root :focus#{&} {
        text-decoration-color: $invalidButtonText;
      }
      @at-root :active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          color: $invalidButtonTextActive;
        }
      }
      @at-root .button-disabled#{&} {
        color: $invalidButtonTextDisabled;
      }
    }
    &.button-disabled {
      background-color: $invalidButtonBgDisabled;
      border-color: $invalidButtonBorderDisabled;
      :global(.platform-desktop) & {
        &:hover {
          background-color: $invalidButtonBgDisabled;
          border-color: $invalidButtonBorderDisabled;
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          background-color: $invalidButtonBgDisabled;
          border-color: $invalidButtonBorderDisabled;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Success Clear */
  /* -------------------------------------------------------------- */
  &.success-clear {
    background-color: transparent;
    border: none;
    height: auto;
    padding: 0;
    @at-root {
      a#{&} {
        color: $successButtonBg;
        &:focus {
          color: $successButtonBgHover;
          text-decoration-color: $successButtonBgHover;
        }
        &:hover {
          :global(.platform-desktop) & {
            color: $successButtonInvertedTextHover;
          }
        }
        &:active {
          :global(.platform-desktop) &,
          :global(.platform-mobile) & {
            color: $successButtonBgActive;
          }
        }
        &.button-disabled {
          color: $primaryButtonBgDisabled;
        }
        &.button-disabled:focus {
          color: $primaryButtonBgDisabled;
        }
        &.button-disabled:hover {
          :global(.platform-desktop) & {
            color: $primaryButtonBgDisabled;
          }
        }
        &.button-disabled:active {
          :global(.platform-desktop) &,
          :global(.platform-mobile) & {
            color: $primaryButtonBgDisabled;
          }
        }
      }
    }
    span {
      color: $successButtonBg;
      @at-root :focus#{&} {
        color: shade($successDark, 5%);
        text-decoration-color: shade($successDark, 5%);
      }
      @at-root :hover#{&} {
        :global(.platform-desktop) & {
          color: shade($successDark, 5%);
        }
      }
      @at-root :active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          color: $successButtonBgActive;
        }
      }
      @at-root .button-disabled#{&} {
        color: $primaryButtonBgDisabled;
      }
      @at-root .button-disabled:focus#{&} {
        color: $primaryButtonBgDisabled;
      }
      @at-root .button-disabled:hover#{&} {
        :global(.platform-desktop) & {
          color: $primaryButtonBgDisabled;
        }
      }
      @at-root .button-disabled:active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          color: $primaryButtonBgDisabled;
        }
      }
    }
    svg * {
      fill: $successButtonBg;
      @at-root :focus#{&} {
        fill: shade($successDark, 5%);
      }
      @at-root :hover#{&} {
        :global(.platform-desktop) & {
          fill: shade($successDark, 5%);
        }
      }
      @at-root :active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          fill: $successButtonBgActive;
        }
      }
      @at-root .button-disabled#{&} {
        fill: $primaryButtonBgDisabled;
      }
      @at-root .button-disabled:focus#{&} {
        fill: $primaryButtonBgDisabled;
      }
      @at-root .button-disabled:hover#{&} {
        :global(.platform-desktop) & {
          fill: $primaryButtonBgDisabled;
        }
      }
      @at-root .button-disabled:active#{&} {
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          fill: $primaryButtonBgDisabled;
        }
      }
    }
  }
}
/* -------------------------------------------------------------- */
/* Read Only */
/* -------------------------------------------------------------- */
.button-read-only {
  cursor: default;
  pointer-events: none;
  &.primary:focus {
    background-color: $primaryButtonBg;
    border-color: $primaryButtonBorder;
  }
  &.primary-clear:focus {
    span {
      color: $primaryButtonBg;
    }
    svg * {
      fill: $primaryButtonBg;
    }
  }
  &.secondary:focus {
    background-color: $secondaryButtonBg;
    border-color: $secondaryButtonBorder;
    span {
      color: $secondaryButtonText;
    }
    svg * {
      fill: $secondaryButtonText;
    }
  }
  &.secondary-clear:focus {
    span {
      color: $formButtonText;
    }
    svg * {
      fill: $formButtonText;
    }
  }
  &.success:focus {
    background-color: $successButtonBg;
    border-color: $successButtonBorder;
  }
  &.invalid:focus {
    background-color: $invalidButtonBg;
    border-color: $invalidButtonBorder;
  }
  &.success-clear:focus {
    span {
      color: $successButtonBg;
    }
    svg * {
      fill: $successButtonBg;
    }
  }
}
/* -------------------------------------------------------------- */
/* Disabled */
/* -------------------------------------------------------------- */
.button-disabled {
  cursor: default;
  pointer-events: none;
  &:focus {
    span {
      text-decoration: none !important;
    }
  }
}
