@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Error */
/* ---------------------------------------------------------------- */
.error {
  display: flex;
  margin: 20px 0 0;
  @media (max-width: 1024px) {
    margin: 16px 0 0;
  }
  /* ---------------------------------------------------------------- */
  /* Error - Icon */
  /* ---------------------------------------------------------------- */
  > svg {
    flex-shrink: 0;
    height: 20px;
    width: 20px;
    * {
      fill: $questionInvalidText;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Error - Text */
  /* ---------------------------------------------------------------- */
  > p {
    color: $questionInvalidText;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 -4px;
    padding: 0 0 0 10px;
    @media (max-width: 1024px) {
      padding: 0 0 0 16px;
    }
  }
}
