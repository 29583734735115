@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Product Wrapper */
/* ---------------------------------------------------------------- */
.product-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* ---------------------------------------------------------------- */
  /* Main Content */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    flex-grow: 1;
    @media (max-width: 767px) {
      display: block;
    }
    > section {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: 100%;
      /* ---------------------------------------------------------------- */
      /* Col 1 */
      /* ---------------------------------------------------------------- */
      &:nth-child(1) {
        flex-basis: 520px;
        margin-right: 30px;
        max-width: 520px;
        @media (max-width: 1240px) {
          flex-basis: calc(50% - 8px);
          margin-right: 16px;
          max-width: calc(50% - 8px);
        }
        @media (max-width: 1024px) {
          flex-basis: calc(50% - 4px);
          margin-right: 8px;
          max-width: calc(50% - 4px);
        }
        @media (max-width: 767px) {
          margin-right: 0;
          max-width: 100%;
        }
        /* ---------------------------------------------------------------- */
        /* Sticky */
        /* ---------------------------------------------------------------- */
        &.sticky > div:first-child {
          position: sticky;
          top: 30px;
          @media (max-width: 1240px) {
            top: 16px;
          }
          @media (max-width: 1024px) {
            top: 8px;
          }
          @media (max-width: 767px) {
            position: relative;
            top: auto;
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Col 2 */
      /* ---------------------------------------------------------------- */
      &:nth-child(2) {
        flex-basis: calc(100% - 550px);
        max-width: calc(100% - 550px);
        @media (max-width: 1240px) {
          flex-basis: calc(50% - 8px);
          margin-right: 16px;
          max-width: calc(50% - 8px);
        }
        @media (max-width: 1024px) {
          flex-basis: calc(50% - 4px);
          max-width: calc(50% - 4px);
        }
        @media (max-width: 767px) {
          max-width: 100%;
        }
        > *:last-child {
          @media (min-width: 768px) {
            margin: 0;
          }
        }
      }
    }
  }
}
