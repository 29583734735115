@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.checkbox {
  flex-shrink: 0;
  height: 30px;
  width: 30px;
  @media (max-width: 767px) {
    height: 40px;
    width: 40px;
  }
  @media (max-width: 374px) {
    height: 30px;
    width: 30px;
  }
  > div[role="button"],
  > button {
    align-items: center;
    border: 2px solid $checkboxBorder;
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 30px;
    justify-content: center;
    width: 30px;
    @include fast-transition($mob: true, $props: "border-color");
    @media (max-width: 767px) {
      height: 40px;
      width: 40px;
    }
    @media (max-width: 374px) {
      height: 30px;
      width: 30px;
    }
    &:focus {
      background-color: $checkboxBgFocus;
      border-color: $checkboxBorderFocus;
      > svg *:nth-child(1) {
        fill: $checkboxIcon;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        border-color: $checkboxBorderHover;
        > svg *:nth-child(1) {
          fill: $checkboxIconHover;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    > svg {
      height: 20px;
      opacity: 0;
      width: 20px;
      will-change: transform, opacity;
      @include fast-transition($mob: true, $props: "opacity");
      @media (max-width: 767px) {
        height: 30px;
        width: 30px;
      }
      @media (max-width: 374px) {
        height: 20px;
        width: 20px;
      }
      *:nth-child(1) {
        fill: $checkboxIconChecked;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Checked */
  /* ---------------------------------------------------------------- */
  &.checked {
    > div[role="button"],
    > button {
      &:focus {
        > svg *:nth-child(1) {
          fill: $checkboxIconCheckedFocus;
        }
      }
      > svg {
        opacity: 1;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    > div[role="button"],
    > button {
      background-color: $checkboxBg;
      border-color: $checkboxBorderInvalid;
      &:focus {
        background-color: $checkboxBgFocus;
        border-color: $checkboxBorderFocusInvalid;
        > svg *:nth-child(1) {
          fill: $checkboxIconInvalid;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: $checkboxBorderFocusInvalid;
        }
      }
      > svg *:nth-child(1) {
        fill: $checkboxIconInvalid;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    > div[role="button"],
    > button {
      background-color: $checkboxBg;
      border-color: $checkboxBorderDisabled;
      pointer-events: none;
      &:focus {
        background-color: $checkboxBgFocus;
      }
      > svg *:nth-child(1) {
        fill: $checkboxIconDisabled;
      }
    }
  }
}
