@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Address Search Description */
/* ---------------------------------------------------------------- */
.address-search-description {
  border: 2px solid transparent;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 12px;
  padding: 12px 0;
  position: relative;
  @media (max-width: 1024px) {
    border-width: 2px 0;
    box-sizing: border-box;
    flex-direction: column;
    flex-wrap: nowrap;
    left: -24px;
    padding: 12px 24px;
    width: calc(100% + 48px);
  }
  @media (max-width: 767px) {
    left: -16px;
    padding: 12px 16px;
    width: calc(100% + 32px);
  }
  &:last-child {
    margin: 0 0 -14px;
    @media (max-width: 767px) {
      margin-bottom: -8px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Label */
  /* ---------------------------------------------------------------- */
  > p {
    box-sizing: border-box;
    flex-basis: 314px;
    line-height: 20px;
    max-width: 314px;
    padding: 0 40px 0 20px;
    text-align: right;
    @include no-user-select;
    @media (max-width: 1024px) {
      flex-basis: auto;
      margin-top: -4px;
      max-width: 100%;
      padding: 0 32px 0 0;
      text-align: left;
    }
    @media (max-width: 767px) {
      padding: 0 16px 0 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Description */
  /* ---------------------------------------------------------------- */
  > div:nth-child(2) {
    background-color: $addressLookupDescriptionBg;
    border: 2px solid $addressLookupDescriptionBorder;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 17px 20px 20px;
    width: 412px;
    @media (max-width: 1024px) {
      margin-top: 11px;
      width: 100%;
    }
    @media (max-width: 767px) {
      padding: 14px 16px 16px;
    }
    > div {
      display: flex;
      margin: 0 0 15px;
      > svg {
        flex-shrink: 0;
        height: 34px;
        margin: 0 0 4px;
        width: 30px;
        @media (max-width: 767px) {
          height: 27px;
          width: 24px;
        }
        * {
          fill: $addressLookupDescriptionIcon;
        }
      }
      > p {
        color: $addressLookupDescriptionText;
        font-size: 14px;
        line-height: 18px;
        padding-left: 16px;
        @include user-select;
        @media (max-width: 767px) {
          padding-left: 12px;
        }
      }
    }
    > div[role="button"],
    > button {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    background-color: $questionInvalidBg;
    border-color: $questionInvalidBorder;
    > div {
      &:nth-child(2) {
        border-color: $addressLookupDescriptionBorderInvalid;
      }
      &:nth-child(3) {
        margin-left: 314px;
        width: 412px;
        @media (max-width: 1024px) {
          margin-left: 0;
          width: 100%;
        }
      }
      > div {
        > svg {
          * {
            fill: $addressLookupDescriptionIconInvalid;
          }
        }
        > p {
          color: $addressLookupDescriptionTextInvalid;
        }
      }
    }
  }
}
