@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Question Convictions */
/* ---------------------------------------------------------------- */
.question-convictions {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin: 0 0 40px;
  &:last-child {
    margin: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Empty State */
  /* ---------------------------------------------------------------- */
  > div:not([role="button"]) {
    align-items: center;
    background-color: $claimsConvictionsBg;
    border: 2px solid $claimsConvictionsBorder;
    border-radius: 6px;
    display: flex;
    padding: 20px;
    @media (max-width: 767px) {
      border-radius: 0;
      border-width: 2px 0;
      padding: 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    > svg {
      flex-shrink: 0;
      height: 40px;
      margin-right: 12px;
      width: 50px;
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Head */
      /* ---------------------------------------------------------------- */
      *:nth-child(1),
      *:nth-child(2),
      *:nth-child(3) {
        fill: $iconConvictionHammerHead;
      }
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Base */
      /* ---------------------------------------------------------------- */
      *:nth-child(4) {
        fill: $iconConvictionHammerBase;
      }
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Gavel */
      /* ---------------------------------------------------------------- */
      *:nth-child(5),
      *:nth-child(6) {
        fill: $iconConvictionHammerGavel;
      }
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Handle */
      /* ---------------------------------------------------------------- */
      *:nth-child(7) {
        fill: $iconConvictionHammerHandle;
      }
      /* ---------------------------------------------------------------- */
      /* Hammer - Icon - Stroke */
      /* ---------------------------------------------------------------- */
      *:nth-child(8),
      *:nth-child(9) {
        fill: $iconConvictionHammerStroke;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    > h3 {
      color: $title;
      font-family: $semibold;
      font-size: 20px;
      line-height: 22px;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Empty - Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid > div:not([role="button"]) {
    background-color: $questionInvalidBg;
    border-color: $questionInvalidBorder;
    > h3 {
      color: $questionInvalidText;
    }
  }
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  > ul:not(div),
  > div:not([role="button"]) {
    align-self: stretch;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      margin: 0 -16px 20px;
    }
    /* ---------------------------------------------------------------- */
    /* Add Button */
    /* ---------------------------------------------------------------- */
    + div[role="button"],
    + button {
      margin: 0 0 0 auto;
    }
  }
}
