@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* TextArea */
/* ---------------------------------------------------------------- */
.textarea {
  width: 412px;
  @media (max-width: 1024px) {
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* TextArea */
  /* ---------------------------------------------------------------- */
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-color: $inputBg;
    border: 2px solid $inputBorder;
    border-radius: 6px;
    box-sizing: border-box;
    color: $inputText;
    display: inline-block;
    filter: none;
    font-family: $medium;
    font-size: 16px;
    font-weight: normal;
    height: 104px;
    letter-spacing: normal;
    line-height: normal;
    outline: none;
    overflow: auto;
    padding: 13px 16px;
    resize: none;
    vertical-align: middle;
    width: 100%;
    @include no-user-select;
    /* ---------------------------------------------------------------- */
    /* Placeholder */
    /* ---------------------------------------------------------------- */
    &::-webkit-input-placeholder {
      color: $inputTextPlaceholder !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    &:-moz-placeholder {
      color: $inputTextPlaceholder !important;
      opacity: 1 !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    &::-moz-placeholder {
      color: $inputTextPlaceholder !important;
      opacity: 1 !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    &:-ms-input-placeholder {
      color: $inputTextPlaceholder !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    &::placeholder {
      color: $inputTextPlaceholder !important;
      opacity: 1 !important;
      text-transform: inherit !important;
      @include no-user-select;
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      background-color: $inputBgFocus;
      border-color: $inputBorderFocus;
      @include user-select;
      /* ---------------------------------------------------------------- */
      /* Focus Placeholder */
      /* ---------------------------------------------------------------- */
      &::-webkit-input-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
      }
      &:-moz-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
      }
      &::-moz-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
      }
      &:-ms-input-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
      }
      &::placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        border-color: $inputBorderHover;
        /* ---------------------------------------------------------------- */
        /* Hover Focus */
        /* ---------------------------------------------------------------- */
        &:focus {
          border-color: $inputBorderFocus;
        }
        /* ---------------------------------------------------------------- */
        /* Hover Placeholder */
        /* ---------------------------------------------------------------- */
        &::-webkit-input-placeholder {
          color: darken($inputTextPlaceholder, 15%) !important;
        }
        &:-moz-placeholder {
          color: darken($inputTextPlaceholder, 15%) !important;
        }
        &::-moz-placeholder {
          color: darken($inputTextPlaceholder, 15%) !important;
        }
        &:-ms-input-placeholder {
          color: darken($inputTextPlaceholder, 15%) !important;
        }
        &::placeholder {
          color: darken($inputTextPlaceholder, 15%) !important;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    textarea {
      border-color: $inputBorderInvalid;
      color: $inputTextInvalid;
      &::-webkit-input-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:-moz-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &::-moz-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:-ms-input-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &::placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:focus {
        border-color: $inputBorderFocusInvalid;
        color: $inputText;
        &::-webkit-input-placeholder {
          color: darken($inputTextPlaceholderInvalid, 10%) !important;
        }
        &:-moz-placeholder {
          color: darken($inputTextPlaceholderInvalid, 10%) !important;
        }
        &::-moz-placeholder {
          color: darken($inputTextPlaceholderInvalid, 10%) !important;
        }
        &:-ms-input-placeholder {
          color: darken($inputTextPlaceholderInvalid, 10%) !important;
        }
        &::placeholder {
          color: darken($inputTextPlaceholderInvalid, 10%) !important;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: $inputBorderFocusInvalid;
          color: $inputTextInvalid;
          &:focus {
            color: $inputText;
          }
          &::-webkit-input-placeholder {
            color: darken($inputTextPlaceholderInvalid, 10%) !important;
          }
          &:-moz-placeholder {
            color: darken($inputTextPlaceholderInvalid, 10%) !important;
          }
          &::-moz-placeholder {
            color: darken($inputTextPlaceholderInvalid, 10%) !important;
          }
          &:-ms-input-placeholder {
            color: darken($inputTextPlaceholderInvalid, 10%) !important;
          }
          &::placeholder {
            color: darken($inputTextPlaceholderInvalid, 10%) !important;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  > textarea:read-only {
    cursor: default;
    pointer-events: none;
    &::selection {
      pointer-events: none;
    }
    &:focus {
      background-color: $inputBg;
      border-color: $inputBorder;
      &::-webkit-input-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &:-moz-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &::-moz-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &:-ms-input-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &::placeholder {
        color: $inputTextPlaceholder !important;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        border-color: $inputBorder;
        &:focus {
          border-color: $inputBorder;
        }
        &::-webkit-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-ms-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::placeholder {
          color: $inputTextPlaceholder !important;
        }
      }
    }
  }
  > textarea[readonly=""] {
    cursor: default;
    pointer-events: none;
    &::selection {
      pointer-events: none;
    }
    &:focus {
      background-color: $inputBg;
      border-color: $inputBorder;
      &::-webkit-input-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &:-moz-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &::-moz-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &:-ms-input-placeholder {
        color: $inputTextPlaceholder !important;
      }
      &::placeholder {
        color: $inputTextPlaceholder !important;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        border-color: $inputBorder;
        &:focus {
          border-color: $inputBorder;
        }
        &::-webkit-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-ms-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::placeholder {
          color: $inputTextPlaceholder !important;
        }
      }
    }
  }
  &.invalid > textarea:read-only {
    &:focus {
      border-color: $inputBorderInvalid;
      color: $inputTextInvalid;
      &::-webkit-input-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:-moz-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &::-moz-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:-ms-input-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &::placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        border-color: $inputBorderInvalid;
        color: $inputTextInvalid;
        &:focus {
          color: $inputTextInvalid;
        }
        &::-webkit-input-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &:-moz-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &::-moz-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &:-ms-input-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &::placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
      }
    }
  }
  &.invalid > textarea[readonly=""] {
    &:focus {
      border-color: $inputBorderInvalid;
      color: $inputTextInvalid;
      &::-webkit-input-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:-moz-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &::-moz-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &:-ms-input-placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
      &::placeholder {
        color: $inputTextPlaceholderInvalid !important;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        border-color: $inputBorderInvalid;
        color: $inputTextInvalid;
        &:focus {
          color: $inputTextInvalid;
        }
        &::-webkit-input-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &:-moz-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &::-moz-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &:-ms-input-placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
        &::placeholder {
          color: $inputTextPlaceholderInvalid !important;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    textarea {
      background-color: $inputBg;
      border-color: $inputBorderDisabled;
      color: $inputTextDisabled;
      pointer-events: none;
      &:focus {
        &::-webkit-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-ms-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::placeholder {
          color: $inputTextPlaceholder !important;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          border-color: $inputBorderDisabled;
          &::-webkit-input-placeholder {
            color: $inputTextPlaceholder !important;
          }
          &:-moz-placeholder {
            color: $inputTextPlaceholder !important;
          }
          &::-moz-placeholder {
            color: $inputTextPlaceholder !important;
          }
          &:-ms-input-placeholder {
            color: $inputTextPlaceholder !important;
          }
          &::placeholder {
            color: $inputTextPlaceholder !important;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* VRM */
  /* ---------------------------------------------------------------- */
  &.textarea-vrm {
    flex-shrink: 0;
    width: 152px;
    textarea {
      background-color: $inputVrmBg;
      border-color: $inputVrmBorder;
      font-family: $vrm;
      font-size: 32px;
      line-height: 43px;
      padding: 7px 16px;
      text-align: center;
      text-transform: uppercase;
      &:not(:placeholder-shown) {
        padding-left: 30px;
        padding-right: 30px;
      }
      &::-webkit-input-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
        text-transform: uppercase !important;
      }
      &:-moz-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
        text-transform: uppercase !important;
      }
      &::-moz-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
        text-transform: uppercase !important;
      }
      &:-ms-input-placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
        text-transform: uppercase !important;
      }
      &::placeholder {
        color: darken($inputTextPlaceholder, 15%) !important;
        text-transform: uppercase !important;
      }
      &:focus {
        background-color: rgba($inputVrmBg, 0.85);
        border-color: $inputBorderFocus;
        &::-webkit-input-placeholder {
          color: darken($inputTextPlaceholder, 25%) !important;
        }
        &:-moz-placeholder {
          color: darken($inputTextPlaceholder, 25%) !important;
        }
        &::-moz-placeholder {
          color: darken($inputTextPlaceholder, 25%) !important;
        }
        &:-ms-input-placeholder {
          color: darken($inputTextPlaceholder, 25%) !important;
        }
        &::placeholder {
          color: darken($inputTextPlaceholder, 25%) !important;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          &::-webkit-input-placeholder {
            color: darken($inputTextPlaceholder, 25%) !important;
          }
          &:-moz-placeholder {
            color: darken($inputTextPlaceholder, 25%) !important;
          }
          &::-moz-placeholder {
            color: darken($inputTextPlaceholder, 25%) !important;
          }
          &:-ms-input-placeholder {
            color: darken($inputTextPlaceholder, 25%) !important;
          }
          &::placeholder {
            color: darken($inputTextPlaceholder, 25%) !important;
          }
        }
      }
    }
    &.disabled {
      textarea {
        background-color: $inputBg;
        border-color: $inputBorderDisabled;
        &::-webkit-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::-moz-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &:-ms-input-placeholder {
          color: $inputTextPlaceholder !important;
        }
        &::placeholder {
          color: $inputTextPlaceholder !important;
        }
        :global(.platform-desktop) & {
          &:hover {
            &::-webkit-input-placeholder {
              color: $inputTextPlaceholder !important;
            }
            &:-moz-placeholder {
              color: $inputTextPlaceholder !important;
            }
            &::-moz-placeholder {
              color: $inputTextPlaceholder !important;
            }
            &:-ms-input-placeholder {
              color: $inputTextPlaceholder !important;
            }
            &::placeholder {
              color: $inputTextPlaceholder !important;
            }
          }
        }
      }
    }
    &.textarea-vrm {
      textarea[readonly=""] {
        &:focus {
          background-color: $inputVrmBg;
          border-color: $inputVrmBorder;
        }
      }
      textarea:read-only {
        &:focus {
          background-color: $inputVrmBg;
          border-color: $inputVrmBorder;
        }
      }
    }
    &.invalid {
      textarea[readonly=""] {
        &:focus {
          border-color: $inputBorderInvalid;
        }
      }
      textarea:read-only {
        &:focus {
          border-color: $inputBorderInvalid;
        }
      }
      textarea {
        &::-webkit-input-placeholder {
          color: darken($inputTextPlaceholderInvalid, 17.5%) !important;
        }
        &:-moz-placeholder {
          color: darken($inputTextPlaceholderInvalid, 17.5%) !important;
        }
        &::-moz-placeholder {
          color: darken($inputTextPlaceholderInvalid, 17.5%) !important;
        }
        &:-ms-input-placeholder {
          color: darken($inputTextPlaceholderInvalid, 17.5%) !important;
        }
        &::placeholder {
          color: darken($inputTextPlaceholderInvalid, 17.5%) !important;
        }
        &:focus {
          &::-webkit-input-placeholder {
            color: $inputTextInvalid !important;
          }
          &:-moz-placeholder {
            color: $inputTextInvalid !important;
          }
          &::-moz-placeholder {
            color: $inputTextInvalid !important;
          }
          &:-ms-input-placeholder {
            color: $inputTextInvalid !important;
          }
          &::placeholder {
            color: $inputTextInvalid !important;
          }
        }
        :global(.platform-desktop) & {
          &:hover {
            &::-webkit-input-placeholder {
              color: $inputTextInvalid !important;
            }
            &:-moz-placeholder {
              color: $inputTextInvalid !important;
            }
            &::-moz-placeholder {
              color: $inputTextInvalid !important;
            }
            &:-ms-input-placeholder {
              color: $inputTextInvalid !important;
            }
            &::placeholder {
              color: $inputTextInvalid !important;
            }
          }
        }
      }
    }
  }
}
