@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Annual Mileage */
/* ---------------------------------------------------------------- */
.annual-mileage {
  > svg {
    height: 20px;
    margin: 0 8px 0 0;
    position: absolute;
    width: 20px;
    @include no-user-select;
    > * {
      fill: $primary;
      @include fast-transition($props: "fill");
    }
  }
  > span {
    display: block;
    padding-left: 28px;
  }
}
