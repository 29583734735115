@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Autocomplete Container */
/* ---------------------------------------------------------------- */
.autocomplete-container {
  position: relative;
  width: 100%;
}
/* ---------------------------------------------------------------- */
/* Autocomplete List */
/* ---------------------------------------------------------------- */
.autocomplete-list {
  display: block;
  left: 0;
  position: absolute;
  top: 54px;
  width: 100%;
  z-index: 10;
  /* -------------------------------------------------------------- */
  /* Autocomplete List - Container */
  /* -------------------------------------------------------------- */
  > div {
    background-color: $autocompleteBg;
    border-color: $autocompleteBorder;
    border-radius: 4px 4px 4px 4px;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    max-height: 212px;
    overflow: hidden;
    position: relative;
    will-change: transform;
    /* ------------------------------------------------------------ */
    /* Autocomplete List - Container - Scroll Container */
    /* ------------------------------------------------------------ */
    > ul {
      display: block;
      max-height: 208px;
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      position: relative;
      scrollbar-color: $autocompleteScrollBarThumb $autocompleteScrollBar;
      scrollbar-width: thin;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        scrollbar-3dlight-color: $autocompleteScrollBarThumb;
        scrollbar-arrow-color: $autocompleteScrollBarThumb;
        scrollbar-base-color: $autocompleteScrollBar;
        scrollbar-face-color: $autocompleteScrollBarThumb;
        scrollbar-highlight-color: $autocompleteScrollBarThumb;
        scrollbar-shadow-color: $autocompleteScrollBarThumb;
        scrollbar-track-color: $autocompleteScrollBar;
      }
      :global(.platform-windows) &,
      :global(.platform-mobile) & {
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }
        &::-webkit-scrollbar-track {
          background: $autocompleteScrollBar;
          border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $autocompleteScrollBarThumb;
          border-radius: 4px;
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      }
      /* ---------------------------------------------------------- */
      /* Autocomplete List - Container - Scroll Container  - Item   */
      /* ---------------------------------------------------------- */
      > li {
        background-color: $autocompleteBg;
        border-top: 2px solid $autocompleteBorder;
        box-sizing: border-box;
        color: $autocompleteText;
        cursor: pointer;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 16px;
        position: relative;
        width: 100%;
        @include slow-transition($mob: true, $props: "background-color");
        &:first-child {
          border-color: transparent;
          padding-top: 8px;
        }
        &:global(.Mui-focused) {
          background-color: $autocompleteTextHover;
        }
        &[aria-selected="true"] {
          color: $autocompleteTextSelected;
          font-family: $semibold;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Above */
  /* -------------------------------------------------------------- */
  &.above {
    bottom: 54px;
    top: auto;
    > div {
      box-shadow: 0 -2px 5px 2px rgba(0, 0, 0, 0.1);
    }
  }
}
