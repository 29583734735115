@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Navigation */
/* ---------------------------------------------------------------- */
.navigation {
  background-color: $text;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  width: 290px;
  z-index: 999;
  + main {
    left: 290px;
    &::before {
      background-color: rgba($pageBg, 0.5);
      bottom: 0;
      content: "";
      left: 290px;
      position: fixed;
      right: 0;
      z-index: 999;
    }
  }
  > ul {
    box-sizing: border-box;
    -ms-overflow-style: none;
    overflow-y: auto;
    padding: 10px 20px;
    scrollbar-width: none;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
    > li {
      &:last-child {
        > a {
          border: 0;
        }
      }
      > a {
        border-bottom: 1px solid rgba($footerCopyrightText, 0.3);
        color: $adminLink;
        cursor: pointer;
        display: block;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 0;
        text-transform: capitalize;
        white-space: nowrap;
        @include no-user-select;
        &[aria-current="page"] {
          color: $primaryButtonBg;
          cursor: auto;
        }
        :global(.platform-desktop) & {
          &:hover {
            color: $primaryButtonBg;
          }
        }
      }
    }
  }
}
