@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Header Logo */
/* ---------------------------------------------------------------- */
.header-logo {
  height: 36px;
  position: relative;
  @media (max-width: 1024px) {
    height: 32px;
  }
  @media (max-width: 767px) {
    height: 20px;
    padding-bottom: 4px;
  }
  @media (max-width: 405px) {
    height: auto;
  }
  /* ---------------------------------------------------------------- */
  /* Header Logo - Default */
  /* ---------------------------------------------------------------- */
  > .header-logo-default {
    align-items: flex-end;
    display: flex;
    height: 100%;
    @media (max-width: 405px) {
      align-items: flex-start;
      flex-direction: column;
    }
    /* ---------------------------------------------------------------- */
    /* Header Logo - Default - Logo */
    /* ---------------------------------------------------------------- */
    > button,
    > div[role="button"] {
      cursor: pointer;
      :global(.platform-desktop) & {
        &:hover {
          svg * {
            fill: darken($headerText, 10%);
          }
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          svg * {
            fill: darken($headerText, 10%);
          }
        }
      }
      &:focus {
        outline: 2px solid $headerLogoFocus;
        outline-offset: 1px;
      }
    }
    > button,
    > div[role="button"],
    > svg,
    > button > svg,
    > div[role="button"] > svg {
      height: 36px;
      width: 124px;
      @media (max-width: 1024px) {
        height: 32px;
        width: 110px;
      }
      @media (max-width: 767px) {
        height: 20px;
        width: 69px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Header Logo - Default - Text */
    /* ---------------------------------------------------------------- */
    > p {
      color: $headerText;
      font-size: 15px;
      line-height: 19px;
      margin: 0 0 -4px 12px;
      text-transform: uppercase;
      white-space: nowrap;
      @media (max-width: 1024px) {
        font-size: 13px;
        line-height: 17px;
        margin-left: 9px;
      }
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 16px;
        margin-left: 6px;
      }
      @media (max-width: 405px) {
        margin-left: 0;
        margin-top: 1px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header Logo - Partner */
  /* ---------------------------------------------------------------- */
  > .header-logo-partner {
    display: flex;
    height: 100%;
    /* ---------------------------------------------------------------- */
    /* Header Logo - Partner - Columns */
    /* ---------------------------------------------------------------- */
    > div {
      align-items: flex-end;
      display: flex;
      height: 100%;
      position: relative;
      @media (max-width: 767px) {
        align-items: flex-start;
        flex-direction: column;
      }
      &:first-child {
        padding-right: 15px;
        @media (max-width: 1024px) {
          padding-right: 10px;
        }
        @media (max-width: 767px) {
          padding-right: 7.5px;
        }
      }
      &:last-child {
        margin-left: 1px;
        padding-left: 15px;
        @media (max-width: 1024px) {
          padding-left: 10px;
        }
        @media (max-width: 767px) {
          padding-left: 7.5px;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Header Logo - Partner - Right - Seperator */
      /* ---------------------------------------------------------------- */
      &:last-child::after {
        background-color: white;
        bottom: 0;
        content: "";
        height: 30px;
        left: -1px;
        position: absolute;
        width: 1px;
        @media (max-width: 1024px) {
          height: 29px;
        }
        @media (max-width: 767px) {
          height: 18px;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Header Logo - Partner - Logo */
      /* ---------------------------------------------------------------- */
      > button,
      > div[role="button"] {
        cursor: pointer;
        :global(.platform-desktop) & {
          &:hover {
            svg * {
              fill: darken($headerText, 10%);
            }
          }
        }
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          &:active {
            svg * {
              fill: darken($headerText, 10%);
            }
          }
        }
        &:focus {
          outline: 2px solid $headerLogoFocus;
          outline-offset: 1px;
        }
      }
      > button,
      > div[role="button"],
      > svg,
      > button > svg,
      > div[role="button"] > svg {
        height: 30px;
        width: 104px;
        @media (max-width: 1024px) {
          height: 29px;
          width: 100px;
        }
        @media (max-width: 767px) {
          height: 18px;
          width: 62px;
        }
        /* ---------------------------------------------------------------- */
        /* Header Logo - Partner - Right - Logo - Herald */
        /* ---------------------------------------------------------------- */
        &.header-logo-partner-herald {
          height: 35px;
          margin-bottom: -6px;
          position: relative;
          top: 1px;
          width: 162px;
          @media (max-width: 1024px) {
            height: 34px;
            margin-bottom: -6px;
            width: 157px;
          }
          @media (max-width: 767px) {
            height: 21px;
            margin-bottom: -4px;
            width: 97px;
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Header Logo - Partner - Text */
      /* ---------------------------------------------------------------- */
      > p {
        color: $headerTextPartner;
        font-size: 10px;
        line-height: 10px;
        position: absolute;
        text-transform: uppercase;
        top: -7px;
        white-space: nowrap;
        @media (max-width: 1024px) {
          font-size: 9px;
          line-height: 9px;
          top: -10px;
        }
        @media (max-width: 767px) {
          font-size: 8px;
          line-height: 8px;
          margin-bottom: 3px;
          position: relative;
          top: auto;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Partner logo */
  /* ---------------------------------------------------------------- */
  &.partner-logo {
    @media (max-width: 767px) {
      height: auto;
    }
    /* ---------------------------------------------------------------- */
    /* Trustpilot */
    /* ---------------------------------------------------------------- */
    + a {
      @media (max-width: 767px) {
        margin: 14px 0 0;
      }
    }
  }
}
