@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Footer */
/* ---------------------------------------------------------------- */
.policy-footer {
  background-color: $productBg;
  border-top: 2px solid $productShoppingCartBorder;
  bottom: 0;
  box-shadow: 0 -1px 6px rgba($text, 0.6);
  left: 0;
  min-width: 320px;
  padding: 17px 0 20px;
  position: fixed;
  width: 100%;
  z-index: 99;
  @media (max-width: 1240px) {
    padding: 13px 0 16px;
  }
  @media (max-width: 767px) {
    height: auto;
    padding: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Container */
  /* ---------------------------------------------------------------- */
  > div {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    max-width: 1360px;
    padding: 0 32px;
    @media (max-width: 1024px) {
      padding: 0 24px;
    }
    @media (max-width: 767px) {
      padding: 0 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Button Row */
    /* ---------------------------------------------------------------- */
    > div {
      display: flex;
      width: 100%;
      @media (max-width: 767px) {
        align-items: center;
        flex-direction: column;
      }
      /* ---------------------------------------------------------------- */
      /* Price & Confirm Button */
      /* ---------------------------------------------------------------- */
      .confirm {
        align-items: center;
        display: flex;
        height: 54px;
        margin-left: auto;
        @media (max-width: 767px) {
          background-color: $productPrimaryBg;
          height: 72px;
          justify-content: center;
          margin: 0 -16px;
          width: calc(100% + 32px);
        }
        @media (max-width: 440px) {
          box-sizing: border-box;
          padding: 0 16px;
        }
        > div:not([role="button"]) {
          font-family: $bold;
          margin: 0 15px 0 0;
          @media (max-width: 1024px) {
            margin: 0 11px 0 0;
          }
          @media (max-width: 767px) {
            margin: 0 15px 0 0;
          }
          @media (max-width: 440px) {
            align-items: center;
            display: flex;
            margin: 0 8px 0 0;
            width: calc(50% - 4px);
          }
          > strong {
            font-size: 38px;
            line-height: 46px;
            @media (max-width: 1240px) {
              font-size: 30px;
              line-height: 38px;
            }
            @media (max-width: 1024px) {
              font-size: 28px;
              line-height: 36px;
            }
            @media (max-width: 940px) {
              font-size: 24px;
              line-height: 32px;
            }
            @media (max-width: 767px) {
              font-size: 22px;
              line-height: 27px;
            }
            @media (max-width: 365px) {
              font-size: 18px;
              line-height: 23px;
            }
          }
          > span {
            font-size: 30px;
            line-height: 37px;
            margin: 0 0 0 8px;
            @media (max-width: 1240px) {
              font-size: 22px;
              line-height: 29px;
            }
            @media (max-width: 1024px) {
              font-size: 20px;
              line-height: 27px;
            }
            @media (max-width: 940px) {
              font-size: 16px;
              line-height: 28px;
            }
            @media (max-width: 767px) {
              font-size: 14px;
              line-height: 17px;
            }
            @media (max-width: 365px) {
              font-size: 12px;
              line-height: 15px;
            }
          }
        }
        > div[role="button"],
        > button {
          /* ---------------------------------------------------------------- */
          /* Desktop */
          /* ---------------------------------------------------------------- */
          &:nth-child(2) {
            @media (max-width: 1024px) {
              padding: 0 16px;
            }
            @media (max-width: 940px) {
              padding: 0 12px;
            }
            @media (max-width: 767px) {
              padding: 0 24px;
            }
            @media (max-width: 440px) {
              width: calc(50% - 4px);
            }
            > span {
              @media (max-width: 1024px) {
                font-size: 15px;
              }
              @media (max-width: 940px) {
                font-size: 14px;
              }
              @media (max-width: 767px) {
                font-size: 16px;
              }
              &:nth-child(2) {
                margin-left: 5px;
                @media (max-width: 840px) {
                  display: none;
                }
              }
            }
          }
          span {
            order: 1;
          }
          svg {
            margin: 0 0 0 8px;
            order: 2;
            width: 8px;
            @media (max-width: 1024px) {
              width: 7px;
            }
            @media (max-width: 767px) {
              width: 8px;
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Instalments */
  /* ---------------------------------------------------------------- */
  &.instalments {
    > div > div > .confirm {
      height: auto;
      margin-top: 17px;
      @media (max-width: 767px) {
        height: 70px;
        margin-top: 0;
      }
    }
  }
}
