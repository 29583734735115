@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Live Chat */
/* ---------------------------------------------------------------- */
.live-chat {
  align-items: center;
  background-color: $headerRetrieveQuoteBg;
  border-radius: 10px;
  display: none;
  margin: 0 0 -1px;
  padding: 8px;
  @media (max-width: 1440px) {
    display: flex;
  }
  @media (max-width: 767px) {
    display: none;
  }
  /* ---------------------------------------------------------------- */
  /* Live Chat - Button */
  /* ---------------------------------------------------------------- */
  > [role="button"],
  > button {
    height: 36px;
    padding: 0 16px;
    @media (max-width: 1024px) {
      height: 32px;
      padding: 0 12px;
    }
    /* ---------------------------------------------------------------- */
    /* Live Chat - Button - Text */
    /* ---------------------------------------------------------------- */
    span {
      font-size: 16px;
    }
  }
}
