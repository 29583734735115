@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Product - Partners */
/* ---------------------------------------------------------------- */
.product-partners {
  border: none;
  display: none;
  height: 0;
  width: 0;
}
