@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Footer */
/* ---------------------------------------------------------------- */
.footer {
  background: $footerBg;
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 27px 0 46px;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 27px 0 38px;
  }
  @media (max-width: 767px) {
    padding: 20px 0 21px;
  }
  /* ---------------------------------------------------------------- */
  /* Footer - Container */
  /* ---------------------------------------------------------------- */
  > div {
    box-sizing: border-box;
    display: grid;
    grid-gap: 24px;
    height: 100%;
    margin: 0 auto;
    max-width: 1360px;
    padding: 0 32px;
    position: relative;
    @media (max-width: 1024px) {
      padding: 0 24px;
    }
    @media (max-width: 767px) {
      grid-gap: 18px;
      padding: 0 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Text Container */
    /* ---------------------------------------------------------------- */
    .text-container {
      border-bottom: 1px solid $white;
      display: grid;
      grid-gap: 16px;
      padding-bottom: 24px;
      @media (max-width: 767px) {
        padding-bottom: 18px;
      }
      /* ---------------------------------------------------------------- */
      /* Button */
      /* ---------------------------------------------------------------- */
      button {
        color: inherit;
        display: inline;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
      }
      /* ---------------------------------------------------------------- */
      /* Text */
      /* ---------------------------------------------------------------- */
      p {
        color: $white;
        font-family: $regular;
        font-size: 16px;
        line-height: 20px;
        margin-block: -2px -5px;
        @media (max-width: 1024px) {
          font-size: 15px;
          line-height: 19px;
          margin-block: -2px -4px;
        }
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
          margin-block: -2px -4px;
        }
        strong {
          font-family: $medium;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Footer - Container - Logo Row */
    /* ---------------------------------------------------------------- */
    .logo-container {
      align-items: center;
      display: flex;
      @media (max-width: 840px) {
        align-items: flex-start;
        flex-direction: column;
      }
      > div {
        /* ---------------------------------------------------------------- */
        /* Left / Top Icon List */
        /* ---------------------------------------------------------------- */
        &:nth-child(1) {
          align-items: center;
          display: flex;
          margin-right: 24px;
          @media (max-width: 600px) {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
          }
          > div {
            align-items: center;
            display: flex;
            height: 100%;
            &:nth-child(1) {
              > svg {
                /* ---------------------------------------------------------------- */
                /* Biba Icon */
                /* ---------------------------------------------------------------- */
                &:nth-child(1) {
                  height: 37px;
                  margin-right: 24px;
                  width: 70px;
                  @media (max-width: 352px) {
                    height: 32px;
                    width: 60px;
                  }
                }
                /* ---------------------------------------------------------------- */
                /* BMF Icon */
                /* ---------------------------------------------------------------- */
                &:nth-child(2) {
                  height: 32px;
                  margin-right: 24px;
                  width: 64px;
                }
                /* ---------------------------------------------------------------- */
                /* MCIA Icon */
                /* ---------------------------------------------------------------- */
                &:nth-child(3) {
                  height: 37px;
                  margin-right: 24px;
                  width: 125px;
                  @media (max-width: 600px) {
                    margin-right: 0;
                  }
                  @media (max-width: 352px) {
                    height: 32px;
                    width: 108px;
                  }
                }
              }
            }
            &:nth-child(2) {
              @media (max-width: 600px) {
                margin-top: 18px;
              }
              > svg {
                /* ---------------------------------------------------------------- */
                /* MAG Icon */
                /* ---------------------------------------------------------------- */
                &:nth-child(1) {
                  height: 37px;
                  margin-right: 24px;
                  width: 112px;
                }
                /* ---------------------------------------------------------------- */
                /* Bike Safe Icon */
                /* ---------------------------------------------------------------- */
                &:nth-child(2) {
                  height: 37px;
                  width: 64px;
                }
              }
            }
          }
        }
        /* ---------------------------------------------------------------- */
        /* Right / Bottom Icon List */
        /* ---------------------------------------------------------------- */
        &:nth-child(2) {
          align-items: center;
          display: flex;
          margin-left: auto;
          @media (max-width: 840px) {
            margin: 18px 0 0 0;
          }
          /* ---------------------------------------------------------------- */
          /* Globalsign Icon */
          /* ---------------------------------------------------------------- */
          > svg {
            height: 39px;
            width: 100px;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Footer - Container - Copyright Text */
    /* ---------------------------------------------------------------- */
    > p {
      color: $footerCopyrightText;
      font-family: $regular;
      font-size: 12px;
      line-height: 15px;
      margin-top: auto;
      > a {
        color: $footerCopyrightText;
        text-decoration: underline;
        text-decoration-color: $footerCopyrightText;
        text-decoration-style: dotted;
        :global(.platform-desktop) & {
          &:hover {
            color: darken($footerCopyrightText, 10%);
          }
        }
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          &:active {
            color: darken($footerCopyrightText, 20%);
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Footer - Container - Environment */
    /* ---------------------------------------------------------------- */
    > small {
      background: $footerBg;
      color: $footerCopyrightText;
      display: none;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 11px;
      font-weight: 600;
      left: 27px;
      line-height: 11px;
      padding: 5px 5px 5px 5px;
      position: absolute;
      top: -36px;
      > span {
        color: white;
      }
      > span + span {
        margin-left: 10px;
      }
      @media (max-width: 1024px) {
        left: 19px;
      }
      @media (max-width: 767px) {
        left: 11px;
        top: -30px;
      }
    }
  }
  &.admin > div > small {
    display: block;
  }
  &.confirmation > div > small {
    @media (max-width: 767px) {
      top: -114px;
    }
    @media (max-width: 640px) {
      top: -110px;
    }
  }
}
