@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Confirmation - Competition */
/* ---------------------------------------------------------------- */
.confirmation-competition {
  background-color: $confirmationCompetitionBg;
  background-image: url("/images/competition.svg");
  background-position: center;
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  box-sizing: border-box;
  display: block;
  margin-top: 20px;
  overflow: hidden;
  padding: 48px 197px 48px 48px;
  position: relative;
  will-change: transform;
  @include fast-transition($props: "background-color");
  @media (max-width: 1024px) {
    padding: 37px 174px 38px 36px;
  }
  @media (max-width: 880px) {
    padding: 36px 145px 37px 36px;
  }
  @media (max-width: 767px) {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 20px 134px 21px 16px;
  }
  @media (max-width: 600px) {
    padding-right: 16px;
  }
  /* ---------------------------------------------------------------- */
  /* Image */
  /* ---------------------------------------------------------------- */
  &::after {
    background-image: url("/images/competition.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    content: "";
    display: block;
    height: 100%;
    padding-left: 25%;
    position: absolute;
    right: 0;
    z-index: 0;
    @media (max-width: 600px) {
      content: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Focus */
  /* ---------------------------------------------------------------- */
  &:focus {
    background-color: $confirmationCompetitionBgHover;
    p,
    h4 {
      text-decoration: underline;
      text-decoration-color: $white;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Hover */
  /* ---------------------------------------------------------------- */
  :global(.platform-desktop) & {
    &:hover {
      background-color: $confirmationCompetitionBgHover;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Active */
  /* ---------------------------------------------------------------- */
  :global(.platform-desktop) &,
  :global(.platform-mobile) & {
    &:active {
      background-color: $confirmationCompetitionBgActive;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > h3 {
    color: $confirmationCompetitionText;
    font-family: $bold;
    font-size: 22px;
    line-height: 26px;
    margin: -4px 0 10px;
    position: relative;
    z-index: 1;
    @media (max-width: 1024px) {
      font-size: 21px;
      line-height: 25px;
    }
    @media (max-width: 880px) {
      font-size: 19px;
      line-height: 23px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 6px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  > p {
    color: $white;
    font-family: $bold;
    line-height: 20px;
    margin: 0 0 16px;
    position: relative;
    z-index: 1;
    @media (max-width: 880px) {
      font-size: 15px;
      line-height: 19px;
    }
    &:last-child {
      margin: 0 0 -5px;
    }
  }
}
