@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Header Times */
/* ---------------------------------------------------------------- */
.header-times {
  cursor: default;
  margin: 0 0 3px;
  position: relative;
  @media (min-width: 768px) {
    &:last-child {
      margin: 0;
    }
  }
  @media (max-width: 767px) {
    margin: 9px 0 -2px;
  }
  /* ---------------------------------------------------------------- */
  /* Header Times - Container */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    color: $headerText;
    display: flex;
    font-family: $bold;
    font-size: 16px;
    line-height: 22px;
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 20px;
    }
    &:first-child {
      /* ---------------------------------------------------------------- */
      /* Header Times - Clock Icon */
      /* ---------------------------------------------------------------- */
      > svg {
        display: none;
        height: 15px;
        margin: 0 6px 0 0;
        width: 15px;
        @media (max-width: 767px) {
          display: block;
        }
        * {
          fill: $headerText;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Header Times - Container - Button */
    /* ---------------------------------------------------------------- */
    > div[role="button"],
    button {
      align-items: center;
      color: $headerText;
      cursor: pointer;
      display: flex;
      position: relative;
      @include fast-transition($props: "color");
      &:focus {
        color: darken($headerText, 10%);
        text-decoration: underline;
        text-decoration-color: darken($headerText, 10%);
        svg:nth-child(2) * {
          fill: $primaryButtonBgHover;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          color: darken($headerText, 10%);
          svg:nth-child(2) * {
            fill: $primaryButtonBgHover;
          }
        }
      }
      :global(.platform-desktop) &,
      :global(.platform-mobile) & {
        &:active {
          color: darken($headerText, 20%);
          svg:nth-child(2) * {
            fill: $primaryButtonBgActive;
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Chevron Icon */
      /* ---------------------------------------------------------------- */
      > svg {
        cursor: pointer;
        flex-shrink: 0;
        height: 11px;
        order: 2;
        transform-origin: 50% 50%;
        width: 12px;
        @include slow-transition($mob: true, $props: "transform");
        * {
          fill: $headerIcon;
          @include slow-transition($props: "fill");
        }
      }
      /* ---------------------------------------------------------------- */
      /* Modal Arrow Icon Container */
      /* ---------------------------------------------------------------- */
      > span:nth-child(2) {
        height: 9px;
        left: 50%;
        order: 3;
        position: absolute;
        top: 23px;
        transform: translateX(-50%);
        width: 18px;
        @media (max-width: 1024px) {
          height: 6px;
          top: 20px;
          width: 12px;
        }
        /* ---------------------------------------------------------------- */
        /* Modal Arrow Icon */
        /* ---------------------------------------------------------------- */
        > svg {
          height: 100%;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          transform: scale(0);
          transform-origin: 50% 100%;
          visibility: hidden;
          width: 100%;
          will-change: transform, opacity, visibility;
          @include slow-transition($mob: true, $timing: cubic-bezier(0.55, 0, 0.55, 0.2));
          * {
            fill: $headerTimesBg;
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Header Times - Container - Button - Text */
      /* ---------------------------------------------------------------- */
      > span:nth-child(3) {
        color: $headerText;
        font-family: $bold;
        font-size: 16px;
        line-height: 22px;
        margin: 0 6px 0 0;
        order: 1;
        @media (max-width: 1024px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Header Times - Container - Text */
    /* ---------------------------------------------------------------- */
    > span {
      margin: 0 5px 0 0;
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header Times - Modal */
  /* ---------------------------------------------------------------- */
  > ul {
    background-color: $headerTimesBg;
    border-radius: 12px;
    box-shadow: 0 6px 6px 0 rgba($headerTimesBoxShadow, 0.15);
    box-sizing: border-box;
    opacity: 0;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 31px;
    transform: scale(0);
    transform-origin: calc(100% - 60px) 0;
    visibility: hidden;
    width: 270px;
    will-change: transform, opacity, visibility;
    z-index: 9999;
    @include slow-transition($mob: true, $timing: cubic-bezier(0.55, 0, 0.55, 0.2));
    @media (max-width: 1024px) {
      top: 25px;
    }
    @media (max-width: 767px) {
      padding: 16px;
      width: 240px;
    }
    /* ---------------------------------------------------------------- */
    /* Header Times - Modal - Text */
    /* ---------------------------------------------------------------- */
    > li {
      display: flex;
      margin: 0 0 8px;
      @media (max-width: 767px) {
        margin: 0 0 4px;
      }
      &:last-child {
        margin: 0;
      }
      > span {
        color: $headerTimesText;
        flex-shrink: 0;
        font-family: $bold;
        line-height: 20px;
        @media (max-width: 767px) {
          font-size: 15px;
          line-height: 19px;
        }
        /* ---------------------------------------------------------------- */
        /* Header Times - Modal - Text - Days */
        /* ---------------------------------------------------------------- */
        &:nth-child(1) {
          font-family: $medium;
          margin: 0 20px 0 0;
          @media (max-width: 767px) {
            margin: 0 16px 0 0;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Header Times - Modal - Text - Hours */
        /* ---------------------------------------------------------------- */
        &:nth-child(2) {
          margin-left: auto;
        }
        &:nth-child(3) {
          margin: 0 4px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Show Times */
  /* ---------------------------------------------------------------- */
  &.show-times {
    > div {
      > div[role="button"],
      > button {
        > svg {
          transform: rotate(180deg);
          @include slow-transition($mob: true, $props: "transform");
        }
        > span:nth-child(2) > svg {
          opacity: 1;
          transform: scale(1);
          visibility: visible;
          @include slow-transition($mob: true, $timing: cubic-bezier(0, 0, 0.2, 1));
        }
      }
    }
    > ul {
      opacity: 1;
      transform: scale(1);
      visibility: visible;
      @include slow-transition($mob: true, $timing: cubic-bezier(0, 0, 0.2, 1));
    }
  }
}
