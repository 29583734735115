@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Quote Cart */
/* ---------------------------------------------------------------- */
.quote-cart {
  background-color: $quoteBg;
  border-radius: 12px;
  box-sizing: border-box;
  flex-basis: 432px;
  flex-shrink: 0;
  margin-left: 16px;
  max-width: 432px;
  padding: 32px;
  position: relative;
  @media (max-width: 1350px) {
    flex-basis: calc(35% - 12px);
    margin-left: 12px;
    max-width: calc(35% - 12px);
    padding: 24px;
  }
  @media (max-width: 1230px) {
    padding: 20px;
  }
  @media (max-width: 1024px) {
    flex-basis: calc(35% - 8px);
    margin-left: 8px;
    max-width: calc(35% - 8px);
  }
  @media (max-width: 767px) {
    flex-basis: auto;
    margin: 0 16px 32px;
    max-width: calc(100% - 32px);
    padding: 32px 16px;
  }
  @media (max-width: 440px) {
    margin: 0 16px 24px;
    padding: 24px 16px;
  }
  /* ---------------------------------------------------------------- */
  /* Your Quote */
  /* ---------------------------------------------------------------- */
  > h1 {
    color: $quotePrice;
    margin: 0 0 14px;
    @media (max-width: 767px) {
      text-align: center;
    }
    > span {
      display: block;
      font-family: $bold;
      font-size: 20px;
      line-height: 24px;
      @media (max-width: 1230px), (max-width: 440px) {
        font-size: 19px;
        line-height: 23px;
      }
      @media (max-width: 1134px) and (min-width: 921px) {
        font-size: 18px;
        line-height: 22px;
      }
      @media (max-width: 920px) and (min-width: 768px) {
        font-size: 15px;
        line-height: 20px;
      }
      @media (max-width: 767px) and (min-width: 441px) {
        font-size: 20px;
        line-height: 24px;
      }
      &:nth-child(1) {
        margin: -5px 0 3px;
        @media (max-width: 1134px) and (min-width: 921px) {
          margin: -5px 0 0;
        }
        @media (max-width: 920px) and (min-width: 768px) {
          margin: -5px 0 1px;
        }
        @media (max-width: 767px) and (min-width: 441px) {
          margin: -5px 0 3px;
        }
      }
      &:nth-child(2) {
        margin: 0 0 2px;
        @media (max-width: 1134px) and (min-width: 921px) {
          margin: 0 0 -2px;
        }
        @media (max-width: 920px) and (min-width: 768px) {
          margin: 0 0 -1px;
        }
        @media (max-width: 767px) and (min-width: 441px) {
          margin: 0 0 2px;
        }
        > strong {
          font-size: 32px;
          line-height: 39px;
          @media (max-width: 1230px), (max-width: 440px) {
            font-size: 30px;
            line-height: 37px;
          }
          @media (max-width: 1134px) and (min-width: 921px) {
            font-size: 28px;
            line-height: 35px;
          }
          @media (max-width: 920px) and (min-width: 768px) {
            font-size: 24px;
            line-height: 30px;
          }
          @media (max-width: 767px) and (min-width: 441px) {
            font-size: 32px;
            line-height: 39px;
          }
        }
        > span {
          white-space: nowrap;
        }
      }
      &:nth-child(3) {
        font-family: $regular;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  > div[role="button"],
  > button {
    height: auto;
    margin: 0 0 17px;
    padding-bottom: 13px;
    padding-top: 13px;
    width: 100%;
    span {
      font-family: $medium;
      > span {
        font-family: $bold;
        text-decoration: underline;
        text-decoration-color: $successButtonText;
      }
      @media (max-width: 920px) and (min-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Legals */
  /* ---------------------------------------------------------------- */
  > small {
    margin: 0 0 -3px;
    p {
      color: $quoteLegal;
      font-family: $regular;
      font-size: 12px;
      line-height: 15px;
      margin: 0 0 10px;
      @media (max-width: 1230px) {
        font-size: 11px;
        line-height: 14px;
      }
      &:last-child {
        margin: 0;
      }
    }
  }
}
