@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Product Breakdown */
/* ---------------------------------------------------------------- */
.product-breakdown {
  background-color: $productBg;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 24px;
  overflow: hidden;
  padding-left: 86px;
  position: relative;
  z-index: 1;
  @media (max-width: 1240px) {
    margin: 0 0 16px;
  }
  @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
    padding-left: 0;
  }
  @media (max-width: 1024px) {
    margin: 0 0 12px;
  }
  @media (max-width: 767px) {
    border-radius: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &::before {
    background-color: $productSecondaryBg;
    border-right: 1px solid $productSecondaryBorder;
    box-sizing: border-box;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 86px;
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
      content: none;
    }
  }
  /* -------------------------------------------------------------- */
  /* Header */
  /* -------------------------------------------------------------- */
  > header {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 20px;
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
      padding: 0;
    }
    @media (max-width: 767px) and (min-width: 671px) {
      padding: 16px;
    }
    > div {
      display: flex;
      &:nth-child(1) {
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          align-items: center;
          background-color: $productSecondaryBg;
          border-bottom: 1px solid $productSecondaryBorder;
          box-sizing: border-box;
          min-height: 64px;
          padding: 12px 20px;
        }
        @media (max-width: 350px) {
          min-height: 58px;
        }
        /* -------------------------------------------------------- */
        /* Header - Icon */
        /* -------------------------------------------------------- */
        > svg {
          flex-shrink: 0;
          height: 18px;
          left: 16px;
          position: absolute;
          top: 24px;
          width: 54px;
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            height: 16px;
            left: auto;
            position: relative;
            top: auto;
            width: 48px;
          }
          @media (max-width: 350px) {
            display: none;
          }
        }
        /* -------------------------------------------------------- */
        /* Header - Title */
        /* -------------------------------------------------------- */
        > h2 {
          font-family: $bold;
          font-size: 19px;
          line-height: 22px;
          margin: 0 0 12px;
          padding-right: 20px;
          width: 100%;
          @include user-select;
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            font-size: 18px;
            line-height: 21px;
            margin: 0;
            padding: 0 16px;
          }
          @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
            font-size: 16px;
            line-height: 19px;
          }
          @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
            font-size: 16px;
            line-height: 19px;
          }
          @media (max-width: 350px) {
            padding-left: 0;
          }
        }
        /* -------------------------------------------------------- */
        /* Header - Price */
        /* -------------------------------------------------------- */
        > div {
          color: $title;
          flex-shrink: 0;
          font-family: $bold;
          font-size: 24px;
          line-height: 24px;
          @include user-select;
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            font-size: 22px;
            line-height: 22px;
          }
          @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
            font-size: 18px;
            line-height: 18px;
          }
          @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
            font-size: 17px;
            line-height: 17px;
          }
          > span {
            font-size: 16px;
            line-height: 20px;
            @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
              font-size: 15px;
              line-height: 19px;
            }
            @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
              font-size: 15px;
              line-height: 19px;
            }
            @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
              font-size: 14px;
              line-height: 18px;
            }
            @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
              font-size: 13px;
              line-height: 17px;
            }
          }
        }
      }
      &:nth-child(2) {
        align-items: center;
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          padding: 12px 16px;
        }
        > p {
          line-height: 20px;
          padding-right: 20px;
          @include user-select;
          @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
            font-size: 15px;
            line-height: 19px;
          }
          @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
        > div[role="button"],
        > button {
          cursor: pointer;
          flex-shrink: 0;
          height: 14px;
          margin-left: auto;
          position: relative;
          width: 14px;
          &::after {
            border: 2px solid darken($productChevron, 10%);
            border-radius: 50%;
            content: "";
            flex-shrink: 0;
            height: calc(100% + 4px);
            left: 0;
            margin-left: -4px;
            margin-top: -5px;
            opacity: 0;
            position: absolute;
            top: 0;
            width: calc(100% + 4px);
            @include fast-transition($props: "opacity");
          }
          &:focus {
            &::after {
              opacity: 1;
            }
            svg * {
              fill: darken($productChevron, 10%);
            }
          }
          :global(.platform-desktop) & {
            &:hover {
              svg * {
                fill: darken($productChevron, 10%);
              }
            }
          }
          :global(.platform-desktop) &,
          :global(.platform-mobile) & {
            &:active {
              svg * {
                fill: darken($productChevron, 20%);
              }
            }
          }
          > svg {
            flex-shrink: 0;
            height: 14px;
            width: 14px;
            * {
              fill: $productChevron;
            }
          }
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Content Container */
  /* -------------------------------------------------------------- */
  > div {
    box-sizing: border-box;
    padding: 4px 20px 25px;
    position: relative;
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
      padding: 6px 16px 19px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Footer */
  /* -------------------------------------------------------------- */
  > footer {
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    padding: 0 20px 20px;
    width: 100%;
    @media (max-width: 767px) {
      padding: 0 16px 16px;
    }
    /* ------------------------------------------------------------ */
    /* Footer - Buttons */
    /* ------------------------------------------------------------ */
    > div[role="button"],
    > button {
      flex-shrink: 0;
      margin-left: auto;
      min-width: 170px;
      @media (max-width: 415px) {
        margin-left: 0;
        min-width: initial;
        width: 100%;
      }
      > svg {
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          height: 15px;
          width: 15px;
        }
        @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
          height: 14px;
          width: 14px;
        }
      }
      span {
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          font-size: 15px;
        }
        @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
          font-size: 14px;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Included */
  /* -------------------------------------------------------------- */
  &.included {
    /* ------------------------------------------------------------ */
    /* Header */
    /* ------------------------------------------------------------ */
    > header {
      > div:nth-child(1) {
        > div {
          > div {
            align-items: center;
            background-color: $productIncludedBg;
            border-radius: 20px;
            display: flex;
            padding: 3px 6px 3px 8px;
            @include no-user-select;
            @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
              position: absolute;
              right: 20px;
              top: 12px;
            }
            @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
              padding: 3px 6px 3px 8px;
            }
            @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
              padding: 3px 6px;
            }
            @media (max-width: 350px) {
              padding: 3px 6px 3px 8px;
            }
            > span {
              color: $productIncludedText;
              font-size: 16px;
              line-height: 16px;
              margin: 2px 5px 0 0;
              @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
                font-size: 15px;
                line-height: 15px;
                margin: 1px 5px 0 0;
              }
              @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
                font-size: 14px;
                line-height: 14px;
                margin: 0;
              }
              @media (max-width: 350px) {
                margin: 1px 5px 0 0;
              }
            }
            > svg {
              flex-shrink: 0;
              height: 14px;
              width: 14px;
              @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
                height: 12px;
                width: 12px;
              }
              @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
                display: none;
              }
              @media (max-width: 350px) {
                display: block;
              }
              > * {
                fill: $productIncludedText;
              }
            }
          }
        }
        > h2 {
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            padding: 36px 0 0;
          }
          @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
            padding: 32px 0 0;
          }
        }
        > svg {
          @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
            left: 20px;
            position: absolute;
            top: 12px;
          }
        }
      }
    }
    /* ------------------------------------------------------------ */
    /* Content */
    /* ------------------------------------------------------------ */
    > div {
      > * {
        @include user-select;
        &:first-child {
          margin-top: -3px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $title;
        font-size: 20px;
        line-height: 24px;
        margin: 0 0 15px;
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          font-size: 19px;
          line-height: 23px;
        }
        @media (max-width: 836px) and (min-width: 768px), (max-width: 414px) {
          font-size: 18px;
          line-height: 22px;
        }
      }
      strong {
        font-family: $bold;
      }
      p,
      li {
        font-size: 16px;
        line-height: 20px;
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          font-size: 15px;
          line-height: 19px;
        }
        @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
      p,
      ul,
      ol {
        margin: 0 0 15px;
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          margin: 0 0 10px;
        }
      }
      ul {
        li {
          padding-left: 16px;
          position: relative;
          &::before {
            background-color: $modalListBullet;
            border-radius: 50%;
            content: "";
            display: block;
            height: 8px;
            left: 0;
            position: absolute;
            top: 6px;
            width: 8px;
          }
        }
      }
      li {
        margin: 0 0 11px;
        &:last-child {
          margin: 0;
        }
      }
      blockquote {
        color: $title;
        font-family: $semibold;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          font-size: 17px;
          line-height: 21px;
        }
        @media (max-width: 836px) and (min-width: 768px), (max-width: 414px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Open */
  /* -------------------------------------------------------------- */
  &.open {
    > header {
      > div:nth-child(2) {
        > div[role="button"],
        > button {
          > svg {
            position: relative;
            top: -2px;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
