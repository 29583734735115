@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modals */
/* ---------------------------------------------------------------- */
.modals {
  height: 0;
  width: 0;
  /* ---------------------------------------------------------------- */
  /* Mui Dialog Root */
  /* ---------------------------------------------------------------- */
  > div > div {
    min-width: 320px;
  }
}
