@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Question */
/* ---------------------------------------------------------------- */
.question {
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 12px;
  padding: 12px 0;
  position: relative;
  @media (max-width: 1024px) {
    border-radius: 0;
    border-width: 2px 0;
    box-sizing: border-box;
    left: -24px;
    padding: 12px 24px;
    width: calc(100% + 48px);
  }
  @media (max-width: 767px) {
    left: -16px;
    padding: 12px 16px;
    width: calc(100% + 32px);
  }
  &:last-child {
    margin: 0 0 -14px;
    @media (max-width: 767px) {
      margin-bottom: -8px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Question Row */
  /* ---------------------------------------------------------------- */
  > div {
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Question Column - Label */
    /* ---------------------------------------------------------------- */
    &:nth-child(1) {
      flex-basis: 314px;
      max-width: 314px;
      text-align: right;
      @media (max-width: 1024px) {
        align-items: center;
        display: flex;
        flex-basis: calc(100% - 28px);
        margin-top: -4px;
        max-width: calc(100% - 28px);
        order: 1;
        text-align: left;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Question Column - Input */
    /* ---------------------------------------------------------------- */
    &:nth-child(2) {
      box-sizing: border-box;
      flex-basis: 412px;
      flex-shrink: 0;
      max-width: 412px;
      @media (max-width: 1024px) {
        flex-basis: 100%;
        margin: 11px 0 0;
        max-width: 100%;
        order: 3;
        padding-right: 0;
        position: static;
      }
      > div:nth-child(1) {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        @media (max-width: 1024px) {
          position: static;
        }
        /* ---------------------------------------------------------------- */
        /* Tooltip  */
        /* ---------------------------------------------------------------- */
        > .tooltip {
          cursor: pointer;
          flex-shrink: 0;
          height: 32px;
          position: absolute;
          right: -72px;
          top: 0;
          width: 32px;
          @media (max-width: 1060px) {
            right: -44px;
          }
          @media (max-width: 1024px) {
            height: 28px;
            right: 24px;
            top: 5px;
            width: 28px;
          }
          @media (max-width: 767px) {
            right: 16px;
          }
          * {
            @include slow-transition($props: "fill");
          }
          *:nth-child(1) {
            fill: $fieldsetBg;
          }
          *:nth-child(2),
          *:nth-child(3) {
            fill: $helpTooltipIcon;
          }
          :global(.platform-desktop) & {
            &:hover {
              *:nth-child(2),
              *:nth-child(3) {
                fill: $helpTooltipIconSelected;
              }
            }
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Help */
      /* ---------------------------------------------------------------- */
      > .help {
        margin: 20px 0 0;
        position: relative;
        @media (max-width: 1024px) {
          margin: 16px 0 0;
        }
        &::before {
          background-color: $helpTooltipIconSelected;
          border-radius: 6px;
          content: "";
          display: block;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 6px;
        }
        > div {
          font-size: 16px;
          line-height: 20px;
          padding: 0 0 0 20px;
          @include user-select;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Info */
    /* ---------------------------------------------------------------- */
    > .info {
      margin: 20px 0 -5px;
      @include user-select;
      @media (max-width: 1024px) {
        margin: 16px 0 -5px;
      }
      > *:first-child {
        margin-top: -3px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Rich Text */
    /* ---------------------------------------------------------------- */
    .rich-text {
      a {
        color: $hyperlink;
        text-decoration: underline;
        text-decoration-color: $hyperlink;
        @include fast-transition($mob: false, $props: color);
        &:focus {
          color: $hyperlinkHover;
          text-decoration-color: $hyperlinkHover;
        }
        :global(.platform-desktop) & {
          &:hover {
            color: $hyperlinkHover;
          }
        }
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          &:active {
            color: $hyperlinkActive;
          }
        }
      }
      strong {
        font-family: $semibold;
        font-weight: normal;
      }
      p,
      li {
        font-size: 16px;
        line-height: 20px;
      }
      ul,
      ol,
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0 0 12px;
      }
      > *:last-child {
        margin: 0;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: $semibold;
        font-size: 16px;
        line-height: 19px;
        margin-top: 16px;
        &:first-child {
          margin-top: 0;
        }
      }
      ul {
        li {
          margin: 0 0 4px;
          padding-left: 16px;
          position: relative;
          &:last-child {
            margin: 0;
          }
          &::before {
            background-color: $text;
            border-radius: 50%;
            content: "";
            height: 5px;
            left: 0;
            position: absolute;
            top: 7px;
            width: 5px;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Size */
  /* ---------------------------------------------------------------- */
  &.small {
    > div:nth-child(2) > div:nth-child(1) {
      > * {
        @media (max-width: 1024px) {
          order: 3;
        }
      }
      > *:nth-child(1) {
        width: 103px;
        @media (max-width: 1024px) {
          min-width: 103px;
          order: 1;
          width: 25%;
        }
      }
      > .tooltip {
        left: calc(25% + 40px);
        position: absolute;
        top: 0;
        @media (max-width: 1060px) {
          left: calc(25% + 16px);
        }
        @media (max-width: 1024px) {
          left: auto;
          margin-left: 16px;
          order: 2;
          position: relative;
          right: auto;
        }
        @media (max-width: 767px) {
          margin-left: 12px;
        }
      }
    }
  }
  &.medium {
    > div:nth-child(2) > div:nth-child(1) {
      > * {
        @media (max-width: 1024px) {
          order: 3;
        }
      }
      > *:nth-child(1) {
        width: 206px;
        @media (max-width: 1024px) {
          min-width: 206px;
          order: 1;
          width: 50%;
        }
      }
      > .tooltip {
        left: calc(50% + 40px);
        position: absolute;
        top: 0;
        @media (max-width: 1060px) {
          left: calc(50% + 16px);
        }
        @media (max-width: 1024px) {
          left: auto;
          margin-left: 16px;
          order: 2;
          position: relative;
          right: auto;
        }
        @media (max-width: 767px) {
          margin-left: 12px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Grouped */
  /* ---------------------------------------------------------------- */
  &.grouped {
    > div:nth-child(2) > div:nth-child(1) > .tooltip {
      *:nth-child(1) {
        fill: $questionSecondaryBg;
      }
    }
    &.show-help {
      > div:nth-child(2) > div:nth-child(1) > .tooltip {
        *:nth-child(3) {
          fill: $questionSecondaryBg;
        }
        :global(.platform-desktop) & {
          &:hover {
            *:nth-child(3) {
              fill: $questionSecondaryBg;
            }
          }
        }
      }
    }
    &.secondary {
      background-color: $fieldsetBg;
      &:last-child {
        margin: 0 0 -14px;
        @media (max-width: 767px) {
          margin-bottom: -8px;
        }
      }
      &.invalid {
        background-color: $questionInvalidBg;
      }
    }
    &.invalid {
      &:last-child {
        margin: 0 0 -14px;
        @media (max-width: 767px) {
          margin-bottom: -8px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Secondary */
  /* ---------------------------------------------------------------- */
  &.secondary {
    background-color: $questionSecondaryBg;
    border-color: $questionSecondaryBorder;
    &:last-child {
      margin: 0;
      @media (max-width: 767px) {
        margin-bottom: -4px;
      }
    }
    > div:nth-child(2) > div:nth-child(1) > .tooltip {
      *:nth-child(1) {
        fill: $questionSecondaryBg;
      }
    }
    &.show-help {
      > div:nth-child(2) > div:nth-child(1) > .tooltip {
        *:nth-child(3) {
          fill: $questionSecondaryBg;
        }
        :global(.platform-desktop) & {
          &:hover {
            *:nth-child(3) {
              fill: $questionSecondaryBg;
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    background-color: $questionInvalidBg;
    border-color: $questionInvalidBorder;
    &:last-child {
      margin: 0;
      @media (max-width: 767px) {
        margin-bottom: -4px;
      }
    }
    > div:nth-child(2) > div:nth-child(1) > .tooltip {
      *:nth-child(1) {
        fill: $questionInvalidBg;
      }
    }
    &.show-help {
      > div:nth-child(2) > div:nth-child(1) > .tooltip {
        *:nth-child(3) {
          fill: $questionInvalidBg;
        }
        :global(.platform-desktop) & {
          &:hover {
            *:nth-child(3) {
              fill: $questionInvalidBg;
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    > div:nth-child(2) > div:nth-child(1) > .tooltip {
      pointer-events: none;
      *:nth-child(2),
      *:nth-child(3) {
        fill: $helpTooltipIconDisabled;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Show Help */
  /* ---------------------------------------------------------------- */
  &.show-help {
    > div:nth-child(2) > div:nth-child(1) > .tooltip {
      *:nth-child(1),
      *:nth-child(2) {
        fill: $helpTooltipIconSelected;
      }
      *:nth-child(3) {
        fill: $fieldsetBg;
      }
      :global(.platform-desktop) & {
        &:hover {
          *:nth-child(1),
          *:nth-child(2) {
            fill: $helpTooltipIcon;
          }
          *:nth-child(3) {
            fill: $fieldsetBg;
          }
        }
      }
    }
  }
}
