@font-face {
  font-display: swap;
  font-family: "Montserrat Bold";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Montserrat-Bold.eot");
  src: url("/fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Montserrat-Bold.woff2") format("woff2"), url("/fonts/Montserrat-Bold.woff") format("woff"),
    url("/fonts/Montserrat-Bold.ttf") format("truetype"), url("/fonts/Montserrat-Bold.svg") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Montserrat Semibold";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Montserrat-Semibold.eot");
  src: url("/fonts/Montserrat-Semibold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Montserrat-Semibold.woff2") format("woff2"), url("/fonts/Montserrat-Semibold.woff") format("woff"),
    url("/fonts/Montserrat-Semibold.ttf") format("truetype"), url("/fonts/Montserrat-Semibold.svg") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Montserrat-Medium.eot");
  src: url("/fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Montserrat-Medium.woff2") format("woff2"), url("/fonts/Montserrat-Medium.woff") format("woff"),
    url("/fonts/Montserrat-Medium.ttf") format("truetype"), url("/fonts/Montserrat-Medium.svg") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Montserrat Regular";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Montserrat-Regular.eot");
  src: url("/fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Montserrat-Regular.woff2") format("woff2"), url("/fonts/Montserrat-Regular.woff") format("woff"),
    url("/fonts/Montserrat-Regular.ttf") format("truetype"), url("/fonts/Montserrat-Regular.svg") format("svg");
}
@font-face {
  font-display: swap;
  font-family: "Charles Wright Bold";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/Charleswright-Bold.eot");
  src: url("/fonts/Charleswright-Bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/Charleswright-Bold.woff2") format("woff2"), url("/fonts/Charleswright-Bold.woff") format("woff"),
    url("/fonts/Charleswright-Bold.ttf") format("truetype"), url("/fonts/Charleswright-Bold.svg") format("svg");
}
