@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Policy Terms Conditions */
/* ---------------------------------------------------------------- */
.policy-terms-conditions {
  background-color: $productBg;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  margin: 0 0 24px;
  @media (max-width: 1240px) {
    margin: 0 0 16px;
  }
  @media (max-width: 1024px) {
    margin: 0 0 12px;
  }
  @media (max-width: 767px) {
    border-radius: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  > header {
    margin: -5px 0 18px;
    padding: 24px 20px 0;
    @media (max-width: 1240px) {
      margin-top: -4px;
    }
    @media (max-width: 767px) {
      padding: 24px 16px 0;
    }
    &::after {
      background-color: transparent;
      content: "";
      display: block;
      height: 0;
      margin: 0;
      position: relative;
      width: 100%;
      @include slow-transition($mob: true, $props: "background-color");
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        margin: 6px 0 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    > div[role="button"],
    > button {
      cursor: pointer;
      position: relative;
      width: 100%;
      &:focus {
        > h2 {
          text-decoration: underline;
          text-decoration-color: $title;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Title */
      /* ---------------------------------------------------------------- */
      > h2 {
        color: $title;
        font-family: $bold;
        font-size: 28px;
        line-height: 32px;
        padding-right: 32px;
        position: relative;
        text-align: left;
        text-transform: capitalize;
        @include no-user-select;
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          font-size: 24px;
          line-height: 28px;
        }
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          font-size: 23px;
          line-height: 27px;
        }
        @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
          font-size: 22px;
          line-height: 26px;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Icon */
      /* ---------------------------------------------------------------- */
      > svg {
        height: 20px;
        margin: -10px 0 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: rotate(0deg);
        width: 20px;
        @include slow-transition($mob: true, $props: "transform");
        @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
          height: 18px;
          margin: -9px 0 0;
          width: 18px;
        }
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          height: 16px;
          margin: -8px 0 0;
          width: 16px;
        }
        * {
          fill: $policyAccordionIcon;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  > section {
    padding: 4px 20px 25px;
    @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
      padding: 4px 16px 19px;
    }
    @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
      padding: 4px 16px 16px;
    }
    > * {
      @include user-select;
      &:first-child {
        margin-top: -4px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: $bold;
      font-size: 20px;
      line-height: 24px;
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 19px;
        line-height: 23px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    p,
    li {
      font-family: $regular;
      font-size: 16px;
      line-height: 20px;
      > strong {
        font-family: $bold;
      }
      > button {
        color: $hyperlink;
        cursor: pointer;
        display: inline;
        font-family: $bold;
        font-size: 16px;
        line-height: 20px;
        text-decoration: underline;
        text-decoration-color: $hyperlink;
        :global(.platform-desktop) & {
          &:hover {
            color: $hyperlinkHover;
          }
        }
        &:active {
          color: $hyperlinkActive;
        }
      }
      @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
        font-size: 15px;
        line-height: 19px;
      }
      @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    ul,
    ol,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0 0 15px;
      @media (max-width: 1240px) and (min-width: 768px), (max-width: 670px) {
        margin: 0 0 12px;
      }
    }
    ul {
      list-style-type: none;
      > li {
        padding-left: 24px;
        position: relative;
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          padding-left: 20px;
        }
        @media (max-width: 836px) and (min-width: 768px), (max-width: 390px) {
          padding-left: 16px;
        }
        &::before {
          background-color: $modalListBullet;
          border-radius: 50%;
          content: "";
          display: block;
          height: 8px;
          left: 0;
          position: absolute;
          top: 5px;
          width: 8px;
          @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
            height: 6px;
            width: 6px;
          }
        }
        > ol {
          padding-top: 7px;
          > li {
            margin: 0 0 4px;
          }
        }
      }
    }
    ol {
      counter-reset: li;
      list-style: none;
      > li {
        counter-increment: li;
        padding-left: 24px;
        position: relative;
        @media (max-width: 884px) and (min-width: 768px), (max-width: 414px) {
          padding-left: 20px;
        }
        &::before {
          color: $modalListBullet;
          content: counter(li) ". ";
          display: inline-block;
          font-family: $bold;
          left: 18px;
          margin-left: -18px;
          position: absolute;
          text-align: right;
          top: 0;
        }
      }
    }
    li {
      margin: 0 0 4px;
      &:last-child {
        margin: 0;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Open */
  /* ---------------------------------------------------------------- */
  &.open {
    > header {
      margin-bottom: 26px;
      @media (max-width: 1240px) {
        margin-bottom: 20px;
      }
      @media (max-width: 1024px) {
        margin-bottom: 16px;
      }
      @media (max-width: 767px) {
        margin-bottom: 20px;
      }
      &::after {
        background-color: $titleBorder;
        height: 2px;
        margin: 11px 0 0;
      }
      > div[role="button"],
      > button {
        > svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
