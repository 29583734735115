@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Live Chat */
/* ---------------------------------------------------------------- */
.live-chat {
  display: block;
  height: 60px;
  margin-top: -30px;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%) rotate(-90deg);
  transform-origin: bottom;
  z-index: 999;
  @media (max-width: 1192px) {
    height: 50px;
    margin-top: -25px;
  }
  @media (max-width: 896px) {
    height: 40px;
    margin-top: -20px;
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  > div[role="button"],
  > button {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: none;
    height: 60px;
    width: 212px;
    z-index: 999;
    @media (max-width: 1192px) {
      height: 50px;
    }
    @media (min-width: 768px) {
      display: inline-flex;
    }
    @media (max-width: 896px) {
      height: 40px;
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    > svg {
      height: 20px;
      margin: 0 10px 0 0;
      width: 20px;
      @media (max-width: 896px) {
        height: 18px;
        width: 18px;
      }
    }
    &:focus > span {
      text-decoration-color: $white;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Available */
  /* ---------------------------------------------------------------- */
  &.available {
    > div[role="button"],
    > button {
      width: 144px;
      @media (max-width: 896px) {
        width: 134px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Maximized */
  /* ---------------------------------------------------------------- */
  &.maximized {
    > div[role="button"],
    > button {
      background-color: $successButtonBgHover;
      border-color: $successButtonBorderHover;
    }
  }
}
/* ---------------------------------------------------------------- */
/* Live Chat Widget */
/* ---------------------------------------------------------------- */
:global {
  html:not(.platform-chat) #chat-widget {
    display: none !important;
  }
  html.platform-chat #chat-widget {
    transform: translateY(200px);
    transition: transform 0.3s ease-out;
    will-change: transform;
  }
  html.platform-chat-maximized #chat-widget {
    transform: translateY(0);
  }
}
