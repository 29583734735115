@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Header Recall */
/* ---------------------------------------------------------------- */
.header-recall {
  align-items: center;
  background-color: $headerRetrieveQuoteBg;
  border-radius: 10px;
  display: flex;
  margin: 0 0 -1px;
  padding: 8px;
  @media (max-width: 767px) {
    display: none;
  }
  /* ---------------------------------------------------------------- */
  /* Header Recall - Text */
  /* ---------------------------------------------------------------- */
  > p {
    color: $headerText;
    font-family: $bold;
    font-size: 15px;
    line-height: 19px;
    padding: 0 10px 1px 4px;
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 18px;
      padding: 0 3px 0 4px;
      width: 118px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header Recall - Button */
  /* ---------------------------------------------------------------- */
  > [role="button"],
  > button {
    height: 36px;
    padding: 0 16px;
    @media (max-width: 1024px) {
      height: 32px;
      padding: 0 12px;
    }
    /* ---------------------------------------------------------------- */
    /* Header Recall - Button - Text */
    /* ---------------------------------------------------------------- */
    span {
      font-size: 15px;
      @media (max-width: 1024px) {
        font-size: 14px;
      }
    }
  }
}
