@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Recall - Banner */
/* ---------------------------------------------------------------- */
.recall-banner {
  align-items: flex-start;
  background-color: $fieldsetBg;
  background-image: url("/images/banner.jpg");
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 170px;
  justify-content: center;
  margin-top: 20px;
  padding: 0 48px;
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min--moz-device-pixel-ratio: 1.25),
    only screen and (-o-min-device-pixel-ratio: 1.25 / 1),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 200dpi),
    only screen and (min-resolution: 1.25dppx) {
    background-image: url("/images/banner@2x.jpg");
  }
  @media (max-width: 850px) {
    background-position: -346px 0;
  }
  @media (max-width: 767px) {
    background-position: 100% 0;
    border: 0;
    border-radius: 0;
    border-width: 1px 0;
    box-shadow: none;
    margin: 0;
    padding: 0 16px;
  }
  @media (max-width: 665px) {
    background-position: -435px 0;
  }
  @media (max-width: 367px) {
    background-position: -522px 0;
  }
  > h2 {
    color: $recallBannerTitle;
    font-family: $bold;
    font-size: 28px;
    line-height: 32px;
    margin: -9px 0 26px;
    @media (max-width: 1024px) {
      font-size: 25px;
      line-height: 28px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 26px;
    }
  }
  > button {
    flex-shrink: 0;
  }
}
