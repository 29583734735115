@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Date Select */
/* ---------------------------------------------------------------- */
.date-select {
  display: block;
  width: 412px;
  @media (max-width: 1024px) {
    width: 100%;
  }
  > ul {
    display: flex;
    margin: 0 -3px;
    @media (max-width: 345px) {
      margin: 0 -1px;
    }
    > li {
      margin: 0 3px;
      @media (max-width: 345px) {
        margin: 0 1px;
      }
      /* ---------------------------------------------------------------- */
      /* Select Container */
      /* ---------------------------------------------------------------- */
      > div {
        width: auto;
      }
      /* ---------------------------------------------------------------- */
      /* Day */
      /* ---------------------------------------------------------------- */
      &.day {
        flex-basis: 33.33%;
        max-width: 33.33%;
        @media (max-width: 345px) {
          flex-basis: 30.5%;
          max-width: 30.5%;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Month */
      /* ---------------------------------------------------------------- */
      &.month {
        flex-basis: 33.34%;
        max-width: 33.34%;
        @media (max-width: 345px) {
          flex-basis: 34%;
          max-width: 34%;
        }
        &:first-child {
          flex-basis: 60%;
          max-width: 60%;
          + li {
            flex-basis: 40%;
            max-width: 40%;
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Year */
      /* ---------------------------------------------------------------- */
      &.year {
        flex-basis: 33.33%;
        max-width: 33.33%;
        @media (max-width: 345px) {
          flex-basis: 35.5%;
          max-width: 35.5%;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Select */
      /* ---------------------------------------------------------------- */
      select {
        padding-right: 40px;
      }
    }
  }
}
