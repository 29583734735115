@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Confirmation - Message */
/* ---------------------------------------------------------------- */
.confirmation-message {
  background-color: $confirmationMessageBg;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  padding: 20px;
  @media (max-width: 767px) {
    border-radius: 0;
    box-shadow: none;
    padding: 24px 16px;
  }
  > div {
    text-align: center;
    &:nth-child(1) {
      background-color: $confirmationMessageTitleBg;
      border: 2px solid $confirmationMessageTitleBorder;
      border-radius: 12px;
      padding: 24px 20px;
      @media (max-width: 767px) {
        background: none;
        border: none;
        margin: 0;
        padding: 0;
      }
      > h2 {
        color: $confirmationMessageTitle;
        font-family: $bold;
        font-size: 40px;
        line-height: 42px;
        margin: -8px 0 9px;
        @media (max-width: 1024px) {
          font-size: 36px;
          line-height: 38px;
        }
        @media (max-width: 767px) {
          font-size: 32px;
          line-height: 34px;
          margin: -6px 0 13px;
        }
      }
      > p {
        color: $confirmationMessageTitle;
        font-size: 20px;
        line-height: 24px;
        margin: 0 auto;
        max-width: 780px;
        @media (max-width: 767px) {
          font-size: 19px;
          line-height: 23px;
          margin: 0 0 24px;
        }
      }
    }
    &:nth-child(2) {
      margin-top: 29px;
      > p {
        line-height: 20px;
        margin: 0 0 12px;
        @include user-select;
        @media (max-width: 767px) {
          margin: 0 0 24px;
        }
        > span {
          font-family: $semibold;
          font-weight: normal;
        }
      }
    }
  }
}
