@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Question Vehicles */
/* ---------------------------------------------------------------- */
.question-vehicles {
  background-color: $fieldsetBg;
  border-radius: 12px;
  box-shadow: 0 6px 6px rgba(48, 48, 48, 0.15);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 32px;
  @media (max-width: 1071px) {
    padding: 32px 24px;
  }
  @media (max-width: 767px) {
    border-radius: 0;
    padding: 20px 16px;
  }
  + fieldset {
    margin-top: 20px;
    @media (max-width: 767px) {
      margin-top: 16px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Question Vehicles - Title */
  /* ---------------------------------------------------------------- */
  > h2 {
    color: $title;
    font-family: $bold;
    font-size: 28px;
    line-height: 30px;
    margin: 0 0 13px;
    position: relative;
    text-transform: capitalize;
    @include no-user-select;
    @media (max-width: 1024px) {
      font-size: 26px;
      line-height: 28px;
      margin: 0 0 8px;
    }
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 28px;
    }
    &:first-child {
      margin-top: -4px;
    }
    &::after {
      background-color: $titleBorder;
      content: "";
      display: block;
      height: 2px;
      margin: 11px 0 0;
      position: absolute;
      width: 100%;
      @media (max-width: 1024px) {
        margin: 7px 0 0;
      }
      @media (max-width: 767px) {
        margin: 6px 0 0;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Question Vehicles - Button */
  /* ---------------------------------------------------------------- */
  > div[role="button"],
  > button {
    align-self: flex-end;
    margin: 24px 0 0 auto;
    @media (max-width: 440px) {
      width: 100%;
    }
  }
}
