@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Footer Navigation */
/* ---------------------------------------------------------------- */
.footer-navigation {
  /* ---------------------------------------------------------------- */
  /* Footer Navigation - List */
  /* ---------------------------------------------------------------- */
  > ul {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 40px;
    justify-content: flex-start;
    @media (max-width: 1240px) {
      grid-gap: 32px;
    }
    @media (max-width: 1024px) {
      grid-gap: 24px;
    }
    @media (max-width: 767px) {
      grid-gap: 16px;
    }
    @media (max-width: 640px) {
      grid-auto-flow: row;
    }
    /* ---------------------------------------------------------------- */
    /* Footer Navigation - Item */
    /* ---------------------------------------------------------------- */
    > li {
      /* ---------------------------------------------------------------- */
      /* Footer Navigation - Button / Link */
      /* ---------------------------------------------------------------- */
      > [role="button"],
      > button,
      > a {
        color: $footerLink;
        cursor: pointer;
        display: block;
        font-family: $medium;
        font-size: 16px;
        line-height: 20px;
        margin-block: -2px -5px;
        @include fast-transition($props: "color");
        @media (max-width: 1024px) {
          font-size: 15px;
          line-height: 19px;
          margin-block: -2px -4px;
        }
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
          margin-block: -3px -4px;
        }
        @media (max-width: 640px) {
          display: table;
        }
        &:focus {
          color: darken($footerLink, 10%);
          text-decoration: underline;
          text-decoration-color: darken($footerLink, 10%);
        }
        :global(.platform-desktop) & {
          &:hover {
            color: darken($footerLink, 10%);
          }
        }
        :global(.platform-desktop) &,
        :global(.platform-mobile) & {
          &:active {
            color: darken($footerLink, 20%);
          }
        }
      }
    }
  }
}
